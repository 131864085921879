import { Observable, Subject } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ClientService {
    constructor(http) {
        this.http = http;
        this.userChanges$ = new Subject();
        this.headers = new HttpHeaders();
        this.clientUrl = environment.clientUrl;
        //this.clientUrl = 'https://localhost/api2/';
        //this.clientUrl = 'http://172.31.10.154/api/v1/';
        this.headers = this.headers.set('puppis_key', 'puppis_key').set('puppis_token', 'e7df7cd2ca07f4f1ab415d457a6e1c13');
        this.userList = [];
    }
    getIdentificationDocumentCompleteList() {
        return this.http.get(`${this.clientUrl}/DocumentType`);
    }
    getIdentificationDocumentByCountryId(id) {
        return this.http.get(`${this.clientUrl}/DocumentType/Country/${id}`);
    }
    getIdentificationDocumentById(id) {
        return this.http.get(`${this.clientUrl}/DocumentType/${id}`);
    }
    getClientById(id, countryCode) {
        //return this.http.get<Client>(`${this.clientUrl}/Client/${id}`);
        return this.http.get(`${this.clientUrl}/cliente/${id}/${countryCode}`, { headers: this.headers });
    }
    getPetsByClient(id, countryCode) {
        return this.http.get(`${this.clientUrl}/mascotas/${id}/${countryCode}`, { headers: this.headers });
    }
    getAddressTypeList() {
        return this.http.get(`${this.clientUrl}/Client/addressType`);
    }
    getUnifiedClientList(searchData) {
        return this.http.post(`${this.clientUrl}buscar`, searchData, { headers: this.headers });
    }
    getUnifiedClientList_bis(searchData) {
        return this.http.post(`${this.clientUrl}/Client/Grid/Filter`, searchData);
    }
    getInvoiceList(searchData) {
        return this.http.post(`${this.clientUrl}facturas`, searchData, { headers: this.headers });
    }
    getInvoice(searchData) {
        return this.http.post(`${this.clientUrl}factura`, searchData, { headers: this.headers });
    }
    getExtract(id) {
        return this.http.get(`${this.clientUrl}/extract/${id}`, { headers: this.headers });
    }
    // getPagingInfoForUnifiedClientList(searchData: any): Observable<any> {
    //   return this.http.get<ClientList>(`${this.clientUrl}/clients/get-pagination`, {
    //     params: searchData
    //   });
    // }
    createClient(client) {
        const cliendDto = this.getClientDTO(client);
        return this.http.post(`${this.clientUrl}/client`, cliendDto);
    }
    updateClient(client) {
        const cliendDto = this.getClientDTO(client);
        return this.http.put(`${this.clientUrl}/cliente/${cliendDto.id_unico}`, cliendDto, { headers: this.headers });
    }
    getNeedAttentionClients(amount, exceptionIdList) {
        const data = exceptionIdList && exceptionIdList.length > 0
            ? {
                amount: amount,
                exceptionIds: exceptionIdList.map(exceptionCard => exceptionCard.id)
            }
            : {
                amount: amount
            };
        return this.http.get(`${this.clientUrl}/client/clientPossibleDuplicates`, {
            params: Object.assign({}, data)
        });
    }
    getChangeHistoryDetail(clientId) {
        const today = new Date();
        const response = null;
        return new Observable(observer => {
            observer.next(response);
            observer.complete();
        });
    }
    getChangeHistory() {
        const response = [
            {
                historyId: "1",
                clientId: "C91145839201",
                properties: ["phone", "email", "address", "nationality"],
                date: new Date()
            },
            {
                historyId: "2",
                clientId: "C91145839205",
                properties: ["phone", "email", "address", "nationality"],
                date: new Date()
            },
            {
                historyId: "3",
                clientId: "C91145839203",
                properties: ["phone", "email", "address", "nationality"],
                date: new Date()
            },
            {
                historyId: "4",
                clientId: "C91145839204",
                properties: ["phone", "email", "address", "nationality"],
                date: new Date()
            },
            {
                historyId: "5",
                clientId: "C91145839201",
                properties: ["phone"],
                date: new Date()
            },
            {
                historyId: "6",
                clientId: "C91145839217",
                properties: ["email", "address"],
                date: new Date()
            }
        ];
        return new Observable(observer => {
            observer.next(response);
            observer.complete();
        });
    }
    getClientDTO(client) {
        let clientDto = {
            confirmSave: client.confirmSave,
            name: client.name
        };
        /*
        if (client.lastName) {clientDto.lastName = client.lastName;}
        if (client.id) {clientDto.id = client.id;}
        if (client.id) {clientDto.id = client.id;}
        if (client.countryCode) {clientDto.countryCode = client.countryCode;}
        if (client.dateOfBirth) {clientDto.dateOfBirth = client.dateOfBirth;}
        if (client.id) {clientDto.id = client.id;}
        if (client.gender) {clientDto.gender = client.gender;}
        if (client.identificationDocuments &&client.identificationDocuments[0] &&(client.identificationDocuments[0].number ||client.identificationDocuments[0].name)) {clientDto.identificationDocuments = client.identificationDocuments;}
        if (client.emails &&client.emails[0] &&(client.emails[0].address || client.emails[0].addressType)) {clientDto.emails = client.emails;}
        if (client.phoneNumbers &&client.phoneNumbers[0] &&(client.phoneNumbers[0].number ||client.phoneNumbers[0].areaCode ||client.phoneNumbers[0].countryCode ||client.phoneNumbers[0].contactType)) {clientDto.phoneNumbers = client.phoneNumbers;}
        if (client.addresses &&client.addresses[0] &&(client.addresses[0].street ||client.addresses[0].type ||client.addresses[0].postalCode ||client.addresses[0].number ||client.addresses[0].floor ||client.addresses[0].city ||client.addresses[0].gpsCoordinates)) {clientDto.addresses = client.addresses;}
        if (client.country) {clientDto.country = client.country;}
        */
        if (client.dni)
            clientDto.dni = client.dni;
        if (client.id_unico)
            clientDto.id_unico = client.id_unico;
        if (client.NombrePrincipal)
            clientDto.NombrePrincipal = client.NombrePrincipal;
        if (client.ApellidoPrincipal)
            clientDto.ApellidoPrincipal = client.ApellidoPrincipal;
        if (client.TelefonoPrincipal)
            clientDto.TelefonoPrincipal = client.TelefonoPrincipal;
        if (client.EmailPrincipal)
            clientDto.EmailPrincipal = client.EmailPrincipal;
        if (client.Telefonos)
            clientDto.Telefonos = client.Telefonos;
        if (client.Emails)
            clientDto.Emails = client.Emails;
        if (client.Domicilios)
            clientDto.Domicilios = client.Domicilios;
        if (client.verificado)
            clientDto.verificado = client.verificado;
        if (client.countryCode)
            clientDto.countryCode = client.countryCode;
        console.log(clientDto);
        return clientDto;
    }
    getPossibleDuplicatedById(id) {
        return this.http.get(`${this.clientUrl}/client/getPossibleDuplicatesById/${id}`);
    }
    getPossibleDuplicatedByIdList(ids) {
        return this.http.post(`${this.clientUrl}/client/getPossibleDuplicatesByIds`, ids);
    }
    getDuplicatedDetails(ids) {
        return this.http.post(`${this.clientUrl}/client/getDuplicatedDetails`, ids);
    }
    mergeClients(countryCode, id_unico_emisor, id_unico_receptor) {
        return this.http.post(`${this.clientUrl}merge`, { 'countryCode': countryCode, 'id_unico_emisor': id_unico_emisor, 'id_unico_receptor': id_unico_receptor }, { headers: this.headers });
    }
    getStatisticsList(dateFrom, dateTo) {
        const dataSearch = {
            dateFrom: dateFrom,
            dateTo: dateTo
        };
        const statistics1 = {
            date: new Date("2019/12/02"),
            data: [
                { origin: "SAP", newUpdated: 1345, hasErrors: true },
                { origin: "ICG", newUpdated: 1260, hasErrors: true },
                { origin: "MELI", newUpdated: 100, hasErrors: false },
                { origin: "PVET", newUpdated: 1050, hasErrors: true },
                { origin: "SIMP", newUpdated: 350, hasErrors: true },
                { origin: "COMM", newUpdated: 2150, hasErrors: true }
            ]
        };
        const statistics2 = {
            date: new Date("2019/12/03"),
            data: [
                { origin: "SAP", newUpdated: 2345, hasErrors: false },
                { origin: "ICG", newUpdated: 260, hasErrors: false },
                { origin: "MELI", newUpdated: 100, hasErrors: false },
                { origin: "PVET", newUpdated: 1000, hasErrors: true },
                { origin: "PVETC", newUpdated: 50, hasErrors: true },
                { origin: "SIMP", newUpdated: 850, hasErrors: true },
                { origin: "COMM", newUpdated: 2159, hasErrors: true }
            ]
        };
        const statistics3 = {
            date: new Date("2019/12/04"),
            data: [
                { origin: "SAP", newUpdated: 347, hasErrors: true },
                { origin: "ICG", newUpdated: 2068, hasErrors: false },
                { origin: "MELI", newUpdated: 100, hasErrors: false },
                { origin: "PVET", newUpdated: 3000, hasErrors: false },
                { origin: "PVETC", newUpdated: 504, hasErrors: false },
                { origin: "SIMP", newUpdated: 550, hasErrors: false },
                { origin: "COMM", newUpdated: 1239, hasErrors: true }
            ]
        };
        const statistics4 = {
            date: new Date("2019/12/05"),
            data: [
                { origin: "SAP", newUpdated: 1345, hasErrors: true },
                { origin: "ICG", newUpdated: 1260, hasErrors: true },
                { origin: "MELI", newUpdated: 100, hasErrors: false },
                { origin: "PVET", newUpdated: 1050, hasErrors: true },
                { origin: "SIMP", newUpdated: 350, hasErrors: true },
                { origin: "COMM", newUpdated: 2150, hasErrors: true }
            ]
        };
        const statistics5 = {
            date: new Date("2019/12/06"),
            data: [
                { origin: "SAP", newUpdated: 2345, hasErrors: false },
                { origin: "ICG", newUpdated: 260, hasErrors: false },
                { origin: "MELI", newUpdated: 100, hasErrors: false },
                { origin: "PVET", newUpdated: 1000, hasErrors: false },
                { origin: "PVETC", newUpdated: 50, hasErrors: false },
                { origin: "SIMP", newUpdated: 850, hasErrors: false },
                { origin: "COM", newUpdated: 2159, hasErrors: false }
            ]
        };
        const statistics6 = {
            date: new Date("2019/12/07"),
            data: [
                { origin: "SAP", newUpdated: 347, hasErrors: true },
                { origin: "ICG", newUpdated: 2068, hasErrors: false },
                { origin: "MELI", newUpdated: 100, hasErrors: false },
                { origin: "PVET", newUpdated: 3000, hasErrors: false },
                { origin: "PVETC", newUpdated: 504, hasErrors: false },
                { origin: "SIMP", newUpdated: 550, hasErrors: false },
                { origin: "COM", newUpdated: 1039, hasErrors: false }
            ]
        };
        const statistics7 = {
            date: new Date("2019/12/08"),
            data: [
                { origin: "SAP", newUpdated: 347, hasErrors: true },
                { origin: "ICG", newUpdated: 2068, hasErrors: false },
                { origin: "MELI", newUpdated: 100, hasErrors: false },
                { origin: "PVET", newUpdated: 3000, hasErrors: false },
                { origin: "PVETC", newUpdated: 504, hasErrors: false },
                { origin: "SIMP", newUpdated: 550, hasErrors: false },
                { origin: "COM", newUpdated: 1239, hasErrors: false }
            ]
        };
        const response = {
            data: [statistics1, statistics2, statistics3, statistics4, statistics5, statistics6, statistics7],
            paging: {
                total: 120,
                pageSize: 12,
                page: 0,
                found: 120,
                totalPages: 12
            }
        };
        return new Observable(observer => {
            observer.next(response);
            observer.complete();
        });
        /*return this.http.get<StatisticsList>(
          `${this.clientUrl}/client/getStatisticsList`,
          {
            params: { ...data }
          }
        );*/
    }
    getStatisticsSummary() {
        const response = {
            imported: 12356,
            notImported: 0,
            merged: 123,
            pendingMerges: 56
        };
        return new Observable(observer => {
            observer.next(response);
            observer.complete();
        });
        /*return this.http.get<StatisticsSummary>(
          `${this.clientUrl}/client/getStatisticsSummary`
        );*/
    }
    getStatisticsDetails(origin, date) {
        const data = {
            origin: origin,
            date: date
        };
        return this.http.get(`${this.clientUrl}/client/getStatisticsDetails`, {
            params: Object.assign({}, data)
        });
    }
    getClientsToUnmerge(clientId) {
        return this.http.get(`${this.clientUrl}/client/getClientsToUnmerge/${clientId}`);
    }
    unmergeClient(clientId) {
        return this.http.get(`${this.clientUrl}/client/unmerge/${clientId}`);
    }
}
ClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClientService_Factory() { return new ClientService(i0.ɵɵinject(i1.HttpClient)); }, token: ClientService, providedIn: "root" });
