import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    constructor(private authenticationService: AuthenticationService) {}

    checkPermission(permission: string): boolean {
        return this.checkPermissions([permission]);
    }

    checkPermissions(permissions: string[], logicalOp: string = 'AND'): boolean {
        let hasPermission = false;

        if (this.authenticationService.isAdmin()) {
            return true;
        }

        for (const checkPermission of permissions) {
            if (this.authenticationService.hasPolicy(checkPermission)) {
                hasPermission = true;

                if (logicalOp === 'OR') {
                    break;
                }
            } else {
                hasPermission = false;
                if (logicalOp === 'AND') {
                    break;
                }
            }
        }
        return hasPermission;
    }

    checkRole(role: string): boolean {
        return this.checkRoles([role]);
    }

    checkRoles(roles: string[], logicalOp: string = 'AND'): boolean {
        let hasRole = false;

        if (this.authenticationService.isAdmin()) {
            return true;
        }

        for (const role of roles) {
            if (this.authenticationService.isInRole(role)) {
                hasRole = true;

                if (logicalOp === 'OR') {
                    break;
                }
            } else {
                hasRole = false;
                if (logicalOp === 'AND') {
                    break;
                }
            }
        }

        return hasRole;
    }
}
