import { Injectable } from '@angular/core';

//cdk
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatSpinner, MatSnackBar } from '@angular/material';

//rxjs
import { Observable, Subject } from 'rxjs'
import { mapTo, scan, map, mergeMap } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class UiService {

    private spinnerTopRef = this.cdkSpinnerCreate();

    spin$ :Subject<boolean> = new Subject()

    constructor(
        private overlay: Overlay,
        private snackBar: MatSnackBar
    ) {

      this.spin$
        .asObservable()
        .pipe(
          map(val => val ? 1 : -1 ),
          scan((acc, one) => (acc + one) >= 0 ? acc + one : 0, 0)
        )
        .subscribe(
          (res) => {
            if(res === 1){
               this.showSpinner();
            }
            else if( res == 0 ){ 
              this.spinnerTopRef.hasAttached() ? this.stopSpinner(): null;
            }
          }
        )
    }

    private cdkSpinnerCreate() {
        return this.overlay.create({
            hasBackdrop: false,
            backdropClass: 'dark-backdrop',
            positionStrategy: this.overlay.position()
                .global()
                .centerHorizontally()
                .centerVertically()
        })
    }

    private showSpinner(){
      if(!this.spinnerTopRef.hasAttached()) {
        this.spinnerTopRef.attach(new ComponentPortal(MatSpinner));
        setTimeout(() => {
          this.stopSpinner();
        }, 12000);
      }
    }

    private stopSpinner(){
      this.spinnerTopRef.detach() ;
    }

    openSnackBar(message: string, action: string) {
      this.snackBar.open(message, action, {
        duration: 5000
      });
    }

    displayErrorMessage(exception, action, article, component){ 
      if(exception && exception.error && exception.error.error)
      {
        this.openSnackBar(`${exception.error.error}`, '');  
      }
      else 
      {
        this.openSnackBar(`Ocurrió un error al ${action} ${article} ${component}`, '');   
      }
    }
}