import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DataValidationLandingComponent } from './data-validation-landing/data-validation-landing.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './login/login.component';
import { PickingMobileFormComponent } from './oms/picking-groups/picking-mobile-form.component';
import { RegistrationComponent } from './registration/registration.component';
import { OmsTrackingComponent } from './tracking/tracking.component';
import { TransferQRComponent } from './transfers/public/transfer-qr/transfer-qr.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'registro/:action/:userCode', component: RegistrationComponent },
  { path: 'public/tracking/:nro', component: OmsTrackingComponent },
  { path: 'public/tracking', component: OmsTrackingComponent },
  { path: 'public/validate/:token', component: DataValidationLandingComponent },
  { path: 'public/transfer-qr/:id', component: TransferQRComponent },
  { path: 'separation/:id', canActivate: [AuthGuard], component: PickingMobileFormComponent },
  {
    path: '**',
    canActivate: [AuthGuard],
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
