import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LocationWmsService {
    constructor(http) {
        this.http = http;
        this.baseUrl = environment.wmsUrl;
    }
    getAll(countryCode, sorting, skipCount, maxResultCount) {
        let url_ = `${this.baseUrl}/api/services/app/Location/GetAll?`;
        if (countryCode !== undefined)
            url_ += "CountryCode=" + encodeURIComponent("" + countryCode) + "&";
        if (sorting !== undefined)
            url_ += "Sorting=" + encodeURIComponent("" + sorting) + "&";
        if (skipCount !== undefined)
            url_ += "SkipCount=" + encodeURIComponent("" + skipCount) + "&";
        if (maxResultCount !== undefined)
            url_ += "MaxResultCount=" + encodeURIComponent("" + maxResultCount) + "&";
        url_ = url_.replace(/[?&]$/, "");
        return this.http.get(url_)
            .pipe(map(x => x.result ? x.result : null));
    }
}
LocationWmsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocationWmsService_Factory() { return new LocationWmsService(i0.ɵɵinject(i1.HttpClient)); }, token: LocationWmsService, providedIn: "root" });
export class ProductLocationWmsService {
    constructor(http) {
        this.http = http;
        this.baseUrl = environment.wmsUrl;
    }
    get(nro) {
        const url = `${this.baseUrl}/api/services/app/ProductLocation/Get?id=${nro}`;
        return this.http.get(url)
            .pipe(map(x => x.result ? x.result : null));
    }
    create(dto) {
        const url = `${this.baseUrl}/api/services/app/ProductLocation/create`;
        return this.http.post(url, dto)
            .pipe(map(x => x.result ? x.result : null));
    }
    update(dto) {
        const url = `${this.baseUrl}/api/services/app/ProductLocation/update`;
        return this.http.put(url, dto)
            .pipe(map(x => x.result ? x.result : null));
    }
}
export class LocationDtoResult {
}
export class LocationDto {
}
export class ProductLocationDto {
}
