import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import localeAR from '@angular/common/locales/es-AR';
import localeCO from '@angular/common/locales/es-CO';
import { registerLocaleData } from '@angular/common';
import { HomeModule } from './home/home.module';
import { LocalizationService } from './services/localization.service';
import { NgxMaskModule } from 'ngx-mask';
import { StoreService } from './services/stores.service';
import { forkJoin } from 'rxjs';
import { UserService } from './services/user.service';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MyHttpInterceptor } from './shared/interceptors/httpInterceptor';
import { PickingMobileFormComponent } from './oms/picking-groups/picking-mobile-form.component';
import { EanScanComponent } from './products-lite/components/ean-scan/ean-scan.component';
import { SwUpdate } from '@angular/service-worker';
import { OmsTrackingComponent } from './tracking/tracking.component';
import { TransferQRComponent } from './transfers/public/transfer-qr/transfer-qr.component';
import { ProductLocationFormComponent } from './oms/picking-groups/product-location-form/product-location-form.component';
import { LocationWmsService, ProductLocationWmsService } from './services/wms.service';
import { ProductsService } from './services/products.service';
import { DataValidationLandingComponent } from './data-validation-landing/data-validation-landing.component';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { SuccessValidationMessageComponent } from './data-validation-landing/success-validation-message/success-validation-message.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule, GoogleAnalyticsService } from 'ngx-google-analytics';

registerLocaleData(localeAR, 'es-AR');
registerLocaleData(localeCO, 'es-CO');

@NgModule({
  declarations: [
   AppComponent,
   LoginComponent,
   RegistrationComponent,
   PickingMobileFormComponent,    
   EanScanComponent,
   OmsTrackingComponent,
   TransferQRComponent,
   ProductLocationFormComponent,
   DataValidationLandingComponent,
   SuccessValidationMessageComponent
  ],
  entryComponents: [
    EanScanComponent,
    ProductLocationFormComponent,
    SuccessValidationMessageComponent
  ],
  imports: [
    BrowserModule,
    HomeModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCa2U1n-Ns6AAfdd0fEpDlzhiVk653b3MU',
      libraries: ['places']
    }),
    SharedModule,
    MatPasswordStrengthModule,
    SocialLoginModule,
    MatGoogleMapsAutocompleteModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot('G-DJ4WF6LNZR'),
    NgxGoogleAnalyticsRouterModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MyHttpInterceptor, multi: true },
    { provide: LOCALE_ID, useFactory: (locService) => locService.getCulture(), deps: [LocalizationService] },
    { provide: APP_INITIALIZER, useFactory: initialize, deps: [LocalizationService, StoreService, UserService], multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.AUTH_CONFIG.clientId,
               {
                scope: 'email',
                plugin_name: 'puppis-demo-login'
              }
            )
          }
        ]
      } as SocialAuthServiceConfig,
    },
    ProductLocationWmsService,
    LocationWmsService,
    ProductsService,
    GoogleAnalyticsService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function initialize(localizationService: LocalizationService, storeService: StoreService, userService: UserService) {
  return () => forkJoin([localizationService.load(), storeService.load(), userService.load()]).toPromise();
}
