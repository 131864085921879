import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { StoreService } from 'src/app/services/stores.service';
import { TransferOmsService, TransferOrderInfoDto } from 'src/app/services/transfersOms.service';

// http://localhost:4200/public/transfer-qr/1

@Component({
    selector: 'app-transfer-qr',
    templateUrl: './transfer-qr.component.html',
    styleUrls: ['./transfer-qr.component.scss']
  })
  export class TransferQRComponent {

    private transferId : number = null;
    public transfer : TransferOrderInfoDto =  null;
    public isLoading  = false;
    public errorMsj   = '';
    public qrTransferUrl = '';

    

    constructor(private transferService: TransferOmsService,
                private activatedRoute: ActivatedRoute,
                private storeService: StoreService) {

      this.activatedRoute.params.subscribe((params) => {
        if (params.id) {
          this.transferId = Number(params.id);
          this.qrTransferUrl = `${window.location.origin}/transfers/transfer-accept/${this.transferId}`;
        }

      });
    }

    ngOnInit(): void {
      if (this.transferId) {
        this.getDataPage();
        console.log('data page')
      }
    }
  
    
    getDataPage() {

      
        this.isLoading = true;
        this.errorMsj = '';

        console.log('this.transfersService');

        this.transferService.getTransferOrderInfo(this.transferId)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (result) => {

                  this.transfer = result;
                  this.transfer.sourceStoreCode = this.storeService.getStoreName(this.transfer.sourceStoreCode),
                  this.transfer.destinationStoreCode = this.storeService.getStoreName(this.transfer.destinationStoreCode),
                  console.log('tranferencia obtenida ' + result);
                },
                (error) => {

                    //Error callback
                    // console.error('error caught in component ' + error.status);

                    //if (error.status == 400)
                        //this.errorMsj = 'El pedido es de retiro en tienda';

                    if (error.status == 404)
                        this.errorMsj = 'No se encuentra la trasferencia';
      
                    throw error;
                }
          );
      
    }


    public getSkus() : string {

      if(this.transfer?.transferItemsSkus.length > 0){
        return this.transfer?.transferItemsSkus.join(', ');
      }

      return '';

    }
  }
  