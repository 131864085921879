<div class="toolbar" role="banner">
    <img
      width="200"
      alt="Puppis Logo"
      src="https://puppis.vteximg.com.br/arquivos/logo_puppis.png?v=637490932890630000"
    />
</div>

<div class="content" role="main">

    <div class="card-container">

        <mat-list>

            <mat-list-item style="height: 52px" >
              <mat-icon mat-list-icon>directions_bus</mat-icon>
              <div mat-line> {{ 'TipoDeEntrega' | localize }} </div>
              <div mat-line> {{ this.transfer?.orderShippingType }} </div>
            </mat-list-item>

            <mat-list-item style="height: 52px" >
                <mat-icon mat-list-icon>store</mat-icon>
                <div mat-line> {{ 'TiendaOrigen' | localize }} </div>
                <div mat-line> {{ this.transfer?.sourceStoreCode }}  </div>
            </mat-list-item>


            <mat-list-item style="height: 52px" >
                <mat-icon mat-list-icon>store</mat-icon>
                <div mat-line> {{ 'TiendaDestino' | localize }} </div>
                <div mat-line> {{ this.transfer?.destinationStoreCode }}  </div>
            </mat-list-item>

            <mat-list-item style="height: 52px" >
                <mat-icon mat-list-icon>location_on</mat-icon>
                <div mat-line> {{ 'Dirección' | localize }} </div>
                <div mat-line> {{ this.transfer?.orderCompleteAdress }}  </div>
            </mat-list-item>

            <mat-list-item style="height: 52px" >
                <mat-icon mat-list-icon>calendar_today</mat-icon>
                <div mat-line> {{ 'FechaTransferencia' }} </div>
                <div mat-line> {{ this.transfer?.deliveryDate  | date: 'dd/MM/yyyy' }}  </div>
            </mat-list-item>

            <mat-list-item style="height: 52px" >
                <mat-icon mat-list-icon>chevron_right</mat-icon>
                <div mat-line> {{ 'NroTransferencia ' | localize }} </div>
                <div mat-line> {{ this.transfer?.trxSrcCode }}  </div>
            </mat-list-item>

            <mat-list-item style="height: 52px" >
                <mat-icon mat-list-icon>list</mat-icon>
                <div mat-line> {{ 'SkuPedidos ' | localize }} </div>
                <div mat-line> {{ getSkus() }}  </div>
            </mat-list-item>

          </mat-list>

          <qr-code [value]="qrTransferUrl" size="300" ></qr-code>
          

        <!-- <mat-card class="mat-card-center">

            <mat-card-content>
                

                <mat-card-title> Ingresá tu Nro de pedido </mat-card-title>
                <mat-card-subtitle> 
                    (Para compras online ingresa los 16 digitos incluyendo el guion y el digito de validación, para compras de call center ingresa los XX digitos de seguimiento de pedido)
                </mat-card-subtitle>

                <input type="text"  [(ngModel)]="numDeli" maxlength="25"/>

                <div *ngIf="errorMsj" class="alert">
                    <div> {{ errorMsj }} </div>
                </div>

            </mat-card-content>

            <mat-card-actions>
                <button mat-button class="blue-puppis"  (click)="getDataPage()" [disabled]="!numDeli" > Consultar </button>
            </mat-card-actions>


        </mat-card>




        <mat-card *ngIf="steps.length > 0" >

            <mat-card-content>


                <mat-grid-list cols="6" rowHeight="70px">

                    <mat-grid-tile [colspan]="1" >
                        <img src="/assets/icons/cardEnTransito-azul.svg" >
                    </mat-grid-tile>

                    <mat-grid-tile [colspan]="5" >

                        <div  class="step-item" fxLayout="column" fxLayoutAlign="space-around stretch" > 
                            <span  class="text-blue-puppis step-title"> Entrega Estimada : {{ estimatedTime }}  </span>
                            <span  class="step-subtitle"> {{ adress }} </span>
                        </div>

                    </mat-grid-tile>

                </mat-grid-list>

                
            </mat-card-content>

            <mat-divider> </mat-divider>

            <mat-card-content style="padding-top:16px">




                <mat-grid-list cols="6" rowHeight="60px">

                    <div *ngFor="let item of steps;let indice=index" >

                        <mat-grid-tile [colspan]="1" >

                            <img *ngIf="indice == 0 && item.isChecked"  src="/assets/icons/tracking/first-step.jpg" >
                            <img *ngIf="indice == 0 && !item.isChecked" src="/assets/icons/tracking/first-step-gray.jpg" > 
                            <img *ngIf="indice > 0 && indice < (steps.length - 1) &&  item.isChecked" src="/assets/icons/tracking/step.jpg" > 
                            <img *ngIf="indice > 0 && indice < (steps.length - 1) && !item.isChecked" src="/assets/icons/tracking/step-gray.jpg" > 
                            <img *ngIf="indice == (steps.length - 1) &&  item.isChecked" src="/assets/icons/tracking/last-step.jpg" >
                            <img *ngIf="indice == (steps.length - 1) && !item.isChecked" src="/assets/icons/tracking/last-step-gray.jpg" >

                        </mat-grid-tile>
    
                        <mat-grid-tile [colspan]="5" >
    
                            <div  class="step-item" fxLayout="column" fxLayoutAlign="space-around stretch" > 
                                <span *ngIf="item.isChecked"  class="text-blue-puppis step-title"> {{ item.stepDesc }}  </span>
                                <span *ngIf="!item.isChecked" class="text-gray-disabled step-title"> {{ item.stepDesc }}  </span>
                                <span *ngIf="item.isChecked" class="step-subtitle"> {{ item.dateDesc }} </span>
                            </div>
    
                        </mat-grid-tile>

                    </div>

                    

                </mat-grid-list>

            </mat-card-content>

        </mat-card> -->
    
      
    </div>

    
</div>

 <loading-indicator *ngIf="isLoading"></loading-indicator> 
  