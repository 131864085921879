const environmentFacingCameraLabelStrings: string[] = [
  'rear',
  'back',
  'rück',
  'arrière',
  'trasera',
  'trás',
  'traseira',
  'posteriore'
];

export function isKnownBackCameraLabel(label: string): boolean {
  const labelLowerCase = label.toLowerCase();
  return environmentFacingCameraLabelStrings.some(str => {
    return labelLowerCase.includes(str);
  });
}

export function getMainBarcodeScanningCamera(devices: MediaDeviceInfo[]): MediaDeviceInfo | undefined {
  const backCameras = devices.filter(v => isKnownBackCameraLabel(v.label));
  const sortedBackCameras = backCameras.sort((a, b) => a.label.localeCompare(b.label));
  return sortedBackCameras.length > 0 ? sortedBackCameras[0] : undefined;
}
