import * as i0 from "@angular/core";
import * as i1 from "../services/authentication.service";
import * as i2 from "@angular/router";
import * as i3 from "angular-oauth2-oidc";
export class AuthGuard {
    constructor(authenticationService, router, oauthService) {
        this.authenticationService = authenticationService;
        this.router = router;
        this.oauthService = oauthService;
    }
    canActivate(next, state) {
        this.authenticationService.setReturnUrl(state.url);
        if (!this.authenticationService.isAuthenticated()) {
            let params = getParamsObjectFromHash();
            if (params && params.access_token && params.expires_in && params.id_token) {
                navigateToProperUrl();
            }
            this.authenticationService.removeSession();
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
        return true;
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.OAuthService)); }, token: AuthGuard, providedIn: "root" });
export function getParamsObjectFromHash() {
    const hash = window.location.hash ? window.location.hash.split('#') : [];
    let toBeReturned = {};
    if (hash.length && hash[1].split('&').length) {
        toBeReturned = hash[1].split('&').reduce((acc, x) => {
            const hello = x.split('=');
            if (hello.length === 2)
                acc[hello[0]] = hello[1];
            return acc;
        }, {});
    }
    return Object.keys(toBeReturned).length ? toBeReturned : null;
}
export function navigateToProperUrl() {
    let newLocation = window.location.href;
    let urlSplitted = newLocation.split('#');
    if (urlSplitted[1]) {
        let domain = urlSplitted[0].replace('/login', '');
        window.location.replace(`${domain}/#/login?${urlSplitted[1]}`);
    }
}
