import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedRoutingModule } from './shared-routing.module';
import { OAuthModule } from 'angular-oauth2-oidc';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutComponent } from './components/layout/layout.component';
import { LogoutDialogComponent } from './components/layout/toolbar/user-profile/logout-dialog/logout-dialog.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatRadioModule,
  MatCardModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatListModule,
  MatMenuModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSliderModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatStepperModule,
  MatToolbarModule,
  MatSidenavModule,
  MatChipsModule,
  MatDialogModule,
  MatCheckboxModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatDividerModule,
  MatTabsModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatBadgeModule,
  MatSlideToggleModule,
  MatButtonToggleModule,
  MatTreeModule,
  MatSpinner
} from '@angular/material';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { FilterItemDirective } from './mat-filter/filter-item.directive';
import { MatFilterComponent } from './mat-filter/mat-filter.component';
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { EmptyResultsComponent } from './components/empty-results/empty-results.component';
import { ActionImageComponent } from './components/action-image/action-image.component';
import { TableFooterComponent } from './components/table-footer/table-footer.component';
import { MatSelectAsyncComponent } from './components/mat-select-async/mat-select-async.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { LocalizeMakedPipe, LocalizePipe } from './pipes/localize.pipe';
import { TableFilterColumnsComponent } from './components/table-filter-columns/table-filter-columns.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { CurrencyProxyPipe } from './pipes/currency-proxy';
import { AuthenticationInterceptor } from './interceptors/authentication-interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { StoreSelectorComponent } from './components/store-selector/store-selector.component';
import { MatSelectObservableComponent } from './components/mat-select-observable/mat-select-observable.component';
import { DisplayNameFromErpCodePipe } from './pipes/display-name-from-erp-code.pipe';
import { StorePipe } from './pipes/store.pipe';
import { UserPipe } from './pipes/user.pipe';
import { TitlePipe } from './pipes/title.pipe';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { MatTableDetailComponent } from './components/mat-table-detail/mat-table-detail.component';
import { OrderProductFormComponent } from './components/order-product-form/order-product-form.component';
import { CopyClipboardDirective } from './directives/copy-clipboard.directive';
import { QuadmindsResultComponent } from '../oms/orders/quadminds-preview/quadminds-result.component';
import { SourceNameImageComponent } from './components/source-name-image/source-name-image.component';
import { StoreSelectComponent } from './components/store-select/store-select.component';
import { RoleSelectorComponent } from './components/role-selector/role-selector.component';
import { UserSelectorComponent } from './components/user-selector/user-selector.component';
import { RoleService } from '../services/role.service';
import { ProcessesComponent } from './components/layout/toolbar/processes/processes.component';
import { SideNavComponent } from './components/layout/side-nav/side-nav.component';
import { CountryComponent } from './components/layout/toolbar/country/country.component';
import { UserProfileComponent } from './components/layout/toolbar/user-profile/user-profile.component';
import { NotificationsComponent } from './components/layout/toolbar/notifications/notifications.component';
import { NotificationsService } from '../services/notifications.service';
import { NotificationItemComponent } from './components/layout/toolbar/notifications/notification-item/notification-item.component';
import { QrCodeModule } from 'ng-qrcode';
import { StoreSelectorMultipleComponent } from './components/store-selector-multiple/store-selector-multiple.component';
import { HasRoleDirective } from './directives/has-role.directive';

@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    SharedRoutingModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatRadioModule,
    MatCardModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatStepperModule,
    MatToolbarModule,
    MatSidenavModule,
    MatChipsModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDividerModule,
    MatBadgeModule,
    MatTabsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    DragDropModule,
    MatTreeModule,
    OAuthModule.forRoot(),
    NgxPaginationModule,
    MatPasswordStrengthModule.forRoot(),
    NgxMatSelectSearchModule,
    NgxFileDropModule,
    MatMomentDateModule,
    NgxMaskModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    QrCodeModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatRadioModule,
    MatCardModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatStepperModule,
    MatToolbarModule,
    MatSidenavModule,
    MatChipsModule,
    MatBadgeModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDividerModule,
    MatTabsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    LayoutComponent,
    MatButtonToggleModule,
    MatTreeModule,
    DragDropModule,
    LogoutDialogComponent,
    OAuthModule,
    NgxPaginationModule,
    MatPasswordStrengthModule,
    MatFilterComponent,
    EmptyResultsComponent,
    ActionImageComponent,
    TableFooterComponent,
    TableFilterColumnsComponent,
    MatSelectAsyncComponent,
    NgxMatSelectSearchModule,
    LoadingIndicatorComponent,
    LocalizePipe,
    UserPipe,
    LocalizeMakedPipe,
    StorePipe,
    FileUploadComponent,
    CurrencyProxyPipe,
    NgxMaskModule,
    MatDatepickerModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    StoreSelectorComponent,
    StoreSelectorMultipleComponent,
    MatSelectObservableComponent,
    DisplayNameFromErpCodePipe,
    TitlePipe,
    HasPermissionDirective,
    HasRoleDirective,
    OrderProductFormComponent,
    CopyClipboardDirective,
    SourceNameImageComponent,
    StoreSelectComponent,
    RoleSelectorComponent,
    UserSelectorComponent,
    QrCodeModule,
  ],
  declarations: [
    LayoutComponent,
    LogoutDialogComponent,
    ConfirmationDialogComponent,
    FilterItemDirective,
    MatFilterComponent,
    StopPropagationDirective,
    EmptyResultsComponent,
    ActionImageComponent,
    TableFooterComponent,
    TableFilterColumnsComponent,
    MatSelectAsyncComponent,
    LoadingIndicatorComponent,
    LocalizePipe,
    LocalizeMakedPipe,
    StorePipe,
    UserPipe,
    TableFilterColumnsComponent,
    FileUploadComponent,
    CurrencyProxyPipe,
    StoreSelectorComponent,
    MatSelectObservableComponent,
    DisplayNameFromErpCodePipe,
    TitlePipe,
    HasPermissionDirective,
    HasRoleDirective,
    MatTableDetailComponent,
    OrderProductFormComponent,
    CopyClipboardDirective,
    QuadmindsResultComponent,
    SourceNameImageComponent,
    StoreSelectComponent,
    RoleSelectorComponent,
    UserSelectorComponent,
    ProcessesComponent,
    NotificationsComponent,
    SideNavComponent,
    CountryComponent,
    UserProfileComponent,
    NotificationItemComponent,
    StoreSelectorMultipleComponent
  ],
  entryComponents: [
    LogoutDialogComponent,
    ConfirmationDialogComponent,
    TableFilterColumnsComponent,
    MatSpinner,
    TableFilterColumnsComponent,
    OrderProductFormComponent,
    QuadmindsResultComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    CurrencyPipe,
    RoleService,
    NotificationsService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class SharedModule { }
