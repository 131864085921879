import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { MatSnackBar } from '@angular/material';
import { UserService } from '../services/user.service';
import { SocialAuthService } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginForm: FormGroup;
  loading = false;
  isSubmitted: boolean = false;
  isSubmittedForgot: boolean = false;
  showPassword: boolean = false;
  isLogged: boolean = false;
  invalidData: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: SocialAuthService,
    private activatedRoute: ActivatedRoute
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  get formControls() {
    return this.loginForm.controls;
  }

  login() {
    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    combineLatest([
      this.activatedRoute.queryParams,
      this.authenticationService.login(this.loginForm.value),
    ]).subscribe(
      (results) => {
        const data = results[1] as any;
        const returnUrl = results[0].returnUrl || '/';
        if (data && data.id) {
          this.setSessionData(data);
          this.router.navigateByUrl(returnUrl);
        }

        this.loading = false;
      },
      (error) => {
        this.invalidData = true;
        this.loading = false;
        console.error(error);
      }
    );
  }

  setShowPassword() {
    if (this.loginForm.controls.password.value) {
      this.showPassword = !this.showPassword;
    } else {
      this.showPassword = false;
    }
  }

  setSessionData(data: any) {
    this.authenticationService.setSessionToken(data.auth_Token);
    this.authenticationService.setRefreshToken(data.refresh_Token);
    const userData = this.authenticationService.decodePayloadJWT();
    this.authenticationService.setUserProfile(userData);
    localStorage.setItem('is_internal_user', 'true');
    this.authenticationService.setExpirationDate(data.expires_In);
    localStorage.setItem(
      'user',
      JSON.stringify({
        id: userData.id,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        storeCode: userData.storeCode,
        countryCode: userData.countryCode,
        isAdmin: userData.isAdmin,
      })
    );

    this.authenticationService.isLoggedIn$.next(true);
  }

  async loginWithGoogle() {
    this.loginForm.reset();
    this.isSubmitted = false;
    this.loading = true;
    try {
      const result = await this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
      console.log(result);
      const externalLogin = { providerId: result.provider, idToken: result.idToken };
      this.authenticationService.loginWithGoogleSingin(externalLogin).subscribe(
        (response: any) => {
          if (response && response.id) {
            this.authenticationService.setSessionToken(response.auth_Token);
            this.authenticationService.setRefreshToken(response.refresh_Token);
            const userData = this.authenticationService.decodePayloadJWT();
            this.authenticationService.setUserProfile(userData);
            localStorage.setItem('is_internal_user', 'true');
            this.authenticationService.setExpirationDate(response.expires_In);
            localStorage.setItem(
              'user',
              JSON.stringify({
                id: userData.id,
                firstName: userData.firstName,
                lastName: userData.lastName,
                email: userData.email,
                storeCode: userData.storeCode,
                countryCode: userData.countryCode,
                isAdmin: userData.isAdmin,
              })
            );

            this.authenticationService.isLoggedIn$.next(true);
            this.router.navigateByUrl('/oms/info');
          }
        },
        (error) => {
          this.invalidData = true;
          console.log(error);
        },
        () => (this.loading = false)
      );
    } catch (e) {
      this.loading = false;
    }
  }

  setResetPassword() {
    this.isSubmittedForgot = true;
    const emailControl = this.loginForm.controls['email'];
    if (emailControl.valid) {
      const email = emailControl.value;
      this.userService.setResetPassword(undefined, email).subscribe(
        (response: any) => {
          this.snackBar.open('Contraseña restablecida, verifique su casilla de mail', 'Cerrar', {
            duration: 5000,
          });
        },
        (error: any) => {
          this.snackBar.open('Surgió un error, no se ha restrablecido la contraseña', 'Cerrar', {
            duration: 5000,
          });
        }
      );
    }
  }
}
