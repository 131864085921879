import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LogoutDialogComponent } from './logout-dialog/logout-dialog.component';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent {

  constructor(private authenticationService: AuthenticationService, private dialog: MatDialog) {}

  get userProfile$() { return this.authenticationService.userProfile$; }

  openLogoutDialog(): void {
    const dialogRef = this.dialog.open(LogoutDialogComponent, {
      width: '22.857em',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this._logout();
      }
    });
  }

  private _logout() {
    this.authenticationService.logout();
  }
}
