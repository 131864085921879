import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TrackingService {
    constructor(http) {
        this.http = http;
        this.baseUrl = environment.omsUrl;
    }
    get(nro) {
        const queryUrl = `${this.baseUrl}/api/services/app/OrderTracking/Get?nro=${nro}`;
        return this.http
            .get(queryUrl)
            .pipe(map(x => x.result ? x.result : null));
    }
    validateOrderBySecureId(secureId) {
        const query = `${this.baseUrl}/api/services/app/OrderTracking/ValidateOrderBySecureId?secureid=${secureId}`;
        return this.http.get(query).pipe(map(x => x.result ? x.result : null));
    }
    updateDeliveryData(deliveryData) {
        const query = `${this.baseUrl}/api/services/app/OrderTracking/UpdateDeliveryData`;
        return this.http.post(query, Object.assign({}, deliveryData));
    }
}
TrackingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TrackingService_Factory() { return new TrackingService(i0.ɵɵinject(i1.HttpClient)); }, token: TrackingService, providedIn: "root" });
export class OrderTrackingDto {
}
export class OrderTrackingStepDto {
}
