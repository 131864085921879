import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { CommonService } from 'src/app/services/common.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshToken$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private dialogRef: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const isApiRequest =  request.url.startsWith(environment.clientUrl) ||
                          request.url.startsWith(environment.storesUrl) ||
                          request.url.startsWith(environment.securityUrl) ||
                          request.url.startsWith(environment.omsLiteUrl) ||
                          request.url.startsWith(environment.omsUrl) ||
                          request.url.startsWith(environment.productUrl) ||
                          request.url.startsWith(environment.salesUrl) ||
                          request.url.startsWith(environment.supplyingUrl) ||
                          request.url.startsWith(environment.productsLiteUrl) ||
                          request.url.startsWith(environment.notificationsUrl) ||
                          request.url.startsWith(environment.transfersUrl) ||
                          request.url.startsWith(environment.wmsUrl) ||
                          request.url.startsWith(environment.singleCustomerUrl) ||
                          request.url.startsWith(environment.puppisRepurchase);

    if (isApiRequest && this.authenticationService.isAuthenticated()) {
      request = this.addToken(request, this.authenticationService.getToken());
    }

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          if (error.url.endsWith('refresh-token')) {
            this.isRefreshing = false;
            this.dialogRef.closeAll();
            this.authenticationService.removeSession();
            this.router.navigate(['/login']);
          } else {
            return this.handle401Error(request, next);
          }
        }

        if (error.status === 403) {
          this.snackBar.open('No posee permisos para acceder a este recurso.', '', { duration: 1000 });
          this.router.navigate(['/']);
        }
      }

      return throwError(error);
    }));
  }

  private addToken(request: HttpRequest<any>, token: string | false): HttpRequest<any> {
    const userData = this.authenticationService.getUserData(localStorage.getItem('is_internal_user') === 'true');
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        Id: userData.id,
        Email: userData.email,
        CountryCode: this.commonService.getCookie('country'),
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshToken$.next(null);
      const refreshToken = this.authenticationService.getRefreshToken();

      return this.authenticationService.refreshToken(refreshToken).pipe(
        switchMap((jwt: any) => {
          this.isRefreshing = false;
          this.authenticationService.setSessionToken(jwt.auth_Token);
          this.authenticationService.setRefreshToken(jwt.refresh_Token);
          this.authenticationService.setExpirationDate(jwt.expires_In);
          this.refreshToken$.next(jwt);
          return next.handle(this.addToken(request, jwt.auth_Token));
        })
      );
    }

    return this.refreshToken$.pipe(
      filter(jwt => jwt != null),
      take(1),
      switchMap(jwt => {
        return next.handle(this.addToken(request, jwt.token));
      }));
  }
}
