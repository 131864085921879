import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Country } from 'src/app/shared/models/address/country';
import { CommonService } from '../../../../../services/common.service';
import { User } from '../../../../models/user';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss'],
})
export class CountryComponent implements OnInit {
  public countryList: Country[] = [
    { code: 'AR', name: 'Argentina', icon: 'icon-flag-arg' },
    { code: 'CO', name: 'Colombia', icon: 'icon-flag-col' },
    { code: 'PPCO', name: 'PetPlus', icon: 'icon-flag-ppco' },
  ];
  public selectedCountry: Country = { code: 'AR', name: 'Argentina', icon: 'icon-flag-arg' };
  public disabledSelectCountry = true;

  constructor(private authenticationService: AuthenticationService, private commonService: CommonService) {}

  get userProfile$() {
    return this.authenticationService.userProfile$;
  }

  ngOnInit() {
    this.userProfile$.subscribe((profile) => {
      if (profile) this.setDefaultCountry(profile.countryCode);
    });
    this.commonService.selectedCountry$.subscribe((country) => {
      if (!country) {
        this.setDefaultCountry();
      } else {
        this.commonService.setCookie('country', country.code, 120);
        this.selectedCountry = country;
      }
    });
  }

  selectCountry(country: Country) {
    this.commonService.setSelectedCountry(country);
  }

  setDefaultCountry(countryCode?: string) {
    // let countryCode = this.userProfile$?.value?.countryCode;

    if (!countryCode) {
      countryCode = this.commonService.getCookie('country');
      this.disabledSelectCountry = false;
    }

    if (countryCode) this._setCountry(countryCode);
    else this.commonService.setCookie('country', this.selectedCountry.code, 120);
  }

  private _setCountry(code: string) {
    if (code === 'AR') {
      this.selectedCountry = { code: 'AR', name: 'Argentina', icon: 'icon-flag-arg' };
    } else if (code === 'CO') {
      this.selectedCountry = { code: 'CO', name: 'Colombia', icon: 'icon-flag-col' };
    } else if(code === 'PPCO') {
      this.selectedCountry = { code: 'PPCO', name: 'PetPlus', icon: 'icon-flag-ppco' };
    }
  }
}
