<div class="login__container">

  <div class="login__header">
    <div class="user__image">
      <mat-icon [inline]="true" class="user-image__icon">account_circle</mat-icon>
    </div>
    <div class="user__info">
      <h1>{{ 'LoginTitle' | localize }}</h1>
    </div>
  </div>
  <div class="login__form" fxLayout="column">
    <form [formGroup]="loginForm" (ngSubmit)="login()">
      <div [ngClass]="{ 'has-error': isSubmitted && formControls.email.errors }">
        <mat-form-field appearance="outline">
          <mat-label class="placeholder">Email</mat-label>
          <input matInput id="email" type="email" formControlName="email">
          <mat-error *ngIf="(isSubmitted || isSubmittedForgot) && formControls.email.errors?.required">
            {{ 'LoginRequiredUserName' | localize }}
          </mat-error>
          <mat-error *ngIf="(isSubmitted || isSubmittedForgot) && formControls.email.errors?.email">
            {{ 'LoginInvalidUserName' | localize }}
          </mat-error>
        </mat-form-field>
      </div>
      <div [ngClass]="{ 'has-error': isSubmitted && formControls.password.errors }">
        <mat-form-field appearance="outline">
          <mat-label class="placeholder">{{ 'LoginPassword' | localize }}</mat-label>
          <mat-icon matSuffix *ngIf="!showPassword" (click)="setShowPassword()">remove_red_eye</mat-icon>
          <mat-icon matSuffix svgIcon="icon-hide-password" aria-label="HidePassword" *ngIf="showPassword"
            (click)="setShowPassword()"></mat-icon>
          <input matInput id="password" [type]="showPassword ? 'text' : 'password'" formControlName="password">
          <mat-error *ngIf="isSubmitted && formControls.password.errors?.required">
            {{ 'LoginRequiredPassword' | localize }}
          </mat-error>
          <mat-error *ngIf="isSubmitted && formControls.password.errors?.minlength">
            {{ 'LoginPasswordMinLength' | localize }}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="isSubmitted && invalidData">
        <p class="error-message"> {{ 'LoginInvalidUserOrPassword' | localize }}</p>
      </div>
      <div class="action__buttons">
        <button mat-button type="submit" class="btn__ingresar btn__primary" [class.spinner]="loading"
          [disabled]="loading" (click)="login()">{{ 'LoginButton' | localize }}</button>
        <a mat-button (click)="setResetPassword()"
          class="btn__link btn__forgot-password">{{ 'LoginForgotMyPassword' | localize }}</a>

        <button mat-button type="button" (click)="loginWithGoogle()" class="btn__google">
          <mat-icon class="google-icon" svgIcon="icon-google" aria-label="Acceder con Google"></mat-icon>
          <span class="google-text">{{ 'LoginWithGoogle' | localize }}</span>
        </button>
      </div>
    </form>
  </div>
</div>