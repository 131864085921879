import { Action } from 'src/app/shared/models/roles/action';

const allActions = [
  //General
  {
    name: "Seleccionar país",
    module: "Shared",
    route: "/",
    components: [
      {
        name: "layout",
        components: [
          {
            name: "toolbar"
          }
        ]
      }
    ]
  },
  {
    name: "Mostrar menu",
    module: "Shared",
    route: "/",
    components: [
      {
        name: "layout",
        components: [
          {
            name: "menu"
          }
        ]
      }
    ]
  },
  //Cards
  {
    name: "Ver cards",
    module: "Clients",
    route: "/Clientes",
    components: [
      {
        name: "client-dashboard",
        components: [
          {
            name: "client-card-list"
          },
          {
            name: "client-card"
          }
        ]
      }
    ]
  },
  {
    name: "Excluir cards",
    module: "Clients",
    route: "/Clientes",
    components: [
      {
        name: "client-dashboard",
        components: [
          {
            name: "client-card-list"
          },
          {
            name: "client-card"
          }
        ]
      }
    ]
  },
  {
    name: "Abrir card",
    module: "Clients",
    route: "/Clientes",
    components: [
      {
        name: "client-dashboard",
        components: [
          {
            name: "client-card-list"
          },
          {
            name: "client-card"
          }
        ]
      }
    ]
  },

  //Clients
  {
    name: "Listar clientes",
    module: "Clients",
    route: "/Clientes",
    components: [
      {
        name: "client-dashboard",
        components: [
          {
            name: "client-list"
          }
        ]
      }
    ]
  },
  {
    name: "Filtrar clientes",
    module: "Clients",
    route: "/Clientes",
    components: [
      {
        name: "client-dashboard",
        components: [
          {
            name: "client-list"
          }
        ]
      }
    ]
  },
  {
    name: "Seleccionar clientes ",
    module: "Clients",
    route: "/Clientes",
    components: [
      {
        name: "client-dashboard",
        components: [
          {
            name: "client-list"
          }
        ]
      }
    ]
  },
  {
    name: "Ver cliente",
    module: "Clients",
    route: "/Clientes/detalle/:clientId",
    components: [
      {
        name: "client-details-read-only",
        components: null
      }
    ]
  },
  {
    name: "Abrir desunir cliente ",
    module: "Clients",
    route: "/Clientes/detalle/:clientId",
    components: [
      {
        name: "client-details-read-only",
        components: null
      }
    ]
  },
  {
    name: "Crear cliente",
    module: "Clients",
    route: "/Clientes/detalle/:clientId",
    components: [
      {
        name: "client-detail",
        components: null
      }
    ]
  },
  {
    name: "Editar cliente",
    module: "Clients",
    route: "/Clientes/detalle/:clientId",
    components: [
      {
        name: "client-detail",
        components: null
      }
    ]
  },

  // merge wizard
  {
    name: "Ver posibles duplicados",
    module: "Clients",
    route: "/Clientes",
    components: [
      {
        name: "pre-merge-wizard",
        components: [
          {
            name: "candidate-client"
          }
        ]
      }
    ]
  },
  {
    name: "Seleccionar candidatos a mergear",
    module: "Clients",
    route: "/Clientes",
    components: [
      {
        name: "pre-merge-wizard",
        components: [
          {
            name: "candidate-client"
          }
        ]
      }
    ]
  },
  {
    name: "Mostrar detalle de candidatos a mergear",
    module: "Clients",
    route: "/Clientes",
    components: [
      {
        name: "merge-wizard",
        components: null
      }
    ]
  },
  {
    name: "Mergear clientes",
    module: "Clients",
    route: "/Clientes",
    components: [
      {
        name: "merge-wizard",
        components: null
      }
    ]
  },
  //Animales
  {
    name: "Listar Animales",
    module: "Products",
    route: "/productos/animal/lista",
    components: [
      {
        name: "animal-list",
        components: null
      }
    ]
  },
  {
    name: "Filtrar Animales",
    module: "Products",
    route: "/productos/animal/lista",
    components: [
      {
        name: "animal-list",
        components: null
      }
    ]
  },
  {
    name: "Crear/Editar Animales",
    module: "Products",
    route: "/productos/animalform",
    components: [
      {
        name: "animal-form",
        components: null
      }
    ]
  },
  {
    name: "Eliminar Animales",
    module: "Products",
    route: "/productos/animalform",
    components: [
      {
        name: "animal-form",
        components: null
      }
    ]
  },

  //Marcas
  {
    name: "Listar Marcas",
    module: "Products",
    route: "/productos/marcas",
    components: [
      {
        name: "brand-list",
        components: null
      }
    ]
  },
  {
    name: "Filtrar Marcas",
    module: "Products",
    route: "/productos/marcas",
    components: [
      {
        name: "brand-list",
        components: null
      }
    ]
  },
  {
    name: "Crear/Editar Marcas",
    module: "Products",
    route: "/productos/marcasform",
    components: [
      {
        name: "brand-form",
        components: null
      }
    ]
  },
  {
    name: "Eliminar Marcas",
    module: "Products",
    route: "/productos/marcasform",
    components: [
      {
        name: "brand-form",
        components: null
      }
    ]
  },

  //Clusters
  {
    name: "Listar Clusters",
    module: "Products",
    route: "/productos/clusters",
    components: [
      {
        name: "cluster-list",
        components: null
      }
    ]
  },
  {
    name: "filtrar Clusters",
    module: "Products",
    route: "/productos/clusters",
    components: [
      {
        name: "cluster-list",
        components: null
      }
    ]
  },
  {
    name: "Crear/Editar Clusters",
    module: "Products",
    route: "/productos/clusterForm",
    components: [
      {
        name: "cluster-form",
        components: null
      }
    ]
  },

  //Categories
  {
    name: "Listar Categorias",
    module: "Products",
    route: "/productos/categorias",
    components: [
      {
        name: "category-list",
        components: null
      }
    ]
  },
  {
    name: "Filtrar Categorias",
    module: "Products",
    route: "/productos/categorias",
    components: [
      {
        name: "category-list",
        components: null
      }
    ]
  },
  {
    name: "Crear/Editar Categorias",
    module: "Products",
    route: "/productos/categorias",
    components: [
      {
        name: "category-tree",
        components: [
          {
            name: "node-menu",
            components: null
          }
        ]
      }
    ]
  },
  {
    name: "Eliminar Categorias",
    module: "Products",
    route: "/productos/categorias",
    components: [
      {
        name: "category-tree",
        components: [
          {
            name: "node-menu",
            components: null
          }
        ]
      }
    ]
  },

  //Store
  {
    name: "Listar Tiendas",
    module: "Products",
    route: "/productos/tiendas",
    components: [
      {
        name: "store-list",
        components: null
      }
    ]
  },
  {
    name: "filtrar Tiendas",
    module: "Products",
    route: "/productos/tiendas",
    components: [
      {
        name: "store-list",
        components: null
      }
    ]
  },
  {
    name: "Crear/Editar Tiendas",
    module: "Products",
    route: "/productos/storeForm",
    components: [
      {
        name: "store-form",
        components: null
      }
    ]
  },

  //Grupo Atributos
  {
    name: "Listar Grupo de Atributos",
    module: "Products",
    route: "/productos/grupoAtributos",
    components: [
      {
        name: "attribute-group-list",
        components: null
      }
    ]
  },
  {
    name: "Filtrar Grupo de Atributos",
    module: "Products",
    route: "/productos/grupoAtributos",
    components: [
      {
        name: "attribute-group-list",
        components: null
      }
    ]
  },
  {
    name: "Crear/Editar Grupo de Atributos",
    module: "Products",
    route: "/productos/grupoAtributos",
    components: [
      {
        name: "attribute-group-form",
        components: null
      }
    ]
  },
  {
    name: "Eliminar Grupo de Atributos",
    module: "Products",
    route: "/productos/grupoAtributos",
    components: [
      {
        name: "attribute-group-form",
        components: null
      }
    ]
  },

   //Grupo Atributos
  {
    name: "Listar Grupo de Atributos",
    module: "Products",
    route: "/productos/grupoAtributos",
    components: [
      {
        name: "attribute-group-list",
        components: null
      }
    ]
  },
  {
    name: "Filtrar Grupo de Atributos",
    module: "Products",
    route: "/productos/grupoAtributos",
    components: [
      {
        name: "attribute-group-list",
        components: null
      }
    ]
  },
  {
    name: "Crear/Editar Grupo de Atributos",
    module: "Products",
    route: "/productos/grupoAtributos",
    components: [
      {
        name: "attribute-group-form",
        components: null
      }
    ]
  },
  {
    name: "Eliminar Grupo de Atributos",
    module: "Products",
    route: "/productos/grupoAtributos",
    components: [
      {
        name: "attribute-group-form",
        components: null
      }
    ]
  },

  //Familias
  {
    name: "Listar Familias",
    module: "Products",
    route: "/productos/familias",
    components: [
      {
        name: "family-list",
        components: null
      }
    ]
  },
  {
    name: "Filtrar Familias",
    module: "Products",
    route: "/productos/familias",
    components: [
      {
        name: "family-list",
        components: null
      }
    ]
  },
  {
    name: "Crear/Editar Familias",
    module: "Products",
    route: "/productos/familias",
    components: [
      {
        name: "family-form",
        components: null
      }
    ]
  },
  {
    name: "Eliminar Familias",
    module: "Products",
    route: "/productos/familias",
    components: [
      {
        name: "family-form",
        components: null
      }
    ]
  },
  //Usuarios
  {
    name: "Listar usuarios",
    module: "Users",
    route: "/usuarios/lista",
    components: [
      {
        name: "user-list",
        components: null
      }
    ]
  },
  {
    name: "Habilitar usuario",
    module: "Users",
    route: "/usuarios/lista",
    components: [
      {
        name: "user-list",
        components: null
      }
    ]
  },
  {
    name: "Deshabilitar usuario",
    module: "Users",
    route: "/usuarios/lista",
    components: [
      {
        name: "user-list",
        components: null
      }
    ]
  },
  {
    name: "Filtrar usuarios",
    module: "Users",
    route: "/usuarios/lista",
    components: [
      {
        name: "user-list",
        components: null
      }
    ]
  },
  {
    name: "Crear usuario",
    module: "Users",
    route: "/usuarios/form",
    components: [
      {
        name: "user-form",
        components: null
      }
    ]
  },
  {
    name: "Editar usuario",
    module: "Users",
    route: "/usuarios/form",
    components: [
      {
        name: "user-form",
        components: null
      }
    ]
  },
  {
    name: "Reestablecer contraseña",
    module: "Users",
    route: "/usuarios/form",
    components: [
      {
        name: "user-form",
        components: null
      }
    ]
  }
];


export function getAllActions(): Action[] {
  return allActions;
}