import { identifierModuleUrl } from '@angular/compiler';
import { FeatureFlagHelper } from '../helpers/feature-flag-helper';

export interface NavigationItem {
    id?: string;
    title?: string;
    subtitle?: string;
    type: 'aside' | 'basic' | 'collapsable' | 'divider' | 'group' | 'spacer';
    hidden?: (item: NavigationItem) => boolean;
    active?: boolean;
    disabled?: boolean;
    link?: string;
    externalLink?: boolean;
    exactMatch?: boolean;
    function?: (item: NavigationItem) => void;
    classes?: {
        title?: string;
        subtitle?: string;
        icon?: string;
        wrapper?: string;
    };
    icon?: string;
    badge?: {
        title?: string;
        classes?: string;
    };
    permissionName?: string[];
    children?: NavigationItem[];
    expanded?: boolean;
    meta?: any;
    onlyForcountry?: string;
}

export const navigationItems: NavigationItem[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'group',
        icon: 'home',
        expanded: false,
        children: [
            {
                id: 'home.info',
                title: 'Landing',
                type: 'basic',
                link: '/oms/info',
            },
            // {
            //     id: 'home.wiki',
            //     title: 'Wiki',
            //     type: 'basic',
            //     link: '/landing/wiki',
            // }
        ],
    },
    {
        id: 'oms',
        title: 'OMS',
        type: 'group',
        icon: 'local_mall',
        expanded: false,
        permissionName: [
            'PuppisOmsApplication.OrderAppService.GetAll',
            'PuppisOmsApplication.OrderReadyToHandleAppService.GetAll',
            'PuppisOmsApplication.PickingGroupAppService.GetAll',
            'PuppisOmsApplication.DispatchOrderAppService.GetAll',
            'PuppisOmsApplication.ShipmentAppService.GetAll',
            'PuppisOmsApplication.ClaimAppService.GetAll',
            'PuppisOmsApplication.ReturnAppService.GetAll',
            'PuppisOmsApplication.OrderLogisticStatusAppService.GetAll',
            'PuppisOmsApplication.PendingManagementAppService.GetAll',
            'PuppisOmsApplication.OrderInvoicesAppService.GetAll',
            'PuppisOmsApplication.OrderStorePickupAppService.GetAll',
            'PuppisOmsApplication.OrderGeneralAuditAppServiceService.GetAll',
        ],
        children: [
            {
                id: 'oms.orders',
                title: 'orders',
                type: 'basic',
                permissionName: ['PuppisOmsApplication.OrderAppService.GetAll'],
                link: '/oms/orders',
            },
            {
                id: 'oms.ordersReadyToHandle',
                title: 'ordersReadyToHandle',
                type: 'basic',
                permissionName: ['PuppisOmsApplication.OrderReadyToHandleAppService.GetAll'],
                link: '/oms/orders-ready-to-handle',
            },
            {
                id: 'oms.orderSeparations',
                title: 'orderSeparations',
                type: 'basic',
                permissionName: ['PuppisOmsApplication.PickingGroupAppService.GetAll'],
                link: '/oms/orders-separations',
            },
            {
                id: 'oms.ordersReadyToShip',
                title: 'ordersReadyToShip',
                type: 'basic',
                permissionName: ['PuppisOmsApplication.DispatchOrderAppService.GetAll'],
                link: '/oms/orders-ready-to-dispatch',
            },
            {
                id: 'oms.ordersStorePickup',
                title: 'ordersStorePickup',
                type: 'basic',
                permissionName: ['PuppisOmsApplication.OrderStorePickupAppService.GetAll'],
                link: '/oms/orders-store-pickup',
            },
            {
                id: 'oms.shipments',
                title: 'shipments',
                type: 'basic',
                permissionName: ['PuppisOmsApplication.ShipmentAppService.GetAll'],
                link: '/oms/shipments',
            },
            {
                id: 'oms.claims',
                title: 'claims',
                type: 'basic',
                permissionName: ['PuppisOmsApplication.ClaimAppService.GetAll'],
                link: '/oms/claims',
            },
            {
                id: 'oms.returns',
                title: 'returns',
                type: 'basic',
                permissionName: ['PuppisOmsApplication.ReturnAppService.GetAll'],
                link: '/oms/returns',
            },
            {
                id: 'oms.pendingReturns',
                title: 'pendingReturns',
                type: 'basic',
                permissionName: ['PuppisOmsApplication.PendingReturnAppService.GetAll'],
                link: '/oms/pending-returns',
            },
            {
                id: 'oms.generalAudit',
                title: 'generalAudit',
                type: 'basic',
                disabled: FeatureFlagHelper.isGeneralAuditDisabled(),
                permissionName: ['PuppisOmsApplication.OrderGeneralAuditAppServiceService.GetAll'],
                link: '/oms/general-audit',
            },
            {
                id: 'oms.ordersLogisticAudit',
                title: 'ordersLogisticAudit',
                type: 'basic',
                permissionName: ['PuppisOmsApplication.OrderLogisticStatusAppService.GetAll'],
                link: '/oms/orders-logistic-audit',
            },
            {
                id: 'oms.pendingManagemet',
                title: 'pendingManagemet',
                type: 'basic',
                permissionName: ['PuppisOmsApplication.PendingManagementAppService.GetAll'],
                link: '/oms/pending-management',
            },
            {
                id: 'oms.invoices',
                title: 'invoices',
                type: 'basic',
                permissionName: ['PuppisOmsApplication.OrderInvoicesAppService.GetAll'],
                // disabled: FeatureFlagHelper.isInvoiceMonitorDisabled(),
                link: '/oms/invoices',
            },
            {
                id: 'oms.meli-notification',
                title: 'addMeliOrder',
                type: 'basic',
                permissionName: ['PuppisOmsApplication.MeliNotificationAppService.Create'],
                link: '/oms/meli-notification',
            },
            {
                id: 'oms.additionalTables',
                title: 'additionalTables',
                type: 'basic',
                permissionName: ['Admin'],
                children: [
                    {
                        id: 'oms.aliases',
                        title: 'aliases',
                        type: 'basic',
                        link: '/oms/custom-values/alias',
                    },
                    {
                        id: 'oms.carriers',
                        title: 'carriers',
                        type: 'basic',
                        link: '/oms/carriers',
                    },
                    {
                        id: 'oms.deliveryIssues',
                        title: 'deliveryIssues',
                        type: 'basic',
                        link: '/oms/custom-values/delivery-issue',
                    },
                    {
                        id: 'oms.carrierDrivers',
                        title: 'carrierDrivers',
                        type: 'basic',
                        link: '/oms/carrier-drivers',
                    },
                    //   {
                    //       id   : 'oms.promotions',
                    //       title: 'promotions',
                    //       type : 'basic',
                    //       link : '/oms/promotions',
                    //   },
                    {
                        id: 'oms.shipmentIssues',
                        title: 'shipmentIssues',
                        type: 'basic',
                        link: '/oms/custom-values/shipment-issue',
                    },
                    {
                        id: 'oms.dispatchTags',
                        title: 'dispatchTags',
                        type: 'basic',
                        link: '/oms/custom-values/dispatch-tag',
                    },
                    {
                        id: 'oms.shippingTypes',
                        title: 'shippingTypes',
                        type: 'basic',
                        link: '/oms/shippingtypes',
                    },
                    {
                        id: 'oms.shippingTypeMappings',
                        title: 'shippingTypeMappings',
                        type: 'basic',
                        link: '/oms/shippingtypemappings',
                    },
                    {
                        id: 'oms.shippingTypeGroup',
                        title: 'ShippingTypeGroup',
                        type: 'basic',
                        link: '/oms/shippingtypes-group',
                    },
                    // {
                    //   id: 'oms.storePostalCodes',
                    //   title: 'storePostalCodes',
                    //   type: 'basic',
                    //   link: '/oms/store-postal-codes',
                    // },
                    // {
                    //   id: 'oms.mappingDeliveryTimes',
                    //   title: 'storePostalCodes',
                    //   type: 'basic',
                    //   link: '/oms/mapping-delivery-times',
                    // },
                    {
                        id: 'oms.vtexAccounts',
                        title: 'vtexAccounts',
                        type: 'basic',
                        link: '/oms/vtex-accounts',
                    },
                    {
                        id: 'oms.flyers',
                        title: 'Flyers',
                        type: 'basic',
                        link: '/oms/flyers',
                    },
                    {
                        id: 'oms.flyers-abm',
                        title: 'Flyers ABM',
                        type: 'basic',
                        link: '/oms/flyers-abm',
                    },
                    {
                        id: 'oms.rappi',
                        title: 'OmsRappi',
                        type: 'basic',
                        link: '/oms/rappi',
                        onlyForcountry: 'CO',
                    },
                    {
                        id: 'oms.bins',
                        title: 'binCodes',
                        type: 'basic',
                        link: '/oms/bins',
                    },
                    {
                        id: 'oms.picking-groups-rules',
                        title: 'pickingGroupRules',
                        type: 'basic',
                        link: '/oms/picking-groups-rules',
                    },
                    {
                        id: 'oms.delivery-schedules',
                        title: 'deliverySchedules',
                        type: 'basic',
                        link: '/oms/delivery-schedule',
                    },
                ],
            },
        ],
    },
    {
        id: 'zoho',
        title: 'statistic',
        type: 'group',
        icon: 'timeline',
        expanded: false,
        children: [
            {
                id: 'statistic',
                title: 'statistic',
                type: 'basic',
                link: '/oms/statistisc',
            },
        ],
    },
    {
        id: 'wms',
        title: 'WMS',
        type: 'group',
        icon: 'warehouse',
        expanded: false,
        disabled: !FeatureFlagHelper.isWmsEnable(),
        onlyForcountry: 'CO',
        permissionName: ['PuppisWmsApplication.ProductLocationAppService.GetAll'],
        children: [
            {
                id: 'wms.manage',
                title: 'Manage',
                type: 'basic',
                link: '/wms',
                permissionName: ['PuppisWmsApplication.ProductLocationAppService.GetAll'],
            },
        ],
    },
    {
        id: 'pim',
        title: 'Products',
        type: 'group',
        icon: 'shopping_cart',
        expanded: false,
        permissionName: [
            'PuppisProductsLiteApplication.ProductAppService.GetAll',
            'PuppisProductsLiteApplication.TargetCategoryAppService.GetAll',
            'PuppisProductsLiteApplication.SalesCategoryAppService.GetAll',
            'PuppisProductsLiteApplication.ClusterAppService.GetAll',
            'PuppisProductsLiteApplication.PromotionAppService.GetAll',
        ],
        children: [
            {
                id: 'pim.products',
                title: 'Products',
                type: 'basic',
                link: '/products',
                permissionName: ['PuppisProductsLiteApplication.ProductAppService.GetAll'],
            },
            {
                id: 'pim.TargetCategory',
                title: 'TargetCategory',
                type: 'basic',
                link: '/products/targetcategories',
                permissionName: ['PuppisProductsLiteApplication.TargetCategoryAppService.GetAll'],
            },
            {
                id: 'pim.SalesCategory',
                title: 'SalesCategory',
                type: 'basic',
                link: '/products/salescategory',
                permissionName: ['PuppisProductsLiteApplication.SalesCategoryAppService.GetAll'],
            },
            {
                id: 'pim.Cluster',
                title: 'ClusterProducts',
                type: 'basic',
                link: '/products/productsagrupations',
                permissionName: ['PuppisProductsLiteApplication.ClusterAppService.GetAll'],
            },
            {
                id: 'pim.Promotions',
                title: 'PromotionsManagement',
                type: 'basic',
                link: '/products/promotions-management',
                permissionName: ['PuppisProductsLiteApplication.PromotionAppService.GetAll'],
            },
        ],
    },
    {
        id: 'sales',
        title: 'Sales',
        type: 'group',
        icon: 'point_of_sale',
        expanded: false,
        disabled: !FeatureFlagHelper.isSalesEnable(),
        children: [
            {
                id: 'sales.sales',
                title: 'SalesList',
                type: 'basic',
                link: '/sales',
            },
            {
                id: 'sales.channel',
                title: 'Channel',
                type: 'basic',
                link: '/sales/channel',
            },
            {
                id: 'sales.business',
                title: 'Business',
                type: 'basic',
                link: '/sales/business',
            },
        ],
    },
    {
        id: 'customer',
        title: 'Customers',
        type: 'group',
        icon: 'sentiment_satisfied',
        expanded: false,
        permissionName: [
            'PuppisSingleCustomerApplication.ClienteUnicoAppService.GetAll',
            'PuppisSingleCustomerApplication.ClusterAppService.GetAll',
        ],
        children: [
            {
                id: 'customer.customers',
                title: 'Customers',
                type: 'basic',
                link: '/clientes',
                permissionName: [
                    'PuppisSingleCustomerApplication.ClienteUnicoAppService.GetAll',
                ],
            },
            {
                id: 'customer.clusters',
                title: 'Clusters',
                type: 'basic',
                link: '/clientes/clusters',
                permissionName: ['PuppisSingleCustomerApplication.ClusterAppService.GetAll'],
            },

            /*
      {
        id: 'customer.customersHistory',
        title: 'CustomersHistory',
        type: 'basic',
        link: '/clientes/historial',
      },
      {
        id: 'customer.stats',
        title: 'CustomerStats',
        type: 'basic',
        link: '/clientes/estadisticas',
      },
*/
        ],
    },
    {
        id: 'supplying',
        title: 'SupplyingModule',
        type: 'group',
        icon: 'inventory_2',
        expanded: false,
        children: [
            {
                id: 'supplying.stages',
                title: 'stages',
                type: 'basic',
                link: '/supplying/stages',
                permissionName: ['PuppisSupplyingApplication.StageAppService.GetAll'],
            },
            {
                id: 'supplying.additionalTables',
                title: 'additionalTables',
                type: 'basic',
                permissionName: [
                    'PuppisSupplyingApplication.StoreAppService.GetAll',
                    'PuppisSupplyingApplication.SafetyStockAppService.GetAll',
                ],
                children: [
                    {
                        id: 'supplying.stores',
                        title: 'stores',
                        type: 'basic',
                        link: '/supplying/stores',
                        permissionName: ['PuppisSupplyingApplication.StoreAppService.GetAll'],
                    },
                    {
                        id: 'supplying.safetyStock',
                        title: 'safetyStock',
                        type: 'basic',
                        link: '/supplying/safety-stock',
                        permissionName: ['PuppisSupplyingApplication.SafetyStockAppService.GetAll'],
                    },
                ],
            },
            {
                id: 'supplying.repurchase',
                title: 'repurchase',
                type: 'basic',
                link: '/supplying/repurchase',
                permissionName: ['PuppisSupplyingApplication.StageAppService.GetAll'],
            },
        ],
    },
    {
        id: 'store',
        title: 'Stores',
        type: 'group',
        icon: 'store_mall_directory',
        expanded: false,
        children: [
            {
                id: 'store.stores',
                title: 'Stores',
                type: 'basic',
                link: '/stores',
                permissionName: ['PuppisStoresApplication.StoreAppService.GetAll'],
            },
            {
                id: 'store.clusters',
                title: 'StoreClusters',
                type: 'basic',
                link: '/stores/clusters',
                permissionName: ['PuppisStoresApplication.ClusterAppService.GetAll'],
            },
            {
                id: 'store.tpc',
                title: 'thirdPartyCode',
                type: 'basic',
                link: '/stores/tpc',
                permissionName: ['PuppisStoresApplication.ThirdPartyCodeAppService.GetAll'],
            },
            {
                id: 'oms.mappingDeliveryTimes',
                title: 'storePostalCodes',
                type: 'basic',
                link: '/oms/mapping-delivery-times',
                permissionName: ['PuppisOmsApplication.HubDeliveryMappingAppService.GetAll'],
            },
            {
                id: 'store.mappingPolygons',
                title: 'storePolygons',
                type: 'basic',
                link: '/stores/mapping-polygons',
                disabled: FeatureFlagHelper.isPolygonDisabled(),
                permissionName: ['PuppisStoresApplication.StorePolygonAppService.GetAll'],
            },
        ],
    },
    {
        id: 'mailing',
        title: 'Mailing',
        type: 'group',
        icon: 'email',
        expanded: false,
        permissionName: ['Puppis.Mailing.Web.Controllers.EmailController.GetAll'],
        children: [
            {
                id: 'mailing.config',
                title: 'ConfigSendEmail',
                type: 'basic',
                link: '/mailling/config-send',
                permissionName: ['Puppis.Mailing.Web.Controllers.SubscriptionController.GetAll'],
            },
            {
                id: 'mailing.shippingAdmin',
                title: 'shippingAdmin',
                type: 'basic',
                link: '/mailling/shipping-admin',
                permissionName: ['Puppis.Mailing.Web.Controllers.EmailController.GetAll'],
            },
            {
                id: 'mailing.masterData',
                title: 'masterData',
                type: 'basic',
                link: '/mailling/master-data',
                permissionName: ['Puppis.Mailing.Web.Controllers.ClientConfigurationController.GetAll'],
            },
            {
                id: 'mailing.control',
                title: 'controlMessage',
                type: 'basic',
                permissionName: [
                    'Puppis.Mailing.Web.Controllers.TemplateController.GetAll',
                    'Puppis.Mailing.Web.Controllers.SenderController.GetAll',
                ],
                children: [
                    {
                        id: 'mailing.templates',
                        title: 'mailTemplates',
                        type: 'basic',
                        link: '/mailling/templates',
                        permissionName: ['Puppis.Mailing.Web.Controllers.TemplateController.GetAll'],
                    },
                    {
                        id: 'mailing.senders',
                        title: 'mailSenders',
                        type: 'basic',
                        link: '/mailling/senders',
                        permissionName: ['Puppis.Mailing.Web.Controllers.SenderController.GetAll'],
                    },
                ],
            },
        ],
    },
    {
        id: 'security',
        title: 'security',
        type: 'group',
        icon: 'security',
        expanded: false,
        children: [
            {
                id: 'security.users',
                title: 'Users',
                type: 'basic',
                link: '/security/users',
            },
            {
                id: 'security.roles',
                title: 'roles',
                type: 'basic',
                link: '/security/roles',
            },
            {
                id: 'security.applications',
                title: 'applications',
                type: 'basic',
                link: '/security/applications',
            },
        ],
    },
    {
        id: 'transfers',
        title: 'transfers',
        type: 'group',
        icon: 'repeat_on',
        expanded: false,
        permissionName: [
            'PuppisTransfersApplication.TransferAppService.GetAll',
            'PuppisTransfersApplication.SapTransferAppService.GetAll',
        ],
        children: [
            {
                id: 'transfers.dash',
                title: 'transfers',
                type: 'basic',
                link: '/transfers/dash',
                permissionName: ['PuppisTransfersApplication.TransferAppService.GetAll'],
            },
            {
                id: 'transfers.sap',
                title: 'sapTransfersMonitor',
                type: 'basic',
                link: '/transfers/sap',
                permissionName: ['PuppisTransfersApplication.SapTransferAppService.GetAll'],
            },
            {
                id: 'transfers.report',
                title: 'transferReportType',
                type: 'basic',
                link: '/transfers/transfer-type-list',
                permissionName: ['PuppisTransfersApplication.TransferAppService.GetAll'],
                onlyForcountry: 'CO',
            },
            {
                id: 'transfers.stock-report',
                title: 'transferStockItemsReport',
                type: 'basic',
                link: '/transfers/transfer-stock-items',
                permissionName: ['PuppisTransfersApplication.StockItemAppService.GetAll'],
            },
            {
                id: 'transfer.report',
                title: 'transferReport',
                type: 'basic',
                link: '/transfers/transfer-report',
                permissionName: ['PuppisTransfersApplication.TransferAppService.GetAll'],
            },
            {
                id: 'transfer.limit',
                title: 'transferLimit',
                type: 'basic',
                link: '/transfers/transfer-limit-list',
                permissionName: ['PuppisTransfersApplication.TransferLimitAppService.GetAll'],
            },
        ],
    },
    {
        id: 'notifications',
        title: 'notifications',
        type: 'group',
        icon: 'notifications',
        disabled: !FeatureFlagHelper.isNotificationEnable(),
        expanded: false,
        children: [
            {
                id: 'transfers.notification-types-list',
                title: 'notificationTypesList',
                type: 'basic',
                link: '/notifications/notification-types',
                permissionName: ['PuppisNotificationsApplication.NotificationsTypeAppService.GetAll'],
            },
            {
                id: 'transfers.notification-list',
                title: 'notificationList',
                type: 'basic',
                link: '/notifications',
            },
        ],
    },
    {
        id: 'logs',
        title: 'logs',
        type: 'group',
        icon: 'plagiarism',
        expanded: false,
        children: [
            {
                id: 'logs.dash',
                title: 'ServerLogs',
                type: 'basic',
                link: '/logs/cloudwatch',
            },
        ],
    },
];
