import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Inject, OnInit, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';

export abstract class BaseFormComponent<TModel> implements OnInit {
    action: FormAction;
    isLoading: boolean;
    loadedForm = false;
    loadedModel = false;
    modelId: any;
    model: TModel;

    constructor(
        @Optional() public dialogRef: MatDialogRef<TModel>,
        @Inject(MAT_DIALOG_DATA) public data: any
        ) {
        this.modelId = data.id;
        this.defineAction(data.isReadonly, this.modelId != undefined);
    }

    ngOnInit(): void {
      this.loadDto();
    }

    private loadDto() {
        if (this.action != FormAction.create) {
            this.isLoading = true;
            this.getModel()
                .pipe(finalize(() => {
                    this.isLoading = false;
                }))
                .subscribe(result => {
                    this.model = result;
                    this.loadedModel = true;
                    this.buildForm();
                });
        } else {
          this.buildForm();
        }
    }

    private defineAction(isReadonly, isModelAvailable) {
        if (isReadonly)
            this.action = FormAction.view;
        else if (isModelAvailable)
            this.action = FormAction.edit;
        else
            this.action = FormAction.create;
    }

    public isReadonly(): boolean {
        return this.action === FormAction.view;
    }

    getActionLabel() {
        switch (this.action) {
            case FormAction.view:
                return "Ver";
            case FormAction.create:
                return "Crear";
            case FormAction.edit:
                return "Editar";
            default: return "";
        }
    }

    submit() {
        var obs: Observable<any>;
        this.isLoading = true;
        switch (this.action) {
            case FormAction.create:
                obs = this.create();
                break;
            case FormAction.edit:
                obs = this.edit();
                break;
        }
        if (obs != undefined) {
          obs
          .pipe(finalize(() => this.isLoading = false))
          .subscribe(result => {
              this.close(true);
          });
        } else {
          this.isLoading = true;
        }
    }

    close(reload: boolean = false) {
        this.dialogRef.close(reload);
    }

  protected alertWithInvalidControls(form: FormGroup) {
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        controls[name].markAsTouched();
      }
    }
  }

    protected refresh() {
      this.loadDto();
    }

    protected buildForm(): void {
      this.buildFormInternal();
    }

    protected buildFormInternal(): void {}
    abstract create(): Observable<any>;
    abstract edit(): Observable<any>;
    abstract getModel(): Observable<any>;
}

export enum FormAction {
    view,
    create,
    edit
}
