import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { NotificationsService } from '../../../../../services/notifications.service';
import { NotificationDto } from '../../../../../notifications/services/service-proxy/service-proxies';
// import { BeepService } from '../../../../';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { UiService } from 'src/app/services/ui.service';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {



  constructor(private dialog: MatDialog,
              private _notificationsService: NotificationsService, 
              // tslint:disable-next-line:variable-name
              public router: Router,
              public uiService: UiService, 
              public localizationService: LocalizationService) {

              }

  get notifications$() { 
    return this._notificationsService.notifications$; 
  }

  ngOnInit(): void {

    this.notifications$
        .subscribe({
                      next: notifResponse => {

                        //this.count = (notifResponse ?  notifResponse.notificationsList.filter(y => !y.readed).length : 0);
                        //console.log('obtenidas', notifResponse);

                        if (notifResponse.notificationsList.length > 0) {

                          const lastNotificationID =  window.localStorage.getItem('last_notification_id');

                          if (lastNotificationID) {

                            const newNoti = ( notifResponse.notificationsList[0].id > Number(lastNotificationID) )  ? true : false ;
                            if (newNoti) 
                              this.playNotification();
                          }

                          window.localStorage.setItem('last_notification_id', notifResponse.notificationsList[0].id.toString());
                        }

                      },
                      error: err => console.error('get notifications error: ' + err),
                      complete: () => console.log('get notifications complete')
      });
  }

  playNotification() {

        const audio = new Audio();
        audio.src = 'assets/sounds/notification1.wav';
        audio.load();
        audio.play();
  }

  goToNotificationList() {
    this.router.navigate(['/notifications']);
  }

  readAll() {

      const msjOK = this.localizationService.localize('MarkAsReadedSuccessful', null);
      const msjEr = this.localizationService.localize('MarkAsReadedError', null);
      
      this._notificationsService
          .markAsReaded([])
          .subscribe(() => {
            this.uiService.openSnackBar(msjOK, null);
            this._notificationsService.getAllNotifications();
          },
          () => this.uiService.openSnackBar(msjEr, null));

  }



}
