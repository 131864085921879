<div class="confirmation-dialog">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <mat-dialog-content>
    <mat-list>
      <mat-list-item *ngIf="data.succeed.length > 0"
        >{{ data.succeed.length }} ordenes se procesaron correctamente.
        <button
          mat-icon-button
          [copy-clipboard]="data.succeed.join(',')"
          matTooltip="Copiar ids al portapapeles"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
      </mat-list-item>
      <mat-list-item *ngIf="data.errors.length > 0"
        >{{ data.errors.length }} ordenes no se pudieron procesar.
        <button
          mat-icon-button
          [copy-clipboard]="data.errors.join(',')"
          matTooltip="Copiar ids al portapapeles"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
      </mat-list-item>
    </mat-list>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button class="btn btn__primary" (click)="thisDialogRef.close()">
      {{ "accept" | localize }}
    </button>
  </mat-dialog-actions>
</div>
