import { timer, Subject, of, BehaviorSubject } from 'rxjs';
import { concatMap, finalize, map, retry, shareReplay, takeUntil, takeWhile, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { ConfirmationDialogComponent } from '../shared/components/confirmation-dialog/confirmation-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/material/dialog";
import * as i3 from "./ui.service";
export class ProcessService {
    constructor(httpClient, dialog, ui) {
        this.httpClient = httpClient;
        this.dialog = dialog;
        this.ui = ui;
        this.polledProcesses$ = {};
        this.cancelationToken$ = new Subject();
        this.isProcessing$ = new Subject();
        this.finishedProcessesKey = 'finished_processes';
        const finishedProcessesJson = localStorage.getItem(this.finishedProcessesKey);
        if (finishedProcessesJson) {
            this.finishedProcess$ = new BehaviorSubject(JSON.parse(finishedProcessesJson));
        }
        else {
            this.finishedProcess$ = new BehaviorSubject([]);
        }
        this.cancelationToken$.subscribe(processUrl => {
            if (processUrl) {
                delete this.polledProcesses$[processUrl];
            }
            else {
                this.polledProcesses$ = {};
            }
            this.isProcessing$.next(Object.keys(this.polledProcesses$).length > 0);
        });
    }
    getProcessByUrl(url) {
        return this.httpClient.get(url).pipe(shareReplay(1), map(this.mapResponse));
    }
    getProcess(url, interval = 5000) {
        this.isProcessing$.next(true);
        this.polledProcesses$[url] = this.httpClient.get(url).pipe(map(this.mapResponse));
        return timer(0, interval)
            .pipe(concatMap(() => this.polledProcesses$[url]), takeWhile(x => x.status !== 'Finished' &&
            x.status !== 'Error' &&
            x.status !== 'Stopped', true), takeUntil(this.cancelationToken$), retry(3), shareReplay(1), tap(process => this.addFinishedProcess(process, url)), finalize(() => this.cancelationToken$.next(url)));
    }
    getProcessState(url) {
        return this.getProcess(url).pipe(map(x => x.status));
    }
    getProcessOutput(url, interval = 5000) {
        return this.getProcess(url, interval)
            .pipe(map(x => {
            if (x.status === 'Error') {
                throw Error(x.output);
            }
            return x;
        })).pipe(map(x => x.output));
    }
    getProcessError(url) {
        return this.getProcess(url).pipe(map(x => x.output));
    }
    cancelIfProcessExists() {
        if (Object.keys(this.polledProcesses$).length > 0) {
            return this.dialog.open(ConfirmationDialogComponent, {
                data: {
                    title: 'Existe un proceso en ejecución. ¿Desea cancelarlo?',
                    btnPrimaryText: 'Si',
                    btnSecondaryText: 'No'
                }
            }).afterClosed().pipe(map(result => {
                if (result === 'Confirm') {
                    this.cancelationToken$.next();
                    return true;
                }
                return false;
            }));
        }
        return of(true);
    }
    IfProcessExists() {
        if (Object.keys(this.polledProcesses$).length > 0) {
            return this.dialog.open(ConfirmationDialogComponent, {
                data: {
                    title: 'Ya existe un proceso en ejecución.',
                    btnPrimaryText: 'Ok'
                }
            }).afterClosed().pipe(map(result => {
                return false;
            }));
        }
        return of(true);
    }
    addFinishedProcess(process, url) {
        if (moment().diff(process.creationTime, 'minutes') > 30) {
            process.status = 'Stopped';
            process.output = 'Process timedout.';
            process.icon = 'highlight_off';
            this.cancelationToken$.next(url);
        }
        if (process.status !== 'Pending' && process.status !== 'Running') {
            const finishedProcessesJson = localStorage.getItem(this.finishedProcessesKey);
            let finishedProcesses = [];
            if (finishedProcessesJson) {
                finishedProcesses = JSON.parse(finishedProcessesJson);
            }
            process.id = this.getMaxOfArray(finishedProcesses.map(x => x.id)) + 1;
            finishedProcesses.push(process);
            localStorage.setItem(this.finishedProcessesKey, JSON.stringify(finishedProcesses));
            this.finishedProcess$.next(finishedProcesses);
        }
    }
    removeFinishedProcess(id) {
        const finishedProcessesJson = localStorage.getItem(this.finishedProcessesKey);
        let finishedProcesses = [];
        if (finishedProcessesJson) {
            finishedProcesses = JSON.parse(finishedProcessesJson);
        }
        const processToRemoveIndex = finishedProcesses.findIndex(x => x.id === id);
        if (processToRemoveIndex >= 0) {
            finishedProcesses.splice(processToRemoveIndex, 1);
            localStorage.setItem(this.finishedProcessesKey, JSON.stringify(finishedProcesses));
            this.finishedProcess$.next(finishedProcesses);
        }
    }
    clearFinishedProcesses() {
        localStorage.removeItem(this.finishedProcessesKey);
        this.finishedProcess$.next([]);
    }
    mapResponse(response) {
        const result = response.result;
        switch (result.status) {
            case 'Finished':
                result.icon = 'done';
                break;
            case 'Running':
                result.icon = 'settings';
                break;
            case 'Error':
                result.icon = 'cancel';
                break;
            case 'Stopped':
                result.icon = 'highlight_off';
                break;
            default:
                result.icon = 'schedule';
                break;
        }
        return result;
    }
    getMaxOfArray(numArray) {
        return Math.max.apply(null, numArray);
    }
}
ProcessService.refreshList = false;
ProcessService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProcessService_Factory() { return new ProcessService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.MatDialog), i0.ɵɵinject(i3.UiService)); }, token: ProcessService, providedIn: "root" });
export class ProcessDto {
}
