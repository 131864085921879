import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
  })
export class TransferOmsService {

    private baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = environment.omsUrl;
    }

    getTransferOrderInfo(id: number): Observable<TransferOrderInfoDto> {

        const queryUrl = `${this.baseUrl}api/services/app/Transfer/GetTransferOrderInfo?id=${id}`;

        return this.http
            .get<any>(queryUrl)
            .pipe(map(x => x.result ? x.result : null));
    }
}


export class TransferOrderInfoDto {
    trxSrcCode: string | undefined;
    sourceStoreCode: string | undefined;
    destinationStoreCode: string | undefined;
    deliveryDate: moment.Moment | undefined;
    transferItemsSkus: string[] | undefined;
    orderCompleteAdress: string | undefined;
    orderShippingType: string | undefined;
}