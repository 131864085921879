import { Component, EventEmitter, Inject, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ColumnDto } from '../../../supplying/services/service-proxy/service-proxies';

@Component({
  selector: 'app-table-filter-columns',
  templateUrl: './table-filter-columns.component.html',
  styleUrls: ['./table-filter-columns.component.scss']
})
export class TableFilterColumnsComponent {

  columns: ColumnDto[] = [];
  callback: (newColumns: string) => void;

  constructor(
    @Optional() public dialogRef: MatDialogRef<TableFilterColumnsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.columns = data.columns;
      this.callback = data.callback;

      console.log('data', data)
  }

  hasColumns(): boolean {
    return this.columns.length > 0;
  }

  onSelectionChange(list) {
    const selectedList = list.selectedOptions.selected.map(item => item.value);
    this.columns.forEach(column => {
      const isSelected = selectedList.includes(column);
      isSelected ? column.visible = true : column.visible = false;
    });

    this.dialogRef.close(this.columns);
  }

  onSaveChange(list) {
    const selectedList = list.selectedOptions.selected.map(item => item.value);
    this.columns.forEach(column => {
      const isSelected = selectedList.includes(column);
      isSelected ? column.visible = true : column.visible = false;
    });

    let excludeColumnNames = this.columns.filter(x=> !x.visible).map(columna => columna.name);
    let excludeColumnNamesString = excludeColumnNames.join(';');

    if (this.callback) 
      this.callback(excludeColumnNamesString);
    
    this.dialogRef.close(this.columns);
  }

  onNoClick(): void {
    this.dialogRef.close(this.columns);
  }
}
