import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-quadminds-result-dialog',
    templateUrl: './quadminds-result.component.html',
    styleUrls: []
})
export class QuadmindsResultComponent implements OnInit {
    title: string;

    constructor(
        public thisDialogRef: MatDialogRef<QuadmindsResultComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
    }
}
