import { HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./common.service";
export class UserService {
    constructor(http, commonService) {
        this.http = http;
        this.commonService = commonService;
        this._users = [];
        this.COUNTRY_KEY = 'country';
        this.baseUrl = environment.securityUrl;
    }
    getUserProfileFromLocal() {
        let user = JSON.parse(localStorage.getItem('user'));
        return user;
    }
    getUserList(searchData) {
        return this.http.get(`${this.baseUrl}/users`, {
            params: searchData
        });
    }
    createUser(user) {
        const userDto = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            pictureUrl: user.pictureUrl,
            isAdmin: user.isAdmin,
            storeCode: user.storeCode,
            countryCode: user.countryCode,
            password: user.password,
            rolesIds: user.rolesId ? user.rolesId : []
        };
        return this.http.post(`${this.baseUrl}/user/create`, userDto);
    }
    updateUser(user) {
        const userDto = {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            pictureUrl: user.pictureUrl,
            isAdmin: user.isAdmin,
            storeCode: user.storeCode,
            countryCode: user.countryCode,
            password: user.password,
            rolesId: user.rolesId ? user.rolesId : []
        };
        return this.http.post(`${this.baseUrl}/user/update/${user.id}`, userDto);
    }
    changeUserStatus(user) {
        return this.http.post(`${this.baseUrl}/user/setDisabled/${user.id}`, user.isDisabled, {
            headers: {
                content: 'application/json',
                'content-type': 'application/json'
            }
        });
    }
    /** user set the password from register page */
    resetPassword(id, password, passwordConfirmed) {
        const passwordDto = {
            password,
            passwordConfirmed
        };
        return this.http.post(`${this.baseUrl}/user/resetPassword/${id}`, passwordDto);
    }
    /** Admin clicks on reset user password */
    setResetPassword(id, email) {
        let params = new HttpParams();
        if (id) {
            params = params.append('id', id);
        }
        if (email) {
            params = params.append('email', email);
        }
        return this.http.get(`${this.baseUrl}/user/setResetPassword`, { params });
    }
    /** usuario enter from registration page */
    getUserToRegister(token) {
        return this.http.get(`${this.baseUrl}/user/confirmRegister/${token}`);
    }
    getUserToResetPassword(token) {
        return this.http.get(`${this.baseUrl}/user/confirmResetPassword/${token}`);
    }
    load() {
        return this.http
            .get(`${this.baseUrl}/Users/GetCatalog`)
            .pipe(catchError((e) => of([])))
            .pipe(tap((users) => {
            this._users = users;
        }));
    }
    getUsersCatalog() {
        return this._users;
    }
    getUsersCataloByCountry(country) {
        return this._users.filter(u => u.countryCode === country || !u.countryCode);
    }
    getStoreUsersCatalog(storeCode) {
        return this.getUsersCatalog().filter(x => x.storeCode == storeCode);
    }
    getUserName(id) {
        var user = this.getUsersCatalog().find(x => x.id == id);
        if (user)
            return user.firstName + " " + user.lastName;
        else
            return id;
    }
    isNotUserWithFixedStore() {
        const user = this.getUserProfileFromLocal().id;
        return !this.getUsersCatalog().find(x => x.id == user).storeCode;
    }
    getCsv(searchData) {
        return this.http.get(`${this.baseUrl}/users/getcsv`, {
            params: searchData
        });
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CommonService)); }, token: UserService, providedIn: "root" });
