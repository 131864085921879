import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormGroupDirective,
  NgForm
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../shared/models/user';
import {
  ErrorStateMatcher,
  MatDialogConfig,
  MatDialog
} from '@angular/material';
import { PasswordStrengthValidator } from '../shared/validators/password-strength.validators';
import { ConfirmationDialogComponent } from '../shared/components/confirmation-dialog/confirmation-dialog.component';
import { UserService } from '../services/user.service';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return control.dirty && form.invalid;
  }
}

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  registerForm: FormGroup;
  isSubmitted: boolean = false;
  user: User;
  passwordStrengthText: string = 'Débil';
  userCode: string;
  errorMessage: string;
  action: string;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router
  ) {
    this.userCode = this.route.snapshot.paramMap.get('userCode');
    this.action = this.route.snapshot.paramMap.get('action');
    this.initForm();
  }

  initForm() {
    this.registerForm = this.formBuilder.group(
      {
        password1: ['', [Validators.required, PasswordStrengthValidator]],
        password2: ['', Validators.required]
      },
      {
        validator: this.passwordValidator
      }
    );
  }

  get formControls() {
    return this.registerForm.controls;
  }

  ngOnInit() {
    if (this.action === 'newuser') {
      this.userService.getUserToRegister(this.userCode).subscribe(
        (response: any) => {
          this.user = response.data;
        },
        (error: any) => {
          this.errorMessage = 'Usuario no encontrado';
        }
      );
    }
    if (this.action === 'resetpassword') {
      this.userService.getUserToResetPassword(this.userCode).subscribe(
        (response: any) => {
          this.user = response.data;
        },
        (error: any) => {
          this.errorMessage = 'Usuario no encontrado';
        }
      );
    }
    if (this.action === 'emailconfirm') {
      this.userService.getUserToRegister(this.userCode).subscribe(
        (response: any) => {
          this.router.navigate(['/login']);
        },
        (error: any) => {
          alert('Usuario no encontrado');
          this.router.navigate(['/login']);
        }
      );
    }
  }

  passwordValidator(form: FormGroup) {
    const condition =
      form.get('password1').value !== form.get('password2').value;
    return condition ? { passwordsDoNotMatch: true } : null;
  }

  onStrengthChanged(strength: number) {
    if (strength === 100) {
      this.passwordStrengthText = 'Segura';
    } else if (strength >= 40 && strength < 100) {
      this.passwordStrengthText = 'Moderada';
    } else {
      this.passwordStrengthText = 'Débil';
    }
  }

  getDialogConfig() {
    const disableUserdialogConfig = new MatDialogConfig();
    disableUserdialogConfig.disableClose = false;
    disableUserdialogConfig.autoFocus = false;
    disableUserdialogConfig.data = {
      title: '¡Su contraseña ha sido creada con éxito!',
      text: 'Debe ingresar sus datos para poder iniciar sesión',
      btnPrimaryText: 'Aceptar',
      hideCancelButton: true
    };
    return disableUserdialogConfig;
  }

  setPassword() {
    this.isSubmitted = true;
    if (!this.registerForm.invalid) {

      this.userService
        .resetPassword(this.user.id,
          this.registerForm.controls['password1'].value,
          this.registerForm.controls['password2'].value)
        .subscribe(
          (response: any) => {
            if (response.status) {
              this.openConfirmationDialog();
            }
          },
          error => {
            this.errorMessage = "Surgió un error al establecer la contraseña."
            alert(this.errorMessage);
          }
        );
    }
  }

  openConfirmationDialog() {
    const dialogConfig = this.getDialogConfig();
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(responseDialog => {
      if (responseDialog === 'Confirm') {
        this.router.navigateByUrl('/login');
      }
    });
  }
}
