import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

export class FeatureFlagHelper {
  public static isPickupEnable(): boolean {
    return true;
  }

  public static isSalesEnable(): boolean {
    return true;
  }

  public static isPrepartionPickingEnable(): boolean {
    return environment.env != 'prod';
  }

  public static isFlyersEnable(): boolean {
    return environment.env != 'prod';
  }

  public static isNotificationEnable(): boolean {
    return true;
  }

  public static isManualTransferEnabled(): boolean {
    return environment.env != 'prod';
  }

  public static isWmsEnable(): boolean {
    return true;
  }

  public static isStoreDipatchEnable(): boolean {
    return true;
  }

  public static isRapiboyEnabled(): boolean {
    return environment.env != 'prod';
  }

  public static isComerssiaIntegrationEnabled(): boolean {
    return true;
  }

  public static isOrderStorePickupColEnabled(): boolean {
    return true;
  }

  public static allowedToReasignOrders(): boolean {
    return true;
  }

  public static isGeneralAuditDisabled(): boolean {
    return false;
  }

  public static isManualOrderArgentina(): boolean {
    return environment.env === 'prod';
  }

  public static isInvoiceMonitorDisabled(): boolean {
    const commonService = new CommonService(null);
    if (commonService.getCountryCode() === 'CO') {
      return environment.env === 'prod';
    }

    return false;
  }

  public static isPolygonDisabled(): boolean {
    return environment.env === 'prod';
  }

}
