import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { NotificationDto, NotificationResponse, NotificationServiceProxy } from '../notifications/services/service-proxy/service-proxies';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { interval, Subscription, Observable, of, timer } from 'rxjs';
import { concatMap, map, shareReplay } from 'rxjs/operators';
import { concat } from 'lodash';

@Injectable({
    providedIn: 'root'
})

export class NotificationsService {

  public notifications$: Observable<NotificationResponse>;
  private proxy: NotificationServiceProxy;
  private url = `${environment.notificationsUrl}/api/services/app/Notification/getAllByCurrentUser?channel=APP`;

  constructor(private http: HttpClient) {
    this.getAllNotifications()
  }

  getAllNotifications() {
    this.proxy = new NotificationServiceProxy(this.http, environment.notificationsUrl);
    this.setTimer();
  }

  setTimer(){

    const observ$ = this.http.get<any>(this.url)
                             .pipe(map(x => x.result));

    this.notifications$ = timer(0,60000).pipe(
      concatMap(_ => 
        observ$
        ),
      shareReplay(1)
    );
  }


  markAsReaded(notificationIds: number[]): Observable<void> {

    return this.proxy.markNotificationAsReaded(notificationIds);
  }


}
