import { Component, Input } from '@angular/core';
import { NotificationDto } from '../../../../../../notifications/services/service-proxy/service-proxies';
import { NotificationsService } from '../../../../../../services/notifications.service';
import { UiService } from '../../../../../../services/ui.service';
import { LocalizationService } from '../../../../../../services/localization.service';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent {

  @Input() notification: NotificationDto;

  constructor(private _notificationsService: NotificationsService, 
              public uiService: UiService, 
              public localizationService: LocalizationService) { }

  markAsReaded() {
    if(!this.notification.readed) {

      const msjOK = this.localizationService.localize('MarkAsReadedSuccessful', null);
      const msjEr = this.localizationService.localize('MarkAsReadedError', null);
      
      this._notificationsService
          .markAsReaded([this.notification.id])
          .subscribe(
                      () => { 
                        this.uiService.openSnackBar(msjOK, null); 
                        this._notificationsService.getAllNotifications();
                      },
                      () => this.uiService.openSnackBar(msjEr, null))
    }
    if(this.notification.actionLink)
      window.open(this.notification.actionLink, "_blank");
  }

}
