<mat-toolbar color="primary" class="puppis-toolbar">
  <div class="menu-toggle" fxLayout="row" fxLayoutAlign="center center">
    <button mat-icon-button (click)="isExpanded = !isExpanded">
      <mat-icon class="menu__hamburguer-icon">menu</mat-icon>
    </button>
  </div>

  <div
    class="logo-container"
    fxLayout="row"
    fxLayoutAlign="center center"
    (click)="redirectToHome()"
  >
    <mat-icon
      [inline]="true"
      svgIcon="icon-puppis"
      aria-label="Puppis"
      class="logo-image"
      >Puppis</mat-icon
    >
    <span class="logo-text ocultarEnMobile">Archie v2.0</span>
  </div>
  <span fxFlex></span>
  <div class="menu__country-selector">
    <app-country></app-country>
  </div>
  <mat-divider class="ocultarEnMobile" [vertical]="true"></mat-divider>
  <div class="menu-content">
    <app-user-profile></app-user-profile>
  </div>
  <mat-divider class="ocultarEnMobile" [vertical]="true"></mat-divider>
  <div class="process-icon">
    <app-processes></app-processes>
  </div>
  <div *ngIf="isNotificationsEnable()" class="notification-icon">
    <app-notifications></app-notifications>
  </div>
</mat-toolbar>

<app-side-nav [child]="isExpanded"></app-side-nav>
