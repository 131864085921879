import { Injectable } from '@angular/core';
import { timer, Observable, Subject, of, BehaviorSubject } from 'rxjs';
import { concatMap, finalize, map, retry, shareReplay, takeUntil, takeWhile, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { UiService } from './ui.service';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../shared/components/confirmation-dialog/confirmation-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class ProcessService {
    public static refreshList: boolean = false;
    public polledProcesses$: { [url: string]: Observable<ProcessDto>; } = {};
    public cancelationToken$: Subject<string> = new Subject();
    public isProcessing$: Subject<boolean> = new Subject();
    public finishedProcess$: BehaviorSubject<ProcessDto[]>;
    private readonly finishedProcessesKey: string = 'finished_processes';

    constructor(
        private httpClient: HttpClient,
        private dialog: MatDialog,
        private ui: UiService) {
        const finishedProcessesJson = localStorage.getItem(this.finishedProcessesKey);

        if (finishedProcessesJson) {
            this.finishedProcess$ = new BehaviorSubject(JSON.parse(finishedProcessesJson));
        } else {
            this.finishedProcess$ = new BehaviorSubject([]);
        }

        this.cancelationToken$.subscribe(processUrl => {
            if (processUrl) {
                delete this.polledProcesses$[processUrl];
            } else {
                this.polledProcesses$ = {};
            }

            this.isProcessing$.next(Object.keys(this.polledProcesses$).length > 0);
        });
    }

    public getProcessByUrl(url: string): Observable<ProcessDto> {
        return this.httpClient.get<any>(url).pipe(shareReplay(1), map(this.mapResponse));
    }

    public getProcess(url: string, interval: number = 5000): Observable<ProcessDto> {
        this.isProcessing$.next(true);
        this.polledProcesses$[url] = this.httpClient.get<any>(url).pipe(map(this.mapResponse));

        return timer(0, interval)
            .pipe(
                concatMap(() => this.polledProcesses$[url]),
                takeWhile(x =>
                    x.status !== 'Finished' &&
                    x.status !== 'Error' &&
                    x.status !== 'Stopped', true),
                takeUntil(this.cancelationToken$),
                retry(3),
                shareReplay(1),
                tap(process => this.addFinishedProcess(process, url)),
                finalize(() => this.cancelationToken$.next(url))
            );
    }

    public getProcessState(url: string): Observable<string> {
        return this.getProcess(url).pipe(map(x => x.status));
    }

    public getProcessOutput(url: string, interval: number = 5000): Observable<string> {
        return this.getProcess(url, interval)
            .pipe(map(x => {
                if (x.status === 'Error') {
                    throw Error(x.output);
                }

                return x;
            })).pipe(map(x => x.output));
    }

    public getProcessError(url: string): Observable<string> {
        return this.getProcess(url).pipe(map(x => x.output));
    }

    public cancelIfProcessExists(): Observable<boolean> {
        if (Object.keys(this.polledProcesses$).length > 0) {
            return this.dialog.open(ConfirmationDialogComponent, {
                data: {
                    title: 'Existe un proceso en ejecución. ¿Desea cancelarlo?',
                    btnPrimaryText: 'Si',
                    btnSecondaryText: 'No'
                }
            }).afterClosed().pipe(map(result => {
                if (result === 'Confirm') {
                    this.cancelationToken$.next();
                    return true;
                }

                return false;
            }));
        }
        return of(true);
    }

    public IfProcessExists(): Observable<boolean> {
        if (Object.keys(this.polledProcesses$).length > 0) {
            return this.dialog.open(ConfirmationDialogComponent, {
                data: {
                    title: 'Ya existe un proceso en ejecución.',
                    btnPrimaryText: 'Ok'
                }
            }).afterClosed().pipe(map(result => {
                return false;
            }));
        }
        return of(true);
    }

    public addFinishedProcess(process: ProcessDto, url: string): void {
        if (moment().diff(process.creationTime, 'minutes') > 30) {
            process.status = 'Stopped';
            process.output = 'Process timedout.';
            process.icon = 'highlight_off';
            this.cancelationToken$.next(url);
        }

        if (process.status !== 'Pending' && process.status !== 'Running') {
            const finishedProcessesJson = localStorage.getItem(this.finishedProcessesKey);
            let finishedProcesses: ProcessDto[] = [];

            if (finishedProcessesJson) {
                finishedProcesses = JSON.parse(finishedProcessesJson);
            }

            process.id = this.getMaxOfArray(finishedProcesses.map(x => x.id)) + 1;
            finishedProcesses.push(process);
            localStorage.setItem(this.finishedProcessesKey, JSON.stringify(finishedProcesses));
            this.finishedProcess$.next(finishedProcesses);
        }
    }

    public removeFinishedProcess(id: number): void {
        const finishedProcessesJson = localStorage.getItem(this.finishedProcessesKey);
        let finishedProcesses: ProcessDto[] = [];

        if (finishedProcessesJson) {
            finishedProcesses = JSON.parse(finishedProcessesJson);
        }

        const processToRemoveIndex = finishedProcesses.findIndex(x => x.id === id);

        if (processToRemoveIndex >= 0) {
            finishedProcesses.splice(processToRemoveIndex, 1);
            localStorage.setItem(this.finishedProcessesKey, JSON.stringify(finishedProcesses));
            this.finishedProcess$.next(finishedProcesses);
        }
    }

    public clearFinishedProcesses(): void {
        localStorage.removeItem(this.finishedProcessesKey);
        this.finishedProcess$.next([]);
    }

    private mapResponse(response: any): ProcessDto {
        const result = response.result as ProcessDto;

        switch (result.status) {
            case 'Finished':
                result.icon = 'done';
                break;
            case 'Running':
                result.icon = 'settings';
                break;
            case 'Error':
                result.icon = 'cancel';
                break;
            case 'Stopped':
                result.icon = 'highlight_off';
                break;
            default:
                result.icon = 'schedule';
                break;
        }

        return result;
    }

    private getMaxOfArray(numArray) {
        return Math.max.apply(null, numArray);
    }
}

export class ProcessDto {
    id: number | undefined;
    name: string | undefined;
    status: string | undefined;
    creationTime: moment.Moment;
    steps: any[];
    currentStatus: any;
    output: string | undefined;
    icon?: string | undefined;
}
