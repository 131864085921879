import { Injector } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HelperService } from '../oms/services/helper.service';
import { UserService } from './user.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./common.service";
import * as i3 from "./localization.service";
export class StoreService {
    constructor(httpClient, commonService, localizationService, injector) {
        this.httpClient = httpClient;
        this.commonService = commonService;
        this.localizationService = localizationService;
        this.BASE_URL = environment.storesUrl;
        this._stores = [];
        this.STORE_KEY = 'CURRENT_STORE';
        this.COUNTRY_KEY = 'country';
        this.userService = injector.get(UserService);
    }
    setCurrentStore(storeCode) {
        HelperService.storeOnLocalStorage(this.STORE_KEY, storeCode);
    }
    getStore(storeCode = null) {
        if (storeCode == null) {
            storeCode = this.getCurrentStore();
        }
        return this._stores.find((x) => x.storeCode === storeCode);
    }
    currentStoreIsWarehouse() {
        let current = this.getStore(this.getCurrentStore());
        if (!current)
            return false;
        return current.isWarehouse;
    }
    getStores(countryCode = null) {
        let stores = [];
        const currentCountry = countryCode || this.commonService.getCookie(this.COUNTRY_KEY);
        if (currentCountry) {
            stores = this._stores.filter((x) => x.countryCode === currentCountry);
        }
        else {
            stores = this._stores;
        }
        stores = [
            {
                name: this.localizationService.localize('allStores'),
                id: '',
                isHub: false,
            },
            ...stores.map((x) => ({
                id: x.storeCode,
                name: x.name,
                isHub: x.isHub,
                addressState: x.addressState,
            })),
        ];
        return stores;
    }
    getStoresAvailablesForSales(countryCode = null) {
        let stores = [];
        const currentCountry = countryCode || this.commonService.getCookie(this.COUNTRY_KEY);
        if (currentCountry) {
            stores = this._stores.filter((x) => x.countryCode === currentCountry && x.availableForSales);
        }
        else {
            stores = this._stores.filter((x) => x.availableForSales);
        }
        stores = [
            ...stores.map((x) => ({
                id: x.storeCode,
                name: x.name,
                isHub: x.isHub,
                addressState: x.addressState,
            })),
        ];
        return stores;
    }
    getStoresByCity(city) {
        return this._stores.filter((x) => x.addressState === city).map((x) => x.storeCode);
    }
    getCurrentStore() {
        let storeCode = HelperService.getValueFromLocalStorage(this.STORE_KEY);
        if (this.userService) {
            const user = this.userService.getUserProfileFromLocal();
            storeCode = user.storeCode || storeCode || '';
        }
        return storeCode;
    }
    isStoreSelected() {
        return this.getCurrentStore() ? this.getCurrentStore() !== '' : false;
    }
    getStoreName(code) {
        const store = this.getStores().find((x) => x.id === code);
        if (store) {
            return store.name;
        }
        else {
            return '';
        }
    }
    isStoreHub(code) {
        const store = this.getStores().find((x) => x.id === code);
        return store ? store.isHub : false;
    }
    getStoreNameByErpCode(code) {
        let stores = [];
        const currentCountry = this.commonService.getCookie(this.COUNTRY_KEY);
        if (currentCountry) {
            stores = this._stores.filter((x) => x.countryCode === currentCountry);
        }
        else {
            stores = this._stores;
        }
        return (stores.find((x) => x.erpCode == code) || {}).name || code;
    }
    getStoreErpCode(code) {
        const store = this._stores.find((x) => x.storeCode === code);
        return store ? store.erpCode : code;
    }
    getStoreEmail(code) {
        var _a;
        return (_a = this._stores.find((x) => x.storeCode === code)) === null || _a === void 0 ? void 0 : _a.email;
    }
    load() {
        console.log('load stores ...');
        return this.httpClient
            .get(`${this.BASE_URL}/api/services/app/Store/GetAllCountries?MaxResultCount=1000`)
            .pipe(catchError((e) => of([])))
            .pipe(map((r) => (r.result ? r.result.items : [])))
            .pipe(tap((stores) => {
            this._stores = stores.sort((n1, n2) => {
                if (n1.name > n2.name) {
                    return 1;
                }
                if (n1.name < n2.name) {
                    return -1;
                }
                return 0;
            });
        }));
    }
}
StoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StoreService_Factory() { return new StoreService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CommonService), i0.ɵɵinject(i3.LocalizationService), i0.ɵɵinject(i0.INJECTOR)); }, token: StoreService, providedIn: "root" });
