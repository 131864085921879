import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CommonService } from 'src/app/services/common.service';
import { PermissionService } from 'src/app/services/permission.service';
import { NavigationItem, navigationItems } from 'src/app/shared/models/navigation-item.model';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnChanges {

  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

  isExpanded = false;
  isShowing = false;
  showSubmenu: boolean = false;
  showSubSubMenu: boolean = false;
  user: any;

  isMobile = false;
  mode = 'side';
  countryCode = null;

  @Input() child: boolean;

  constructor(private _authenticationService: AuthenticationService, 
              private _permissionService: PermissionService,
              private commonService: CommonService) {


    // const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
    // is mobile
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {

      this.isMobile = true;
      this.mode = 'over';
      this.isShowing = true;
   }

  }

  get navigationItems(): NavigationItem[] { return navigationItems; }

  ngOnInit(): void {
    this._authenticationService.userProfile$.subscribe(profile => this.user = profile);

    this.countryCode = this.commonService.getCookie('country');
  }

  mouseenter() {

    if (!this.isMobile && !this.isExpanded) { this.isShowing = true; }
  }

  mouseleave() {
    if (!this.isMobile && !this.isExpanded) { this.isShowing = false; }
  }

  showItem(item: NavigationItem): boolean {
    const showForThisCountry = (!item.onlyForcountry) ? true : this.countryCode == item.onlyForcountry;
    return this._permissionService.checkPermissions(item.permissionName || [], "OR") && item.disabled != true && showForThisCountry;
  }

  ngOnChanges() {
    this.sidenav.toggle();
  }

}
