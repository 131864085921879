import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User, UserDto } from '../shared/models/user';
import { UserList } from '../shared/models/userList';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl: string;
  private _users: UserDto[] = [];
  private COUNTRY_KEY = 'country';

  constructor(private http: HttpClient,
    private commonService: CommonService) {
    this.baseUrl = environment.securityUrl;
  }

  getUserProfileFromLocal(): User {
    let user: User = JSON.parse(localStorage.getItem('user'));
    return user;
  }

  getUserList(searchData: any): Observable<UserList> {
    return this.http.get<UserList>(`${this.baseUrl}/users`, {
      params: searchData
    });
  }

  createUser(user: any) {
    const userDto = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      pictureUrl: user.pictureUrl,
      isAdmin: user.isAdmin,
      storeCode: user.storeCode,
      countryCode: user.countryCode,
      password: user.password,
      rolesIds: user.rolesId ? user.rolesId : []
    };
    return this.http.post(`${this.baseUrl}/user/create`, userDto);
  }

  updateUser(user: any) {
    const userDto = {
      userId: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      pictureUrl: user.pictureUrl,
      isAdmin: user.isAdmin,
      storeCode: user.storeCode,
      countryCode: user.countryCode,
      password: user.password,
      rolesId: user.rolesId ? user.rolesId : []
    };
    return this.http.post(
      `${this.baseUrl}/user/update/${user.id}`,
      userDto
    );
  }

  changeUserStatus(user: User) {
    return this.http.post(
      `${this.baseUrl}/user/setDisabled/${user.id}`,
      user.isDisabled,
      {
        headers: {
          content: 'application/json',
          'content-type': 'application/json'
        }
      }
    );
  }

  /** user set the password from register page */
  resetPassword(id: string, password: string, passwordConfirmed: string) {
    const passwordDto = {
      password,
      passwordConfirmed
    };
    return this.http.post(`${this.baseUrl}/user/resetPassword/${id}`, passwordDto);
  }

  /** Admin clicks on reset user password */
  setResetPassword(id?: string | undefined, email?: string | undefined) {
    let params = new HttpParams();
    if (id) {
      params = params.append('id', id);
    }

    if (email) {
      params = params.append('email', email);
    }

    return this.http.get(`${this.baseUrl}/user/setResetPassword`, { params });
  }

  /** usuario enter from registration page */
  getUserToRegister(token: string) {
    return this.http.get(`${this.baseUrl}/user/confirmRegister/${token}`);
  }

  getUserToResetPassword(token: string) {
    return this.http.get(`${this.baseUrl}/user/confirmResetPassword/${token}`);
  }

  load(): Observable<UserDto[]> {
    return this.http
      .get<any>(`${this.baseUrl}/Users/GetCatalog`)
      .pipe(catchError((e) => of([])))
      .pipe(tap((users: UserDto[]) => {
        this._users = users}));
  }

  getUsersCatalog(): UserDto[]{
    return this._users;
  }

  getUsersCataloByCountry(country : string): UserDto[] 
  {
    return this._users.filter(u => u.countryCode === country || !u.countryCode);
  }

  getStoreUsersCatalog(storeCode: string){
    return this.getUsersCatalog().filter(x => x.storeCode == storeCode);
  }

  getUserName(id){
    var user = this.getUsersCatalog().find(x => x.id == id);
    if(user)
      return user.firstName + " " + user.lastName;
    else
      return id;
  }

  isNotUserWithFixedStore(): boolean {
    const user = this.getUserProfileFromLocal().id;
    
    return !this.getUsersCatalog().find(x => x.id == user).storeCode;
  }

  getCsv(searchData: any): Observable<string> {

    return this.http.get<string>(`${this.baseUrl}/users/getcsv`, {
      params: searchData
    });
  }
}
