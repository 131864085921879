import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import * as moment from 'moment';
import { HelperService } from '../oms/services/helper.service';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CommonService {
    // documentTypeList$ = new BehaviorSubject(null); 
    constructor(http) {
        this.http = http;
        this.COUNTRY_KEY = 'country';
        this.selectedCountry$ = new Subject();
        this.countryList$ = new BehaviorSubject(null);
        this.countryListCities$ = new BehaviorSubject(null);
        this.clientUrl = environment.clientUrl;
    }
    getCountryCode() {
        return this.getCookie(this.COUNTRY_KEY);
    }
    setSelectedCountry(country) {
        HelperService.storeOnLocalStorage('CURRENT_STORE', '');
        this.selectedCountry$.next(country);
    }
    getCountryList() {
        return this.http.get(`${this.clientUrl}/country`).pipe(tap((countries) => this.countryList$.next(countries)));
    }
    getCountryById(id) {
        return this.http.get(`${this.clientUrl}/country/${id}`);
    }
    getStatesByCountryId(id) {
        return this.http.get(`${this.clientUrl}/State/statesByCountry/${id}`);
    }
    getStatesById(id) {
        return this.http.get(`${this.clientUrl}/State/${id}`);
    }
    getCityByStateId(id) {
        return this.http.get(`${this.clientUrl}/City/citiesByState/${id}`);
    }
    getCityById(id) {
        return this.http.get(`${this.clientUrl}/City/${id}`);
    }
    getGenderList() {
        return this.http.get(`${this.clientUrl}/Client/genders`);
    }
    //"{Codes}/Cities"
    getCountriesWithStatesAndCities(countryCodes) {
        return this.http.post(`${this.clientUrl}/Country/Cities`, countryCodes).pipe(tap((countries) => this.countryListCities$.next(countries)));
    }
    getCountriesFromLocalStorage() {
        const countryStatesAndCity = JSON.parse(localStorage.getItem('CountriesStatesCities'));
        return countryStatesAndCity;
    }
    getFilteredCountryByCityIdFromLocal(cityId) {
        const countryStatesAndCity = JSON.parse(localStorage.getItem('CountriesStatesCities'));
        return countryStatesAndCity.filter(country => country.states.some(state => state.cities.some(city => city.id === cityId)));
    }
    getDifferenceInDays(biggerDate, smallerDate) {
        return Math.round(moment.duration(moment(biggerDate).diff(moment(smallerDate))).asDays());
    }
    getAllCountryListFromLocal() {
        return JSON.parse(localStorage.getItem('allCountryList'));
    }
    getDocumentTypeListFromLocal() {
        return JSON.parse(localStorage.getItem('documentTypeList'));
    }
    getGenderListFromLocal() {
        return JSON.parse(localStorage.getItem('genderList'));
    }
    getAddressTypeListFromLocal() {
        return JSON.parse(localStorage.getItem('addressTypeList'));
    }
    setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = 'expires=' + d.toUTCString();
        var cookie = cname + '=' + cvalue + ';' + expires + ';SameSite=Lax;path=/';
        document.cookie = cookie;
    }
    getCookie(cname) {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
    clearCookie(cname) {
        const cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = cname || name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    }
    deleteAllCookies() {
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }
}
CommonService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommonService_Factory() { return new CommonService(i0.ɵɵinject(i1.HttpClient)); }, token: CommonService, providedIn: "root" });
