/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-profile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/card";
import * as i3 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "@angular/material/menu";
import * as i10 from "@angular/cdk/overlay";
import * as i11 from "@angular/cdk/bidi";
import * as i12 from "@angular/common";
import * as i13 from "../../../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i14 from "./user-profile.component";
import * as i15 from "../../../../../services/authentication.service";
import * as i16 from "@angular/material/dialog";
var styles_UserProfileComponent = [i0.styles];
var RenderType_UserProfileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserProfileComponent, data: {} });
export { RenderType_UserProfileComponent as RenderType_UserProfileComponent };
function View_UserProfileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "img", [["alt", "usuario"], ["class", "user-picture mat-card-avatar"], ["mat-card-avatar", ""]], [[8, "src", 4]], null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.MatCardAvatar, [], null, null)], null, function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.pictureUrl; _ck(_v, 1, 0, currVal_0); }); }
function View_UserProfileComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "profile__icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["account_circle"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_UserProfileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 9, "button", [["aria-haspopup", "true"], ["class", "user-options"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-expanded", 0]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(2, 1196032, null, 0, i9.MatMenuTrigger, [i10.Overlay, i1.ElementRef, i1.ViewContainerRef, i9.MAT_MENU_SCROLL_STRATEGY, [2, i9.MatMenu], [8, null], [2, i11.Directionality], i7.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "span", [["class", "ocultarEnMobile"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Hola, "])), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "span", [["class", "menu__user-name ocultarEnMobile"], ["style", "margin-right:8px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", " ", ""])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_UserProfileComponent_2)), i1.ɵdid(8, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noPictureTemplate", 2]], 0, 0, null, View_UserProfileComponent_3)), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_3 = i1.ɵnov(_v.parent, 4); _ck(_v, 2, 0, currVal_3); var currVal_6 = (_v.context.ngIf && _v.context.ngIf.pictureUrl); var currVal_7 = i1.ɵnov(_v, 9); _ck(_v, 8, 0, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_2 = (i1.ɵnov(_v, 2).menuOpen || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = ((_v.context.ngIf == null) ? null : _v.context.ngIf.firstName); var currVal_5 = ((_v.context.ngIf == null) ? null : _v.context.ngIf.lastName); _ck(_v, 6, 0, currVal_4, currVal_5); }); }
export function View_UserProfileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_UserProfileComponent_1)), i1.ɵdid(1, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i12.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 9, "mat-menu", [["class", "user-options-menu"], ["xPosition", "before"]], null, null, null, i13.View__MatMenu_0, i13.RenderType__MatMenu)), i1.ɵdid(4, 1294336, [["menu", 4]], 3, i9._MatMenu, [i1.ElementRef, i1.NgZone, i9.MAT_MENU_DEFAULT_OPTIONS], { xPosition: [0, "xPosition"], overlapTrigger: [1, "overlapTrigger"], panelClass: [2, "panelClass"] }, null), i1.ɵqud(603979776, 1, { _allItems: 1 }), i1.ɵqud(603979776, 2, { items: 1 }), i1.ɵqud(603979776, 3, { lazyContent: 0 }), i1.ɵprd(2048, null, i9.MatMenu, null, [i9._MatMenu]), i1.ɵprd(2048, null, i9.MAT_MENU_PANEL, null, [i9.MatMenu]), (_l()(), i1.ɵeld(10, 0, null, 0, 2, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.openLogoutDialog() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i13.View_MatMenuItem_0, i13.RenderType_MatMenuItem)), i1.ɵdid(11, 180224, [[1, 4], [2, 4]], 0, i9.MatMenuItem, [i1.ElementRef, i12.DOCUMENT, i7.FocusMonitor, [2, i9.MAT_MENU_PANEL]], null, null), (_l()(), i1.ɵted(-1, 0, ["Salir"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.userProfile$)); _ck(_v, 1, 0, currVal_0); var currVal_1 = "before"; var currVal_2 = false; var currVal_3 = "user-options-menu"; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_4 = i1.ɵnov(_v, 11).role; var currVal_5 = i1.ɵnov(_v, 11)._highlighted; var currVal_6 = i1.ɵnov(_v, 11)._triggersSubmenu; var currVal_7 = i1.ɵnov(_v, 11)._getTabIndex(); var currVal_8 = i1.ɵnov(_v, 11).disabled.toString(); var currVal_9 = (i1.ɵnov(_v, 11).disabled || null); _ck(_v, 10, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_UserProfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-profile", [], null, null, null, View_UserProfileComponent_0, RenderType_UserProfileComponent)), i1.ɵdid(1, 49152, null, 0, i14.UserProfileComponent, [i15.AuthenticationService, i16.MatDialog], null, null)], null, null); }
var UserProfileComponentNgFactory = i1.ɵccf("app-user-profile", i14.UserProfileComponent, View_UserProfileComponent_Host_0, {}, {}, []);
export { UserProfileComponentNgFactory as UserProfileComponentNgFactory };
