import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
// http://localhost:4200/public/transfer-qr/1
export class TransferQRComponent {
    constructor(transferService, activatedRoute, storeService) {
        this.transferService = transferService;
        this.activatedRoute = activatedRoute;
        this.storeService = storeService;
        this.transferId = null;
        this.transfer = null;
        this.isLoading = false;
        this.errorMsj = '';
        this.qrTransferUrl = '';
        this.activatedRoute.params.subscribe((params) => {
            if (params.id) {
                this.transferId = Number(params.id);
                this.qrTransferUrl = `${window.location.origin}/transfers/transfer-accept/${this.transferId}`;
            }
        });
    }
    ngOnInit() {
        if (this.transferId) {
            this.getDataPage();
            console.log('data page');
        }
    }
    getDataPage() {
        this.isLoading = true;
        this.errorMsj = '';
        console.log('this.transfersService');
        this.transferService.getTransferOrderInfo(this.transferId)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe((result) => {
            this.transfer = result;
            this.transfer.sourceStoreCode = this.storeService.getStoreName(this.transfer.sourceStoreCode),
                this.transfer.destinationStoreCode = this.storeService.getStoreName(this.transfer.destinationStoreCode),
                console.log('tranferencia obtenida ' + result);
        }, (error) => {
            //Error callback
            // console.error('error caught in component ' + error.status);
            //if (error.status == 400)
            //this.errorMsj = 'El pedido es de retiro en tienda';
            if (error.status == 404)
                this.errorMsj = 'No se encuentra la trasferencia';
            throw error;
        });
    }
    getSkus() {
        var _a, _b;
        if (((_a = this.transfer) === null || _a === void 0 ? void 0 : _a.transferItemsSkus.length) > 0) {
            return (_b = this.transfer) === null || _b === void 0 ? void 0 : _b.transferItemsSkus.join(', ');
        }
        return '';
    }
}
