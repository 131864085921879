import { Pipe, PipeTransform } from '@angular/core';
import { LocalizationService } from 'src/app/services/localization.service';

@Pipe({
    name: 'localize'
})
export class LocalizePipe implements PipeTransform {

    constructor(private localizationService: LocalizationService) {
    }

    transform(key: string, ...args: any[]): string {
        return this.localizationService.localize(key, args);
    }
}

@Pipe({
    name: 'localizemarked'
})
export class LocalizeMakedPipe implements PipeTransform {

    constructor(private localizationService: LocalizationService) {
    }

    transform(key: string, ...args: any[]): string {
        return this.localizationService.localizeMarkedText(key, args);
    }
}
