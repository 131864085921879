import { AfterViewInit, ChangeDetectorRef } from '@angular/core';
import Quagga from '@ericblade/quagga2';
import { getMainBarcodeScanningCamera } from './camera-access';
export class EanScanComponent {
    constructor(beepService, 
    // private updateService: UpdateService,
    changeDetectorRef, dialogRef) {
        this.beepService = beepService;
        this.changeDetectorRef = changeDetectorRef;
        this.dialogRef = dialogRef;
    }
    ngAfterViewInit() {
        if (!navigator.mediaDevices || !(typeof navigator.mediaDevices.getUserMedia === 'function')) {
            this.errorMessage = 'getUserMedia is not supported';
            return;
        }
        this.initializeScanner();
        this.dialogRef.afterClosed().subscribe(() => Quagga.stop());
        /*if (environment.production) {
          setTimeout(() => {
            this.updateService.checkForUpdates();
          }, 10000);
        }*/
    }
    initializeScanner() {
        if (!navigator.mediaDevices || !(typeof navigator.mediaDevices.getUserMedia === 'function')) {
            this.errorMessage =
                'getUserMedia is not supported. Please use Chrome on Android or Safari on iOS';
            this.started = false;
            return Promise.reject(this.errorMessage);
        }
        // enumerate devices and do some heuristics to find a suitable first camera
        return Quagga.CameraAccess.enumerateVideoDevices()
            .then((mediaDeviceInfos) => {
            const mainCamera = getMainBarcodeScanningCamera(mediaDeviceInfos);
            if (mainCamera) {
                console.log(`Using ${mainCamera.label} (${mainCamera.deviceId}) as initial camera`);
                return this.initializeScannerWithDevice(mainCamera.deviceId);
            }
            else {
                console.error(`Unable to determine suitable camera, will fall back to default handling`);
                return this.initializeScannerWithDevice(undefined);
            }
        })
            .catch((error) => {
            this.errorMessage = `Failed to enumerate devices: ${error}`;
            this.started = false;
        });
    }
    initializeScannerWithDevice(preferredDeviceId) {
        var _a;
        console.log(`Initializing Quagga scanner...`);
        const constraints = {};
        if (preferredDeviceId) {
            // if we have a specific device, we select that
            constraints.deviceId = preferredDeviceId;
        }
        else {
            // otherwise we tell the browser we want a camera facing backwards (note that browser does not always care about this)
            constraints.facingMode = 'environment';
        }
        return Quagga.init({
            inputStream: {
                type: 'LiveStream',
                constraints,
                area: {
                    // defines rectangle of the detection/localization area
                    top: '25%',
                    right: '10%',
                    left: '10%',
                    bottom: '25%',
                },
                target: (_a = document.querySelector('#scanner-container'), (_a !== null && _a !== void 0 ? _a : undefined)),
            },
            decoder: {
                readers: ['ean_reader', 'upc_reader', 'ean_8_reader'],
                multiple: false,
            },
            // See: https://github.com/ericblade/quagga2/blob/master/README.md#locate
            locate: false,
        }, (err) => {
            if (err) {
                console.error(`Quagga initialization failed: ${err}`);
                this.errorMessage = `Initialization error: ${err}`;
                this.started = false;
            }
            else {
                console.log(`Quagga initialization succeeded`);
                Quagga.start();
                this.started = true;
                this.changeDetectorRef.detectChanges();
                Quagga.onDetected((res) => {
                    if (res.codeResult.code) {
                        //var msj = res.codeResult.format + ' - ' + res.codeResult.code;
                        this.onBarcodeScanned(res.codeResult.code);
                        //alert(msj);
                    }
                });
            }
        });
    }
    onBarcodeScanned(code) {
        // ignore duplicates for 2 seconds
        const now = new Date().getTime();
        if (this.lastScannedCodeDate !== undefined) {
            if (code === this.lastScannedCode && now < this.lastScannedCodeDate + 2000) {
                return;
            }
        }
        this.lastScannedCode = code;
        this.lastScannedCodeDate = now;
        this.changeDetectorRef.detectChanges();
        this.beepService.beep();
        this.dialogRef.close(code);
    }
}
