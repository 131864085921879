import { Component, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { OrderTrackingDto, OrderTrackingStepDto, TrackingService } from '../services/tracking.service';
import { finalize } from 'rxjs/operators';
import { T } from '@angular/cdk/keycodes';


@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss'],
})
export class OmsTrackingComponent {

  public order : OrderTrackingDto;

  public numDeli: string;
  public isLoading = false;
  public errorMsj;
  public urlZen;





  constructor(private trackingService: TrackingService,
              private activatedRoute: ActivatedRoute,
              private elementRef: ElementRef ) 
     {
        this.activatedRoute.params.subscribe((params) => {
          if (params.nro) {
            this.numDeli = params.nro;
            this.getDataPage();
          }
    });
  }

  ngAfterViewInit() {

    this.elementRef
        .nativeElement.ownerDocument
        .body.style.backgroundColor = '#fff';
  }

  getDataPage() {

    if (this.numDeli) {

      this.isLoading = true;
      this.errorMsj = '';
      
      this.trackingService.get(this.numDeli)
                          .pipe(finalize(() => (this.isLoading = false)))
                          .subscribe( 
                                (result) => {
                                  this.order = result;
                                },
                                (error) => {
                                    //Error callback
                                    // console.error('error caught in component ' + error.status);
                                    if (error.status == 400)
                                        this.errorMsj = 'El pedido es de retiro en tienda';
                                    if (error.status == 404)
                                        this.errorMsj = 'No se encuentra el pedido ingresado';

                                    throw error;
                                });
    }
  }

  openUrlZen(){

    if(this.order?.countryCode == 'AR')
      window.open('https://consultaspuppisargentina.zendesk.com/hc/es-419/requests/new', '_blank');

    if(this.order?.countryCode == 'CO')
      window.open('https://consultaspuppiscolombia.zendesk.com/hc/es-419/requests/new', '_blank');
  }

  isCol(): boolean {
    return this.order?.countryCode == 'CO';
  }
}
