/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logout-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./logout-dialog.component";
var styles_LogoutDialogComponent = [i0.styles];
var RenderType_LogoutDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoutDialogComponent, data: {} });
export { RenderType_LogoutDialogComponent as RenderType_LogoutDialogComponent };
export function View_LogoutDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "dialog-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["\u00BFDesea cerrar sesi\u00F3n?"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", "cerrar sesi\u00F3n"], ["src", "/assets/images/doggie-play.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 9, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["class", "btn__secondary"], ["mat-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).dialogRef.close(i1.ɵnov(_v, 11).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(11, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancelar"])), (_l()(), i1.ɵeld(13, 0, null, null, 3, "button", [["cdkFocusInitial", ""], ["class", "btn__primary"], ["mat-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).dialogRef.close(i1.ɵnov(_v, 15).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(15, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cerrar Sesi\u00F3n"]))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_4 = false; _ck(_v, 11, 0, currVal_4); var currVal_8 = true; _ck(_v, 15, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 10).disabled || null); var currVal_2 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); var currVal_3 = (i1.ɵnov(_v, 11).ariaLabel || null); _ck(_v, 9, 0, currVal_1, currVal_2, currVal_3); var currVal_5 = (i1.ɵnov(_v, 14).disabled || null); var currVal_6 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); var currVal_7 = (i1.ɵnov(_v, 15).ariaLabel || null); _ck(_v, 13, 0, currVal_5, currVal_6, currVal_7); }); }
export function View_LogoutDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logout-dialog", [], null, null, null, View_LogoutDialogComponent_0, RenderType_LogoutDialogComponent)), i1.ɵdid(1, 114688, null, 0, i7.LogoutDialogComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoutDialogComponentNgFactory = i1.ɵccf("app-logout-dialog", i7.LogoutDialogComponent, View_LogoutDialogComponent_Host_0, {}, {}, []);
export { LogoutDialogComponentNgFactory as LogoutDialogComponentNgFactory };
