<div class="register__container" fxLayout="column" fxLayoutAlign="start center">
  <div>
    <div class="user__image">
      <mat-icon [inline]="true" svgIcon="icon-puppis-blue" aria-label="Puppis" class="logo-image">Puppis</mat-icon>
    </div>
    <div class="user__info">
      <h1>{{user?.firstName}} {{user?.lastName}}</h1>
    </div>
  </div>
  <div class="password__form" *ngIf="user">
    <p>
      Establezca su contraseña para poder habilitar su perfil
    </p>

    <form [formGroup]="registerForm">
      <mat-form-field appearance="outline" class="password-field">
        <mat-label>Contraseña</mat-label>
        <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
        <input id="password" matInput #password [type]="toggle.type" placeholder="Contraseña" formControlName="password1">
        
        <mat-error *ngIf="registerForm.get('password1').hasError('required')" class="form__error">
            password is required
        </mat-error>
        <mat-error *ngIf="registerForm.get('password1').hasError('passwordStrength')" class="form__error">
            {{registerForm.get('password1').errors['passwordStrength']}}
        </mat-error>

      </mat-form-field>

      <div class="password-strength__container">
        <mat-password-strength #password1 (onStrengthChanged)="onStrengthChanged($event)" [password]="password.value">
        </mat-password-strength>
        <div class="password-strength__text">
          <mat-hint aria-live="polite">
            {{passwordStrengthText}}
          </mat-hint>
        </div>
      </div>

      <mat-form-field appearance="outline">
        <mat-pass-toggle-visibility #confirmation matSuffix></mat-pass-toggle-visibility>
        <input id="confirmPassword" matInput [type]="confirmation.type" placeholder="Repetir Contraseña" formControlName="password2"
          [errorStateMatcher]="errorMatcher">
        <mat-error *ngIf="formControls.password2.errors?.required" class="form__error">
          Se requiere confirmar la contraseña
        </mat-error>
      </mat-form-field>

      <div *ngIf="registerForm.hasError('passwordsDoNotMatch') && !formControls.password2.errors" class="form__error">
          La contraseña no coincide          
      </div>
    </form>

    <div class="action__buttons">
      <button mat-button class="btn__primary" (click)="setPassword()">Establecer contraseña</button>
    </div>
  </div>

  <div *ngIf="!user">
    <p>
      {{errorMessage}}
    </p>
  </div>
</div>