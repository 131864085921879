import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class ProductsService {
    private baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = environment.productsLiteUrl;
    }

    findProducts(filter: string, maxresult: number, storeCode?: string): Observable<ProductDto[]> {
        const requestParams = {
            filter,
            includeStock: (storeCode !== undefined) + '',
            storeCode,
            maxResultCount: maxresult + ''
        };

        const queryUrl = `${this.baseUrl}/api/services/app/Product/findProduct`;
        return this.http.post(queryUrl, requestParams).pipe(map((x: any) => x.result ? x.result.items : []));
    }

    findProductsByCardCode(filter: string, maxresult: number, cardCode: string): Observable<ProductDto[]> {
        const requestParams = {
            filter,
            maxResultCount: maxresult + '',
            cardCode: cardCode
        };

        const queryUrl = `${this.baseUrl}/api/services/app/Product/findProductByCardCode`;
        return this.http.post(queryUrl, requestParams).pipe(map((x: any) => x.result ? x.result.items : []));
    }

    getStock(sku: string, storeCode: string): Observable<number> {
        const queryUrl = `${this.baseUrl}/api/services/app/Product/getStockByStore?sku=${sku}&store=${storeCode}`;
        return this.http
            .get<any>(queryUrl)
            .pipe(map(x => x.result ? x.result.stock : 0));
    }

    get(id: number): Observable<number> {
        const queryUrl = `${this.baseUrl}/api/services/app/Product/Get?id=${id}`;
        return this.http.get<any>(queryUrl)
                        .pipe(map(x => x.result ? x.result : 0));
    }


    getBySku(sku: string, country: string): Observable<number> {

        const queryUrl = `${this.baseUrl}/api/services/app/Product/GetBySku?sku=${sku}&CountryCode=${country}`;
        return this.http.get<any>(queryUrl)
                        .pipe(map(x => x.result ? x.result : 0));
    }
}

export class ProductDto {
    name: string;
    sku: string;
    salesPrice: number;
    stock: number;
    storeCode: string;
    provider?: string;
    providerCode?: string;
    barCode?: string;
    id: number;
    pictureUrl?: string;
}
