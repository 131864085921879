import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { Router } from '@angular/router';
import { FeatureFlagHelper } from '../../helpers/feature-flag-helper';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {

  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

  public isExpanded = false;

  constructor(private router: Router) {
  }

  redirectToHome() {
    this.router.navigate(['/']);
  }

  public isNotificationsEnable(): boolean{
    return FeatureFlagHelper.isNotificationEnable();
  }
}
