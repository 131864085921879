<ng-container *ngIf="(userProfile$|async) as user">

  <button mat-button [matMenuTriggerFor]="countryMenu" [disabled]="disabledSelectCountry" class="country-selector__button noPaddingMobile">
    <mat-icon [svgIcon]="selectedCountry.icon">{{ selectedCountry.name }}</mat-icon>
    <span class="ocultarEnMobile">{{ selectedCountry.name }}</span>
    <mat-icon *ngIf="user.countryCode === ''">expand_more</mat-icon>
  </button>

  <mat-menu #countryMenu="matMenu">
    <button *ngFor="let c of countryList" mat-menu-item (click)="selectCountry(c)">
      <mat-icon [svgIcon]="c.icon" class="myIcon" ></mat-icon>
      <span>{{ c.name }}</span>
    </button>
  </mat-menu>
</ng-container>
