<!--component html goes here -->
<mat-dialog-header>
  <div fxFill fxLayout="row" class="header mt-12">
    <img src="../../../../assets/icons/producto.png">
    <h1>{{'addProduct'|localize}}</h1>
  </div>
</mat-dialog-header>
<mat-dialog-content>
  <div fxFill fxLayout="row">
    <mat-form-field class="full-width">
      <mat-label>{{'findProductByDescriptionSku' | localize}}</mat-label>
      <input type="text" matInput [formControl]="productsControl" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <ng-container *ngIf="isLoading; else elseTemplate">
          <mat-option>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </mat-option>
        </ng-container>
        <ng-template #elseTemplate>
          <mat-option *ngFor="let product of filteredProducts" [value]="product"
            (onSelectionChange)="onProductSelected($event)">
            <span>{{product.name}} ({{product.sku}})</span>
            <div *ngIf="data.showExtraInfo">
              <small>{{'salesPrice'|localize}} {{ product.salesPrice | currency}}</small>
              <small> - {{'Stock'|localize}} {{ product.stock }}</small>
            </div>
          </mat-option>
        </ng-template>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="action-buttons">
  <button mat-button class="btn__secondary btn-width" [mat-dialog-close]="false">
    {{'cancel'|localize}}
  </button>
  <button mat-button type="submit" class="btn__primary btn-width" (click)="accept()">
    <span>{{'accept'|localize}}</span>
  </button>
</mat-dialog-actions>
