/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ean-scan.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./ean-scan.component";
import * as i4 from "./beep.service";
import * as i5 from "@angular/material/dialog";
var styles_EanScanComponent = [i0.styles];
var RenderType_EanScanComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EanScanComponent, data: {} });
export { RenderType_EanScanComponent as RenderType_EanScanComponent };
function View_EanScanComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "error-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 1, 0, currVal_0); }); }
export function View_EanScanComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EanScanComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["id", "scanner-container"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EanScanComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ean-scan", [], null, null, null, View_EanScanComponent_0, RenderType_EanScanComponent)), i1.ɵdid(1, 4243456, null, 0, i3.EanScanComponent, [i4.BeepService, i1.ChangeDetectorRef, i5.MatDialogRef], null, null)], null, null); }
var EanScanComponentNgFactory = i1.ɵccf("app-ean-scan", i3.EanScanComponent, View_EanScanComponent_Host_0, {}, {}, []);
export { EanScanComponentNgFactory as EanScanComponentNgFactory };
