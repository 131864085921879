import { Component, NgModule, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, concatMap, finalize } from 'rxjs/operators';
import { EanScanComponent } from 'src/app/products-lite/components/ean-scan/ean-scan.component';
import { StoreService } from 'src/app/services/stores.service';
import { UiService } from 'src/app/services/ui.service';
import { getRemoteServiceBaseUrl } from '../oms.module';
import {
  API_BASE_URL,
  PickingGroupDto,
  PickingGroupItemDto,
  PickingGroupItemServiceProxy,
  PickingGroupServiceProxy,
  UpdatePickedQuantityRequest,
} from '../services/service-proxy/service-proxies';
import { ProductLocationFormComponent } from './product-location-form/product-location-form.component';

@Component({
  selector: 'app-picking-mobile-form',
  templateUrl: './picking-mobile-form.component.html',
  styleUrls: ['./picking-mobile-form.component.scss'],

  providers: [
    { provide: API_BASE_URL, useFactory: getRemoteServiceBaseUrl },
    PickingGroupServiceProxy,
    PickingGroupItemServiceProxy
  ]
})

export class PickingMobileFormComponent implements OnInit {

  isLoading = false;
  finished = false;
  barcode: string;
  currentProductNumber: number;
  pickingGroup$: Observable<PickingGroupDto>;
  countryCode = 'CO';
  storeCode : string;

  constructor(private pickingGroupService: PickingGroupServiceProxy,
              private pickingGroupItemServiceProxy: PickingGroupItemServiceProxy,
              private activatedRoute: ActivatedRoute,
              private uiService: UiService,
              private dialog: MatDialog,
              private _storeService: StoreService
  ) {}

  ngOnInit(): void {

    this.uiService.openSnackBar('Listo para pickear', null);

    this.currentProductNumber = 1;
    this.pickingGroup$ = this.activatedRoute.params.pipe(
      concatMap( (params) => this.pickingGroupService.getWithOrders(params.id) ),
      tap( data => {
        console.log('pickingGroupService ', data);
        this.setCountryCodeByStore(data.storeCode);
      })
    );
  }

  setCountryCodeByStore(storeCode : string) {

    var store = this._storeService.getStore(storeCode);

    if(store) {
      this.countryCode = store.countryCode;
      this.storeCode   = store.storeCode;
    }
      
  }

  onBarcodeScanned(item: PickingGroupItemDto) {
    
    if (item.quantityPicked < item.quantity) {

      let pickedOk = false;

      if (this.barcode === item.barCode) {
        item.quantityPicked++;
        pickedOk = true;
      }
      else
      {
        //  fix para codigos de 12 digitos que el lector EAN13 los puede leer con un 0 adelante
        if((item.barCode.length == 12 && this.barcode.length == 13 && this.barcode.charAt(0) === '0')) {

          this.barcode = this.barcode.substring(1);

          if (this.barcode === item.barCode ) {
             item.quantityPicked++;
             pickedOk = true;
          }

        }
              
      }

      if(!pickedOk)
        this.uiService.openSnackBar('El codigo escaneado no corresponde al producto.', null);
      
      return;
    }

  }

  nextProduct(picking: PickingGroupDto): void {
    const product = picking.pickingGroupItems[this.currentProductNumber - 1];
    if (product.quantityPicked > product.quantity) {
      this.uiService.openSnackBar('La cantidad pickeada excede al total.', null);
    } else if (this.currentProductNumber < picking.pickingGroupItems.length) {
      this.barcode = '';
      this.currentProductNumber++;
    }
  }

  prevProduct(): void {
    if (this.currentProductNumber > 1) {
      this.barcode = '';
      this.currentProductNumber--;
    }
  }

  finishPicking(picking: PickingGroupDto): void {
    if (picking.pickingGroupItems.some((x) => x.quantityPicked > x.quantity)) {
      this.uiService.openSnackBar('La cantidad pickeada excede al total.', null);
      return;
    }

    this.isLoading = true;
    const request = new UpdatePickedQuantityRequest({
      pickingGroupId: picking.id,
      pickingGroupItems: picking.pickingGroupItems,
    });

    this.pickingGroupService
      .updatePickedQuantity(request)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(() => {
        this.finished = true;
      });
  }

  openScan(item: PickingGroupItemDto): void {
    const dialogConfig = new MatDialogConfig();

    // dialogConfig.data = data;
    dialogConfig.width = '95%';
    dialogConfig.maxWidth = '95%';
    dialogConfig.height = '300px';
    dialogConfig.panelClass = 'scan-dialog-container';

    this.dialog.open(EanScanComponent, dialogConfig)
               .afterClosed()
               .subscribe((codeResult) => {
                  if (codeResult) {
                    this.barcode = codeResult;
                    this.onBarcodeScanned(item);
                  }
                });
  }


  selectLocation(pgItem: PickingGroupItemDto) {

    //  Aislee > Location > Prod

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = 
    { 
      id: pgItem.locationId,
      locationId: pgItem.locationId,  
      countryCode : this.countryCode,
      storeCode : this.storeCode,
      sku : pgItem.sku 
    };

    this.dialog.open(ProductLocationFormComponent, dialogConfig)
               .afterClosed()
               .subscribe((updatedProductLocation) => {

                if (updatedProductLocation) {
                  
                    console.log('updated ProductLocation in WMS', updatedProductLocation);

                    this.isLoading = true;

                    // al actulizar el dato de productLocation en WMS, actualizo el pickingGroupItem de la pantalla para refrescar la vista
                    pgItem.locationId = updatedProductLocation.locationId;
                    pgItem.location   = updatedProductLocation.locationCode;
                    pgItem.aisle      = updatedProductLocation.aisleName;

                    // actulizar el pickingGroupItem en el OMS

                    // Problema
                    // ( y si se cambian los productos de location en el wms, los picking group quedan desactulizados ( exepto este)
                    // Solucion,  si actulizo un ProductLocation, deberia buscar todos los pickingGroups activos que tengan ese producto y actulizarlos)

                    this.pickingGroupItemServiceProxy.update(pgItem)
                                                     .pipe(finalize(() => this.isLoading = false))
                                                     .subscribe( updatedItem => { console.log('updated Item in oms', updatedItem) });


                }
    });
  }
}
