import { ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderTrackingDto, TrackingService } from '../services/tracking.service';
import { finalize } from 'rxjs/operators';
export class OmsTrackingComponent {
    constructor(trackingService, activatedRoute, elementRef) {
        this.trackingService = trackingService;
        this.activatedRoute = activatedRoute;
        this.elementRef = elementRef;
        this.isLoading = false;
        this.activatedRoute.params.subscribe((params) => {
            if (params.nro) {
                this.numDeli = params.nro;
                this.getDataPage();
            }
        });
    }
    ngAfterViewInit() {
        this.elementRef
            .nativeElement.ownerDocument
            .body.style.backgroundColor = '#fff';
    }
    getDataPage() {
        if (this.numDeli) {
            this.isLoading = true;
            this.errorMsj = '';
            this.trackingService.get(this.numDeli)
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe((result) => {
                this.order = result;
            }, (error) => {
                //Error callback
                // console.error('error caught in component ' + error.status);
                if (error.status == 400)
                    this.errorMsj = 'El pedido es de retiro en tienda';
                if (error.status == 404)
                    this.errorMsj = 'No se encuentra el pedido ingresado';
                throw error;
            });
        }
    }
    openUrlZen() {
        var _a, _b;
        if (((_a = this.order) === null || _a === void 0 ? void 0 : _a.countryCode) == 'AR')
            window.open('https://consultaspuppisargentina.zendesk.com/hc/es-419/requests/new', '_blank');
        if (((_b = this.order) === null || _b === void 0 ? void 0 : _b.countryCode) == 'CO')
            window.open('https://consultaspuppiscolombia.zendesk.com/hc/es-419/requests/new', '_blank');
    }
    isCol() {
        var _a;
        return ((_a = this.order) === null || _a === void 0 ? void 0 : _a.countryCode) == 'CO';
    }
}
