<h1 mat-dialog-title>Mostrar Columnas</h1>
<mat-dialog-content>
  <mat-selection-list #list>
    <mat-list-option *ngFor="let column of this.columns" [value]="column" [selected]="column.visible">
      {{ column.name | localize}}
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button class="btn__secondary btn-width" [mat-dialog-close]="false">
    Cancelar
  </button>
  <button mat-button type="button" class="btn__primary btn-width" (click)="onSelectionChange(list)">
    <span>Aplicar</span>
  </button>
  <button *ngIf="this.callback" mat-button type="button" class="btn__primary btn-width" (click)="onSaveChange(list)">
    <span>Guardar</span>
  </button> 

</mat-dialog-actions>