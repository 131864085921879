import { NotificationResponse, NotificationServiceProxy } from '../notifications/services/service-proxy/service-proxies';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, timer } from 'rxjs';
import { concatMap, map, shareReplay } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class NotificationsService {
    constructor(http) {
        this.http = http;
        this.url = `${environment.notificationsUrl}/api/services/app/Notification/getAllByCurrentUser?channel=APP`;
        this.getAllNotifications();
    }
    getAllNotifications() {
        this.proxy = new NotificationServiceProxy(this.http, environment.notificationsUrl);
        this.setTimer();
    }
    setTimer() {
        const observ$ = this.http.get(this.url)
            .pipe(map(x => x.result));
        this.notifications$ = timer(0, 60000).pipe(concatMap(_ => observ$), shareReplay(1));
    }
    markAsReaded(notificationIds) {
        return this.proxy.markNotificationAsReaded(notificationIds);
    }
}
NotificationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationsService_Factory() { return new NotificationsService(i0.ɵɵinject(i1.HttpClient)); }, token: NotificationsService, providedIn: "root" });
