import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { HomeComponent } from './home.component';
import { ProfileComponent } from '../profile/profile.component';

const routes: Routes = [
    {
        path: '', canActivate: [AuthGuard], component: HomeComponent, children: [
            { path: '', redirectTo: 'oms/info', pathMatch: 'full' },
            { path: 'profile', canActivate: [AuthGuard], component: ProfileComponent },
            { path: 'security', canActivate: [AuthGuard], loadChildren:  () => import('../security/security.module').then(m => m.SecurityModule) },
            { path: 'supplying', canActivate: [AuthGuard], loadChildren: () => import('../supplying/supplying.module').then(m => m.SupplyingModule) },
            { path: 'oms', canActivate: [AuthGuard], loadChildren: () => import('../oms/oms.module').then(m => m.OmsModule) },
            { path: 'wms', canActivate: [AuthGuard], loadChildren: () => import('../wms/wms.module').then(m => m.WmsModule) },
            { path: 'sales', canActivate: [AuthGuard], loadChildren: () => import('../sales/sales.module').then(m => m.SalesModule) },
            { path: 'products', canActivate: [AuthGuard], loadChildren: () => import('../products-lite/products-lite.module').then(m => m.ProductsLiteModule) },
            { path: 'mailling', canActivate: [AuthGuard], loadChildren: () => import('../mailling/mailling.module').then(m => m.MaillingModule) },
            { path: 'stores', canActivate: [AuthGuard], loadChildren: () => import('../stores/stores.module').then(m => m.StoresModule) },
            { path: 'landing', canActivate: [AuthGuard], loadChildren: () => import('../landing/landing.module').then(m => m.LandingModule) },
            { path: 'logs', canActivate: [AuthGuard], loadChildren: () => import('../logs/logs.module').then(m => m.LogsModule) },
            { path: 'transfers', canActivate: [AuthGuard], loadChildren: () => import('../transfers/transfers.module').then(m => m.TransfersModule) },
            { path: 'clientes', canActivate: [AuthGuard], loadChildren:  () => import('../clients/clients.module').then(m => m.ClientsModule) },
            { path: 'notifications', canActivate: [AuthGuard], loadChildren:  () => import('../notifications/notifications.module').then(m => m.NotificationsModule) }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class HomeRoutingModule { }
