<button mat-button [matMenuTriggerFor]="menu" class="user-options" *ngIf="(userProfile$|async) as user">
  <span class="ocultarEnMobile">Hola, </span><span class="menu__user-name ocultarEnMobile" style="margin-right:8px;">{{ user?.firstName }} {{ user?.lastName }}</span>
  <ng-container *ngIf="user && user.pictureUrl; else noPictureTemplate">
    <img
      mat-card-avatar
      [src]="user.pictureUrl"
      alt="usuario"
      class="user-picture"
    />
  </ng-container>
  <ng-template #noPictureTemplate>
    <mat-icon class="profile__icon">account_circle</mat-icon>
  </ng-template>
</button>

<mat-menu
  #menu="matMenu"
  xPosition="before"
  [overlapTrigger]="false"
  class="user-options-menu"
>
  <!-- <button mat-menu-item routerLink="/profile">Perfil</button> -->
  <button mat-menu-item (click)="openLogoutDialog()">Salir</button>
</mat-menu>
