import { Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { HomeComponent } from './home.component';
import { ProfileComponent } from '../profile/profile.component';
const ɵ0 = () => import("./../security/security.module.ngfactory").then(m => m.SecurityModuleNgFactory), ɵ1 = () => import("./../supplying/supplying.module.ngfactory").then(m => m.SupplyingModuleNgFactory), ɵ2 = () => import("./../oms/oms.module.ngfactory").then(m => m.OmsModuleNgFactory), ɵ3 = () => import("./../wms/wms.module.ngfactory").then(m => m.WmsModuleNgFactory), ɵ4 = () => import("./../sales/sales.module.ngfactory").then(m => m.SalesModuleNgFactory), ɵ5 = () => import("./../products-lite/products-lite.module.ngfactory").then(m => m.ProductsLiteModuleNgFactory), ɵ6 = () => import("./../mailling/mailling.module.ngfactory").then(m => m.MaillingModuleNgFactory), ɵ7 = () => import("./../stores/stores.module.ngfactory").then(m => m.StoresModuleNgFactory), ɵ8 = () => import("./../landing/landing.module.ngfactory").then(m => m.LandingModuleNgFactory), ɵ9 = () => import("./../logs/logs.module.ngfactory").then(m => m.LogsModuleNgFactory), ɵ10 = () => import("./../transfers/transfers.module.ngfactory").then(m => m.TransfersModuleNgFactory), ɵ11 = () => import("./../clients/clients.module.ngfactory").then(m => m.ClientsModuleNgFactory), ɵ12 = () => import("./../notifications/notifications.module.ngfactory").then(m => m.NotificationsModuleNgFactory);
const routes = [
    {
        path: '', canActivate: [AuthGuard], component: HomeComponent, children: [
            { path: '', redirectTo: 'oms/info', pathMatch: 'full' },
            { path: 'profile', canActivate: [AuthGuard], component: ProfileComponent },
            { path: 'security', canActivate: [AuthGuard], loadChildren: ɵ0 },
            { path: 'supplying', canActivate: [AuthGuard], loadChildren: ɵ1 },
            { path: 'oms', canActivate: [AuthGuard], loadChildren: ɵ2 },
            { path: 'wms', canActivate: [AuthGuard], loadChildren: ɵ3 },
            { path: 'sales', canActivate: [AuthGuard], loadChildren: ɵ4 },
            { path: 'products', canActivate: [AuthGuard], loadChildren: ɵ5 },
            { path: 'mailling', canActivate: [AuthGuard], loadChildren: ɵ6 },
            { path: 'stores', canActivate: [AuthGuard], loadChildren: ɵ7 },
            { path: 'landing', canActivate: [AuthGuard], loadChildren: ɵ8 },
            { path: 'logs', canActivate: [AuthGuard], loadChildren: ɵ9 },
            { path: 'transfers', canActivate: [AuthGuard], loadChildren: ɵ10 },
            { path: 'clientes', canActivate: [AuthGuard], loadChildren: ɵ11 },
            { path: 'notifications', canActivate: [AuthGuard], loadChildren: ɵ12 }
        ]
    },
];
export class HomeRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12 };
