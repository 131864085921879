import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NameValueDto } from '../oms-lite/services/service-proxy/service-proxies';
import { environment } from 'src/environments/environment';
import { map, tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LocalizationService {
    private baseUrl = environment.settingsUrl;
    private localizedStrings: NameValueDto[] = [];

    constructor(private httpClient: HttpClient) {
        if (!this.getCulture()) {
            this.setCulture('es-CO');
        }
    }

    setCulture(culture: string) {
        localStorage.setItem('culture', culture);
    }

    getCulture(): string {
        return localStorage.getItem('culture');
    }

    load() {
        const culture = this.getCulture();

        return this.httpClient
            .get<any>(`${this.baseUrl}/api/services/app/Localization/GetAllByCulture?culture=${culture}`)
            .pipe(catchError((e) => of([])))
            .pipe(map(r => r.result || []))
            .pipe(tap((items: any[]) => {
                items.forEach(element => {
                    this.localizedStrings.push(
                        new NameValueDto({ name: element.key, value: element.text })
                    );
                });
            }));
    }

    localize(key: string, ...args: any[]): string {
        const item = this.localizedStrings
            .find((nameValue: NameValueDto) => key != null && key != undefined && nameValue.name.toLowerCase() === key.toLowerCase());

        if (!item) {
            return key;
        }

        return this.formatString(item.value, args);
    }

    localizeMarkedText(text: string, ...args: any[]){
        var newText:string = "";
        var reBrackets = /\[(.*?)\]/g;
        var found: string[];
            while(found = reBrackets.exec(text)) {
                var key = found[1];
                newText = text.replace("["+key+"]", this.localize(key));
            };
        if(newText =="") newText = text;
        return newText;
    }

    private formatString(str: string, ...args: any[]): string {
        for (let i = 0; i < args.length; i++) {
            const placeHolder = '{' + i + '}';
            str = str.replace(placeHolder, args[i]);
        }

        return str;
    }
}
