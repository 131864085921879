import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { DeliveryDataDto } from '../oms/services/service-proxy/service-proxies';

@Injectable({
    providedIn: 'root',
  })
export class TrackingService {

    private baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = environment.omsUrl;
    }

    get(nro: string): Observable<OrderTrackingDto> {

        const queryUrl = `${this.baseUrl}/api/services/app/OrderTracking/Get?nro=${nro}`;

        return this.http
            .get<any>(queryUrl)
            .pipe(map(x => x.result ? x.result : null));
    }

    validateOrderBySecureId(secureId: string): Observable<any> {
        const query = `${this.baseUrl}/api/services/app/OrderTracking/ValidateOrderBySecureId?secureid=${secureId}`;

        return this.http.get<any>(query).pipe(map(x => x.result ? x.result : null));
    }

    updateDeliveryData(deliveryData: any): Observable<any> {
        const query = `${this.baseUrl}/api/services/app/OrderTracking/UpdateDeliveryData`;

        return this.http.post<any>(query, {...deliveryData});
    }
 }

export class OrderTrackingDto {

    id : number;

    isShipping: boolean;

    countryCode: string;
    nombre: string | undefined;

    estimatedTime: string | undefined;
    estimatedTimeEnd: string | undefined;
    estimatedDate: string | undefined;

    adress: string | undefined;
    calle: string | undefined;
    numero: string | undefined;
    pisoDepto: string | undefined;
    barrioCiudad: string | undefined;
    codigoPostal: string | undefined;
    provincia: string | undefined;
    telefono: string | undefined;

    storeName : string | undefined;
    storeAdress  : string | undefined;
    storeTelefono  : string | undefined;
    storeMail  : string | undefined;


    adressLat : number;
    adressLng : number;

    lastStepDesc: string | undefined;
    lastStepDetail: string | undefined;
    lastStepIsDelivered : boolean;

    steps: OrderTrackingStepDto[] | [];
    orderItem: OrderItemDto[] | [];

    editable: boolean;

    dispatchTag: string | undefined;
}

export interface OrderItemDto  {

    sku: string | undefined;
    description: string| undefined;
    imageUrl: string | undefined;
    quantity: number | undefined;
    grossAmount: number | undefined;
    netAmount: number | undefined;
}






export class OrderTrackingStepDto {

    dateDesc: string | undefined;
    stepDesc: string | undefined;
    stepDescSub: string | undefined;
    isChecked: boolean ;
}