<button mat-icon-button [matMenuTriggerFor]="notifications">
  <ng-container>
    <mat-icon class="profile__icon" [matBadge]="( (notifications$ | async )?.notificationsNotReadedCount || 0 )">{{ 'notifications' }}</mat-icon>
  </ng-container>
</button>

<mat-menu #notifications="matMenu">
  <mat-nav-list style="width: 320px; max-height: 320px;">

    <ng-container *ngIf="( (notifications$ | async )?.notificationsNotReadedCount || 0) > 0; else emptyNotifications">

      <ng-container *ngFor="let notification of (notifications$ | async).notificationsList" >
        <app-notification-item [notification]="notification" matTooltip="{{ notification.body }}" [matTooltipPosition]="'before'" ></app-notification-item>
      </ng-container>

      <mat-list-item (click)="goToNotificationList()">
        <div mat-line class="center">{{ 'seeAllNotifications' | localize}}</div>
      </mat-list-item>

      <mat-list-item (click)="readAll()">
        <div mat-line class="center">{{ 'readAllNotifications' | localize}}</div>
      </mat-list-item>

    </ng-container>

    <ng-template #emptyNotifications>

      <mat-list-item>
        <div mat-line class="center">{{ 'NoDataToDisplay' | localize }}</div>
      </mat-list-item>
    
      <mat-list-item (click)="goToNotificationList()">
        <div mat-line  class="center">{{ 'seeAllNotifications' | localize}}</div>
      </mat-list-item>
    
    </ng-template>

  </mat-nav-list>
</mat-menu>

