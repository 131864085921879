import { NameValueDto } from '../oms-lite/services/service-proxy/service-proxies';
import { environment } from 'src/environments/environment';
import { map, tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LocalizationService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.baseUrl = environment.settingsUrl;
        this.localizedStrings = [];
        if (!this.getCulture()) {
            this.setCulture('es-CO');
        }
    }
    setCulture(culture) {
        localStorage.setItem('culture', culture);
    }
    getCulture() {
        return localStorage.getItem('culture');
    }
    load() {
        const culture = this.getCulture();
        return this.httpClient
            .get(`${this.baseUrl}/api/services/app/Localization/GetAllByCulture?culture=${culture}`)
            .pipe(catchError((e) => of([])))
            .pipe(map(r => r.result || []))
            .pipe(tap((items) => {
            items.forEach(element => {
                this.localizedStrings.push(new NameValueDto({ name: element.key, value: element.text }));
            });
        }));
    }
    localize(key, ...args) {
        const item = this.localizedStrings
            .find((nameValue) => key != null && key != undefined && nameValue.name.toLowerCase() === key.toLowerCase());
        if (!item) {
            return key;
        }
        return this.formatString(item.value, args);
    }
    localizeMarkedText(text, ...args) {
        var newText = "";
        var reBrackets = /\[(.*?)\]/g;
        var found;
        while (found = reBrackets.exec(text)) {
            var key = found[1];
            newText = text.replace("[" + key + "]", this.localize(key));
        }
        ;
        if (newText == "")
            newText = text;
        return newText;
    }
    formatString(str, ...args) {
        for (let i = 0; i < args.length; i++) {
            const placeHolder = '{' + i + '}';
            str = str.replace(placeHolder, args[i]);
        }
        return str;
    }
}
LocalizationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalizationService_Factory() { return new LocalizationService(i0.ɵɵinject(i1.HttpClient)); }, token: LocalizationService, providedIn: "root" });
