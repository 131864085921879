import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { User } from '../shared/models/user';
import { OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
import { AuthConfig } from 'angular-oauth2-oidc';
import { UserService } from './user.service';
import { HttpClient } from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  endpoint: string = '/api/auth';
  baseUrl: string;
  isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  userProfile$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  authConfig: AuthConfig = environment.AUTH_CONFIG;
  isInternalUser: boolean;
  userData: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private oauthService: OAuthService,
    private userService: UserService,
    private commonService: CommonService
  ) {
    this.baseUrl = environment.securityUrl;
    this.oauthService.configure(this.authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.isInternalUser = localStorage.getItem('is_internal_user') === 'true';
    this.getUserData(this.isInternalUser);
    // this.validateAuthorization();
  }

  loginExternal() {
    const body = {
      firstName: this.getFieldFromGoogle('given_name'),
      lastName: this.getFieldFromGoogle('family_name'),
      pictureUrl: this.getFieldFromGoogle('picture'),
      email: this.getFieldFromGoogle('email'),
      providerAuthentication: "google"
    };
    return this.http.post(`${this.baseUrl}/login/external`, body);
  }

  login(userInfo: any) {
    const body = {
      name: userInfo.email,
      password: userInfo.password
    };
    return this.http.post(`${this.baseUrl}/login/internal`, body);
  }

  loginWithGoogleSingin(login: {idToken: any, providerId: any}): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/login/google-sing-in`, login);
  }

  refreshToken(refreshToken: string) {
    const httpHeaders = { refresh_token: refreshToken };
    return this.http.post(`${this.baseUrl}/login/refresh-token`, {}, { headers: httpHeaders });
  }

  logout() {
    // remove sessions
    this.userData = undefined;
    this.removeSession();
    this.isLoggedIn$.next(false);
    this.userProfile$.next(null);
    this.router.navigateByUrl('/login');
  }

  // isAuthorized() {
  //   return !!localStorage.getItem('id_token');
  // }

  validateAuthorization() {
    // if (this.isAuthorized()) {
    //   this.isLoggedIn$.next(true);
    //   return true;
    // } else {
    //   this.isLoggedIn$.next(false);
    //   return false;
    // }
  }

  getReturnUrl() {
    return localStorage.getItem('returnUrl') || false;
  }

  setReturnUrl(url) {
    localStorage.setItem('returnUrl', url);
  }

  removeReturnUrl() {
    localStorage.removeItem('returnUrl');
  }

  removeSession() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('granted_scopes');
    localStorage.removeItem('access_token_stored_at');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('id_token');
    localStorage.removeItem('id_token_claims_obj');
    localStorage.removeItem('id_token_expires_at');
    localStorage.removeItem('id_token_stored_at');
    localStorage.removeItem('session_state');
    localStorage.removeItem('is_internal_user');
    localStorage.removeItem('user');

    localStorage.removeItem('CURRENT_STORE');
    localStorage.removeItem('culture');

    this.commonService.deleteAllCookies();

    localStorage.removeItem('id_token');

    this.isLoggedIn$.next(false);
    this.userProfile$.next(null);

    this.removeReturnUrl();
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem('id_token') && !!localStorage.getItem('is_internal_user')
  }

  loginWithGoogle() {
    // this.oauthService.initImplicitFlow();
    localStorage.clear();
    localStorage.setItem('is_internal_user', 'false');
    this.setReturnUrl('/clientes');
    this.oauthService.loadDiscoveryDocument().then(doc => {
      this.oauthService
        .tryLogin({
          onTokenReceived: context => {
            console.log("token recibido");
            this.oauthService.loadUserProfile().then(cliams => {
              let preUrl = this.oauthService.state;
              if (preUrl == null || preUrl == '') {
                preUrl = '/';
              }
              this.router.navigateByUrl(preUrl);
            });
          }
        })
        .then(() => {
          if (!this.oauthService.hasValidAccessToken()) {
            const returnUrl = this.getReturnUrl();
            this.removeReturnUrl();
            this.oauthService.initImplicitFlow(
              returnUrl ? returnUrl : '/oms/info'
            );
            localStorage.setItem('is_internal_user', 'false');
          }
        });
    });
  }

  getFieldFromGoogle(field: string) {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims[field];
  }

  getUserData(isInternalUser: boolean) {
    let user: User;
    if (!isInternalUser) {
      user = {
        id: '',
        password: '',
        email: this.getFieldFromGoogle('email'),
        firstName: this.getFieldFromGoogle('given_name'),
        lastName: this.getFieldFromGoogle('family_name'),
        pictureUrl: this.getFieldFromGoogle('picture')
      };
      this.userProfile$.next(user);
    } else {
      user = this.userService.getUserProfileFromLocal();
      if (!user || !user.id) {
        this.logout();
      }
      this.userProfile$.next(user);
    }
    return user;
  }

  setUserProfile(userProfile: any) {
    let countryCode = userProfile.countryCode;

    if (!countryCode) {
      countryCode = this.commonService.getCookie('country');
    }
    this.commonService.setCookie('country', countryCode, 120);
    this.setCountry(countryCode || 'CO');
    this.userProfile$.next(userProfile);
  }

  setCountry(code: string) {
    if (code === 'AR') {
      this.commonService.setSelectedCountry({
        code: 'AR',
        name: 'Argentina',
        icon: 'icon-flag-arg'
      });
    } else if (code === 'CO') {
      this.commonService.setSelectedCountry({
        code: 'CO',
        name: 'Colombia',
        icon: 'icon-flag-col'
      });
    } else {
      this.commonService.setSelectedCountry({
        code: '',
        name: 'Todos',
        icon: 'icon-flag-arg-col'
      });
    }
  }

  setSessionToken(idToken) {
    localStorage.setItem('id_token', idToken);
  }

  setRefreshToken(refreshToken) {
    localStorage.setItem('refresh_token', refreshToken);
  }

  getToken() {
    return localStorage.getItem('id_token') || false;
  }

  getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  decodePayloadJWT(): any {
    try {
      if (!this.userData) {
        const userData = jwt_decode(this.getToken());

        Object.keys(userData).forEach((key) => {
          if (userData[key] === 'True' || userData[key] === 'true' ||
            userData[key] === 'False' || userData[key] === 'false') {
            userData[key] = userData[key] === 'True' || userData[key] === 'true';
          }
        });

        if (userData.policies) {
          const policies = [];
          const policiesDictionary = JSON.parse(userData.policies);
          Object.keys(policiesDictionary).forEach((key) => {
            policiesDictionary[key].forEach((element: string) => {
              policies.push(key === element ? key : key + '.' + element);
            });
          });

          userData.policies = policies;
        }

        this.userData = userData;
      }
    } catch (Error) {
      this.logout();
    }

    return this.userData;
  }

  hasPolicy(policy: string): boolean {
    const userData = this.decodePayloadJWT();

    if (userData && userData.policies) {
      return userData.policies.indexOf(policy) >= 0;
    }

    return false;
  }

  isInRole(role: string): boolean {
    const userData = this.decodePayloadJWT();

    if (userData && userData.roles) {
      const roles = JSON.parse(userData.roles);
      
      var hasRol = roles.find(r => r.Name === role);

      return (hasRol) ? true: false;
    }

    return false;
  }

 

  isAdmin(): boolean {
    const userData = this.decodePayloadJWT();
    return userData && userData.isAdmin;
  }

  isStoreUser(): boolean {
    return this.userProfile$.value.storeCode !== '' && this.userProfile$.value.storeCode !== null;
  }

  setExpirationDate(time: number) {
    const today = moment();
    const expiration = moment(today).add(time, 'seconds');
    localStorage.setItem('expires_in', moment.utc(expiration).format());
  }
}
