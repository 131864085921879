import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MatOptionSelectionChange, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { debounceTime, filter, finalize, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ProductDto, ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-order-product-form',
  templateUrl: './order-product-form.component.html',
  styleUrls: ['./order-product-form.component.scss']
})
export class OrderProductFormComponent implements OnInit {
  isLoading: boolean;
  currentStore: string;
  cardCode: string;
  productsControl = new FormControl();
  filteredProducts: ProductDto[];
  selectedProduct: ProductDto;
  stockProducts$: { [key: string]: Observable<number>; } = {};

  constructor(
    private productService: ProductsService,
    private dialogRef: MatDialogRef<ProductDto>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.currentStore = data.storeCode;
    this.cardCode = data.cardCode || '';
  }

  ngOnInit(): void {
    this.productsControl.valueChanges
      .pipe(
        debounceTime(500),
        filter(value => value.length > 2),
        tap(() => this.isLoading = true),
        switchMap(value => {
          if (!this.cardCode) {
            return this.productService.findProducts(value, 5, this.currentStore)
            .pipe(finalize(() => this.isLoading = false));
          } else {
            return this.productService.findProductsByCardCode(value, 5, this.cardCode)
            .pipe(finalize(() => this.isLoading = false));
          }
        })
      ).subscribe(products => this.filteredProducts = products);
  }

  displayFn(product: ProductDto): string {
    if (!product) {
      return '';
    }

    return `${product.name} (${product.sku})`;
  }

  onProductSelected($event: MatOptionSelectionChange): void {
    this.selectedProduct = $event.source.value;
  }

  accept(): void {
    this.dialogRef.close(this.selectedProduct);
  }

  getStock(sku: string): Observable<number> {
    if (!this.stockProducts$[sku]) {
      this.stockProducts$[sku] = this.productService
        .getStock(sku, this.currentStore)
        .pipe(shareReplay(1));
    }

    return this.stockProducts$[sku];
  }
}
