import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private oauthService: OAuthService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      this.authenticationService.setReturnUrl(state.url);
      if (!this.authenticationService.isAuthenticated()) {
        
        let params:any = getParamsObjectFromHash();
        if(params && params.access_token && params.expires_in && params.id_token) {
          navigateToProperUrl();
          
        }
        
        this.authenticationService.removeSession();
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
      }
      return true;
    }
  }

export function getParamsObjectFromHash() {
  const hash = window.location.hash ? window.location.hash.split('#') : [];
  let toBeReturned = {};
  if (hash.length && hash[1].split('&').length) {
    toBeReturned = hash[1].split('&').reduce((acc, x) => {
      const hello = x.split('=');
      if (hello.length === 2) acc[hello[0]] = hello[1];
        return acc;
    }, {});
  }
  return Object.keys(toBeReturned).length ? toBeReturned : null;
}

export function navigateToProperUrl() {
  let newLocation = window.location.href;
  let urlSplitted = newLocation.split('#');
  if(urlSplitted[1]) {
    let domain = urlSplitted[0].replace('/login', '');
    window.location.replace(`${domain}/#/login?${urlSplitted[1]}`);
  }
}