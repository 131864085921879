import { Routes } from '@angular/router';
import { DataValidationLandingComponent } from './data-validation-landing/data-validation-landing.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './login/login.component';
import { PickingMobileFormComponent } from './oms/picking-groups/picking-mobile-form.component';
import { RegistrationComponent } from './registration/registration.component';
import { OmsTrackingComponent } from './tracking/tracking.component';
import { TransferQRComponent } from './transfers/public/transfer-qr/transfer-qr.component';
const ɵ0 = () => import("./home/home.module.ngfactory").then((m) => m.HomeModuleNgFactory);
const routes = [
    { path: 'login', component: LoginComponent },
    { path: 'registro/:action/:userCode', component: RegistrationComponent },
    { path: 'public/tracking/:nro', component: OmsTrackingComponent },
    { path: 'public/tracking', component: OmsTrackingComponent },
    { path: 'public/validate/:token', component: DataValidationLandingComponent },
    { path: 'public/transfer-qr/:id', component: TransferQRComponent },
    { path: 'separation/:id', canActivate: [AuthGuard], component: PickingMobileFormComponent },
    {
        path: '**',
        canActivate: [AuthGuard],
        loadChildren: ɵ0,
    },
];
export class AppRoutingModule {
}
export { ɵ0 };
