import * as i0 from "@angular/core";
import * as i1 from "./authentication.service";
export class PermissionService {
    constructor(authenticationService) {
        this.authenticationService = authenticationService;
    }
    checkPermission(permission) {
        return this.checkPermissions([permission]);
    }
    checkPermissions(permissions, logicalOp = 'AND') {
        let hasPermission = false;
        if (this.authenticationService.isAdmin()) {
            return true;
        }
        for (const checkPermission of permissions) {
            if (this.authenticationService.hasPolicy(checkPermission)) {
                hasPermission = true;
                if (logicalOp === 'OR') {
                    break;
                }
            }
            else {
                hasPermission = false;
                if (logicalOp === 'AND') {
                    break;
                }
            }
        }
        return hasPermission;
    }
    checkRole(role) {
        return this.checkRoles([role]);
    }
    checkRoles(roles, logicalOp = 'AND') {
        let hasRole = false;
        if (this.authenticationService.isAdmin()) {
            return true;
        }
        for (const role of roles) {
            if (this.authenticationService.isInRole(role)) {
                hasRole = true;
                if (logicalOp === 'OR') {
                    break;
                }
            }
            else {
                hasRole = false;
                if (logicalOp === 'AND') {
                    break;
                }
            }
        }
        return hasRole;
    }
}
PermissionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PermissionService_Factory() { return new PermissionService(i0.ɵɵinject(i1.AuthenticationService)); }, token: PermissionService, providedIn: "root" });
