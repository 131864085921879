import * as i0 from "@angular/core";
export class BeepService {
    constructor() {
    }
    beep() {
        const audio = new Audio();
        audio.src = 'assets/sounds/beep.wav';
        audio.load();
        audio.play();
    }
}
BeepService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BeepService_Factory() { return new BeepService(); }, token: BeepService, providedIn: "root" });
