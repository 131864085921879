import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserRole } from 'src/app/shared/models/roles/userRole';
import { Action } from 'src/app/shared/models/roles/action';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { getAllActions } from '../security/roles/data/allActions';
import { Resource } from 'src/app/shared/models/roles/resource';
import { ApplicationComponent } from 'src/app/shared/models/roles/applicationComponent';
import { Policy } from '../shared/models/roles/policy';
import { Role } from '../shared/models/roles/role';

@Injectable()
export class RoleService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.securityUrl;
  }

  getRoleListWithoutPagination(): Observable<UserRole[]> {
    return this.http.get<UserRole[]>(`${this.baseUrl}/roles`);
  }

  getSecuredActionsList(): Observable<Action[]> {
    return this.http.get<Action[]>(`${this.baseUrl}/Actions`);
  }

  getUserRoleWithPagination(searchData: any) {
    return this.http.get<UserRole[]>(`${this.baseUrl}/roles/Pagination`, {
      params: searchData
    });
  }

  getUserRoleById(id): Observable<UserRole> {
    return this.http.get<UserRole>(`${this.baseUrl}/Role/getById/${id}`);
  }

  createRole(role: Role) {
    return this.http.post(`${this.baseUrl}/Role/create`, role);
  }

  updateRole(role: UserRole) {
    const roleDto = {
      id: role.id,
      name: role.name,
      policies: role.policies
      // actions: role.actions.map(action => action.id),
      // countryCodes: role.countries.map(country => country.code)
    };
    return this.http.post(`${this.baseUrl}/Role/update`, roleDto);
  }

  changeRoleStatus(role: UserRole) {
    return this.http.post(
      `${this.baseUrl}/Role/setDisabled/${role.id}`,
      role.isDisabled,
      {
        headers: {
          content: 'application/json',
          'content-type': 'application/json'
        }
      }
    );
  }

  getAllActions(): Action[] {
    return getAllActions();
  }

  getResourceList(): Observable<Resource[]> {
    return this.http.get<Resource[]>(`${this.baseUrl}/Resources`);
  }

  createResource(resourse) {
    return this.http.post(`${this.baseUrl}/Resource/create`, resourse);
  }

  createAction(action) {
    return this.http.post(`${this.baseUrl}/Action/create`, action);
  }

  getEdnpointList(): Observable<ApplicationComponent[]> {
    return this.http.get<ApplicationComponent[]>(`${this.baseUrl}/Actions/allendpoints`);
  }

  getAllPolicies(): Observable<Policy[]> {
    return this.http.get<Policy[]>(`${this.baseUrl}/Policies`);
  }
}
