/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i2 from "@angular/material/list";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/material/tooltip";
import * as i8 from "@angular/cdk/overlay";
import * as i9 from "@angular/cdk/scrolling";
import * as i10 from "@angular/cdk/platform";
import * as i11 from "@angular/cdk/bidi";
import * as i12 from "@angular/platform-browser";
import * as i13 from "../../../shared/directives/copy-clipboard.directive";
import * as i14 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i15 from "@angular/material/icon";
import * as i16 from "../../../shared/pipes/localize.pipe";
import * as i17 from "../../../services/localization.service";
import * as i18 from "@angular/material/dialog";
import * as i19 from "@angular/common";
import * as i20 from "./quadminds-result.component";
var styles_QuadmindsResultComponent = [];
var RenderType_QuadmindsResultComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_QuadmindsResultComponent, data: {} });
export { RenderType_QuadmindsResultComponent as RenderType_QuadmindsResultComponent };
function View_QuadmindsResultComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "mat-list-item", [["class", "mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], null, null, i1.View_MatListItem_0, i1.RenderType_MatListItem)), i0.ɵdid(1, 1228800, null, 3, i2.MatListItem, [i0.ElementRef, i0.ChangeDetectorRef, [2, i2.MatNavList], [2, i2.MatList]], null, null), i0.ɵqud(603979776, 1, { _lines: 1 }), i0.ɵqud(603979776, 2, { _avatar: 0 }), i0.ɵqud(603979776, 3, { _icon: 0 }), (_l()(), i0.ɵted(5, 2, ["", " ordenes se procesaron correctamente. "])), (_l()(), i0.ɵeld(6, 16777216, null, 2, 6, "button", [["mat-icon-button", ""], ["matTooltip", "Copiar ids al portapapeles"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 8)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i0.ɵnov(_v, 8)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (i0.ɵnov(_v, 9).onClick($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i0.ɵdid(7, 180224, null, 0, i4.MatButton, [i0.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i0.ɵdid(8, 212992, null, 0, i7.MatTooltip, [i8.Overlay, i0.ElementRef, i9.ScrollDispatcher, i0.ViewContainerRef, i0.NgZone, i10.Platform, i5.AriaDescriber, i5.FocusMonitor, i7.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i11.Directionality], [2, i7.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i12.HAMMER_LOADER]], { message: [0, "message"] }, null), i0.ɵdid(9, 16384, null, 0, i13.CopyClipboardDirective, [], { payload: [0, "payload"] }, null), (_l()(), i0.ɵeld(10, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i14.View_MatIcon_0, i14.RenderType_MatIcon)), i0.ɵdid(11, 9158656, null, 0, i15.MatIcon, [i0.ElementRef, i15.MatIconRegistry, [8, null], [2, i15.MAT_ICON_LOCATION]], null, null), (_l()(), i0.ɵted(-1, 0, ["content_copy"]))], function (_ck, _v) { var _co = _v.component; var currVal_5 = "Copiar ids al portapapeles"; _ck(_v, 8, 0, currVal_5); var currVal_6 = _co.data.succeed.join(","); _ck(_v, 9, 0, currVal_6); _ck(_v, 11, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵnov(_v, 1)._avatar || i0.ɵnov(_v, 1)._icon); var currVal_1 = (i0.ɵnov(_v, 1)._avatar || i0.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.data.succeed.length; _ck(_v, 5, 0, currVal_2); var currVal_3 = (i0.ɵnov(_v, 7).disabled || null); var currVal_4 = (i0.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_7 = i0.ɵnov(_v, 11).inline; var currVal_8 = (((i0.ɵnov(_v, 11).color !== "primary") && (i0.ɵnov(_v, 11).color !== "accent")) && (i0.ɵnov(_v, 11).color !== "warn")); _ck(_v, 10, 0, currVal_7, currVal_8); }); }
function View_QuadmindsResultComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "mat-list-item", [["class", "mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], null, null, i1.View_MatListItem_0, i1.RenderType_MatListItem)), i0.ɵdid(1, 1228800, null, 3, i2.MatListItem, [i0.ElementRef, i0.ChangeDetectorRef, [2, i2.MatNavList], [2, i2.MatList]], null, null), i0.ɵqud(603979776, 4, { _lines: 1 }), i0.ɵqud(603979776, 5, { _avatar: 0 }), i0.ɵqud(603979776, 6, { _icon: 0 }), (_l()(), i0.ɵted(5, 2, ["", " ordenes no se pudieron procesar. "])), (_l()(), i0.ɵeld(6, 16777216, null, 2, 6, "button", [["mat-icon-button", ""], ["matTooltip", "Copiar ids al portapapeles"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 8)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i0.ɵnov(_v, 8)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (i0.ɵnov(_v, 9).onClick($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i0.ɵdid(7, 180224, null, 0, i4.MatButton, [i0.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i0.ɵdid(8, 212992, null, 0, i7.MatTooltip, [i8.Overlay, i0.ElementRef, i9.ScrollDispatcher, i0.ViewContainerRef, i0.NgZone, i10.Platform, i5.AriaDescriber, i5.FocusMonitor, i7.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i11.Directionality], [2, i7.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i12.HAMMER_LOADER]], { message: [0, "message"] }, null), i0.ɵdid(9, 16384, null, 0, i13.CopyClipboardDirective, [], { payload: [0, "payload"] }, null), (_l()(), i0.ɵeld(10, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i14.View_MatIcon_0, i14.RenderType_MatIcon)), i0.ɵdid(11, 9158656, null, 0, i15.MatIcon, [i0.ElementRef, i15.MatIconRegistry, [8, null], [2, i15.MAT_ICON_LOCATION]], null, null), (_l()(), i0.ɵted(-1, 0, ["content_copy"]))], function (_ck, _v) { var _co = _v.component; var currVal_5 = "Copiar ids al portapapeles"; _ck(_v, 8, 0, currVal_5); var currVal_6 = _co.data.errors.join(","); _ck(_v, 9, 0, currVal_6); _ck(_v, 11, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵnov(_v, 1)._avatar || i0.ɵnov(_v, 1)._icon); var currVal_1 = (i0.ɵnov(_v, 1)._avatar || i0.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.data.errors.length; _ck(_v, 5, 0, currVal_2); var currVal_3 = (i0.ɵnov(_v, 7).disabled || null); var currVal_4 = (i0.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_7 = i0.ɵnov(_v, 11).inline; var currVal_8 = (((i0.ɵnov(_v, 11).color !== "primary") && (i0.ɵnov(_v, 11).color !== "accent")) && (i0.ɵnov(_v, 11).color !== "warn")); _ck(_v, 10, 0, currVal_7, currVal_8); }); }
export function View_QuadmindsResultComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i16.LocalizePipe, [i17.LocalizationService]), (_l()(), i0.ɵeld(1, 0, null, null, 17, "div", [["class", "confirmation-dialog"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(3, 81920, null, 0, i18.MatDialogTitle, [[2, i18.MatDialogRef], i0.ElementRef, i18.MatDialog], null, null), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 7, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(6, 16384, null, 0, i18.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 5, "mat-list", [["class", "mat-list mat-list-base"]], null, null, null, i1.View_MatList_0, i1.RenderType_MatList)), i0.ɵdid(8, 704512, null, 0, i2.MatList, [i0.ElementRef], null, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_QuadmindsResultComponent_1)), i0.ɵdid(10, 16384, null, 0, i19.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_QuadmindsResultComponent_2)), i0.ɵdid(12, 16384, null, 0, i19.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(13, 0, null, null, 5, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(14, 16384, null, 0, i18.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(15, 0, null, null, 3, "button", [["class", "btn btn__primary"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.thisDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i0.ɵdid(16, 180224, null, 0, i4.MatButton, [i0.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(17, 0, [" ", " "])), i0.ɵppd(18, 1)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_2 = (_co.data.succeed.length > 0); _ck(_v, 10, 0, currVal_2); var currVal_3 = (_co.data.errors.length > 0); _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 3).id; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 4, 0, currVal_1); var currVal_4 = (i0.ɵnov(_v, 16).disabled || null); var currVal_5 = (i0.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_4, currVal_5); var currVal_6 = i0.ɵunv(_v, 17, 0, _ck(_v, 18, 0, i0.ɵnov(_v, 0), "accept")); _ck(_v, 17, 0, currVal_6); }); }
export function View_QuadmindsResultComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-quadminds-result-dialog", [], null, null, null, View_QuadmindsResultComponent_0, RenderType_QuadmindsResultComponent)), i0.ɵdid(1, 114688, null, 0, i20.QuadmindsResultComponent, [i18.MatDialogRef, i18.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QuadmindsResultComponentNgFactory = i0.ɵccf("app-quadminds-result-dialog", i20.QuadmindsResultComponent, View_QuadmindsResultComponent_Host_0, {}, {}, []);
export { QuadmindsResultComponentNgFactory as QuadmindsResultComponentNgFactory };
