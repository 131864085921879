import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  title: string;
  text: string;
  hideCancelButton: boolean;
  btnPrimaryText: string;
  btnSecondaryText: string;

  constructor(public thisDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.title = data.title ? data.title : '';
      this.text = data.text ? data.text : '';
      this.hideCancelButton = data.hideCancelButton ? data.hideCancelButton: false;
      this.btnPrimaryText = data.btnPrimaryText ? data.btnPrimaryText : 'Aceptar';
      this.btnSecondaryText = data.btnSecondaryText ? data.btnSecondaryText : 'Cancelar';
    } else {
      this.title = '';
      this.text = '';
      this.hideCancelButton = false 
      this.btnPrimaryText = 'Aceptar';
      this.btnSecondaryText = 'Cancelar';
    }
  }

  ngOnInit() {
  }

  onCloseConfirm() {
    this.thisDialogRef.close('Confirm');
  }

  onCloseCancel() {
    this.thisDialogRef.close('Cancel');
  }
}
