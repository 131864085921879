import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })

export class LocationWmsService {

    private baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = environment.wmsUrl;
    }


    getAll(countryCode: string | null | undefined, sorting: string | null | undefined, skipCount: number | undefined, maxResultCount: number | undefined): Observable<LocationDtoResult> {

        let url_ = `${this.baseUrl}/api/services/app/Location/GetAll?`;

        if (countryCode !== undefined)
            url_ += "CountryCode=" + encodeURIComponent("" + countryCode) + "&"; 

        if (sorting !== undefined)
            url_ += "Sorting=" + encodeURIComponent("" + sorting) + "&"; 

        if (skipCount !== undefined)
            url_ += "SkipCount=" + encodeURIComponent("" + skipCount) + "&"; 

        if (maxResultCount !== undefined)
            url_ += "MaxResultCount=" + encodeURIComponent("" + maxResultCount) + "&"; 

        url_ = url_.replace(/[?&]$/, "");

        return this.http.get<any>(url_)
                        .pipe(map(x => x.result ? x.result : null));
    }
}


export class ProductLocationWmsService {

    private baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = environment.wmsUrl;
    }

    get(nro: string): Observable<ProductLocationDto> {

        const url = `${this.baseUrl}/api/services/app/ProductLocation/Get?id=${nro}`;
  
        return this.http.get<any>(url)
                        .pipe(map(x => x.result ? x.result : null));
    }


    create(dto: ProductLocationDto): Observable<ProductLocationDto> {

        const url = `${this.baseUrl}/api/services/app/ProductLocation/create`;

        return this.http.post<any>(url, dto)
                        .pipe(map(x => x.result ? x.result : null));
    }

    update(dto: ProductLocationDto): Observable<ProductLocationDto> {

        const url = `${this.baseUrl}/api/services/app/ProductLocation/update`;

        return this.http.put<any>(url, dto)
                        .pipe(map(x => x.result ? x.result : null));
    }

}


export class LocationDtoResult  {
    
    totalCount: number;
    items: LocationDto[] | undefined; 
}

export class LocationDto  {
    
    id: number;
    code: string;

    aisleId? : number | null | undefined;
    aisleName? : string | null | undefined;
    orderNumber? : number;  
}

export class ProductLocationDto {
    
    ean: string | null | undefined;
    productId: number;
    productName: string | null | undefined;
    sku: string | null | undefined;
    imageUrl: string | null | undefined;
    locationId: number;
    locationCode: string | null | undefined;
    orderNumber: number;
    aisleName: string | null | undefined;
    storeCode: string | null | undefined;
    stock: string | null | undefined;
    id: number;

}