<mat-sidenav-container class="puppis-container" autosize style="height:calc(100% - 4.285em);">
	<mat-sidenav #sidenav class="puppis-sidenav" mode="{{this.mode}}" opened="true" (mouseenter)="mouseenter()" (mouseleave)="mouseleave()">
		<mat-nav-list>
      <mat-list-item class="parent">
        <span class="full-width" *ngIf="isExpanded || isShowing"> {{ user?.firstName }} {{ user?.lastName }}</span>
        <mat-icon mat-list-icon>account_circle</mat-icon>
      </mat-list-item>
      <mat-divider></mat-divider>
      <ng-container *ngFor="let item of navigationItems">

        <mat-list-item *ngIf="showItem(item)"  (click)="item.expanded = !item.expanded" class="parent gray-icons">
          <span class="full-width" *ngIf="isExpanded || isShowing">{{item.title|localize}}</span>
          <mat-icon aria-hidden="false" fontSet="material-icons-outlined" mat-list-icon>{{item.icon}}</mat-icon>
          <mat-icon class="menu-button" [ngClass]="{'rotated' : item.expanded}" *ngIf="isExpanded || isShowing">expand_more</mat-icon>
        </mat-list-item>

        <div *ngIf="item.children.length > 0 && item.expanded && (isShowing|| isExpanded)" class="submenu" [ngClass]="{'expanded' : item.expanded && (isShowing||isExpanded) }">
          <ng-container *ngFor="let subItem of item.children">

            <ng-container *ngIf="subItem.children === undefined || subItem.children === null">
              <a *ngIf="showItem(subItem)"  mat-list-item [routerLink]="subItem.link">{{subItem.title|localize}}</a>
            </ng-container>

            <ng-container *ngIf="showItem(subItem) && subItem.children !== undefined && subItem.children !== null && (isShowing||isExpanded)">

              <mat-list-item (click)="subItem.expanded = !subItem.expanded" class="parent gray-icons">
                <span class="full-width" *ngIf="isExpanded || isShowing">{{subItem.title|localize}}</span>
                <mat-icon class="menu-button" [ngClass]="{'rotated' : subItem.expanded}" *ngIf="isExpanded || isShowing">expand_more</mat-icon>
              </mat-list-item>

              <div *ngIf="subItem.children !== undefined && subItem.children !== null && subItem.expanded && (isShowing||isExpanded)" class="submenu" [ngClass]="{'expanded' : subItem.expanded && (isShowing||isExpanded) }">
                
                <ng-container *ngFor="let subItem2 of subItem.children">
                  <ng-container *ngIf="subItem2.children === undefined || subItem2.children === null">
                    <a *ngIf="showItem(subItem2)"  mat-list-item [routerLink]="subItem2.link"> {{ subItem2.title|localize }} </a>
                  </ng-container>
                </ng-container>
              </div>

            </ng-container>

          </ng-container>
        </div>
      </ng-container>
		</mat-nav-list>
	</mat-sidenav>

  <section class="main-container">
    <div class="layout__container centered-flex-custom">
      <router-outlet></router-outlet>
    </div>
  </section>

</mat-sidenav-container>
