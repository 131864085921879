//cdk
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatSpinner } from '@angular/material';
//rxjs
import { Subject } from 'rxjs';
import { scan, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/overlay";
import * as i2 from "@angular/material/snack-bar";
export class UiService {
    constructor(overlay, snackBar) {
        this.overlay = overlay;
        this.snackBar = snackBar;
        this.spinnerTopRef = this.cdkSpinnerCreate();
        this.spin$ = new Subject();
        this.spin$
            .asObservable()
            .pipe(map(val => val ? 1 : -1), scan((acc, one) => (acc + one) >= 0 ? acc + one : 0, 0))
            .subscribe((res) => {
            if (res === 1) {
                this.showSpinner();
            }
            else if (res == 0) {
                this.spinnerTopRef.hasAttached() ? this.stopSpinner() : null;
            }
        });
    }
    cdkSpinnerCreate() {
        return this.overlay.create({
            hasBackdrop: false,
            backdropClass: 'dark-backdrop',
            positionStrategy: this.overlay.position()
                .global()
                .centerHorizontally()
                .centerVertically()
        });
    }
    showSpinner() {
        if (!this.spinnerTopRef.hasAttached()) {
            this.spinnerTopRef.attach(new ComponentPortal(MatSpinner));
            setTimeout(() => {
                this.stopSpinner();
            }, 12000);
        }
    }
    stopSpinner() {
        this.spinnerTopRef.detach();
    }
    openSnackBar(message, action) {
        this.snackBar.open(message, action, {
            duration: 5000
        });
    }
    displayErrorMessage(exception, action, article, component) {
        if (exception && exception.error && exception.error.error) {
            this.openSnackBar(`${exception.error.error}`, '');
        }
        else {
            this.openSnackBar(`Ocurrió un error al ${action} ${article} ${component}`, '');
        }
    }
}
UiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UiService_Factory() { return new UiService(i0.ɵɵinject(i1.Overlay), i0.ɵɵinject(i2.MatSnackBar)); }, token: UiService, providedIn: "root" });
