
<div style="background-color: white !important; height: 100%">
<div class="toolbar" role="banner">
    <img
      width="200"
      alt="Puppis Logo"
      src="https://puppis.vteximg.com.br/arquivos/logo_puppis.png?v=637490932890630000"
    />
</div>
<div class="alert" *ngIf="error">
    <div style="text-align: center">
        El pedido no se encontró
    </div>
</div>
<loading-indicator *ngIf="loading"></loading-indicator>
<div class="content" role="main" *ngIf="!editable && !loading && !error">
    <mat-card class="mat-card-center">
        <mat-card-content>
            <div class="container" fxLayout="column" fxLayoutAlign="start">
                <h1 style="color: #003982; font-size: 25px; margin-bottom: 0px;">Estado de tu pedido: {{ status }}</h1>

                
                <small>
                    <b>En esta etapa de la preparación del pedido no podemos modificar tus datos.</b><br/>
                </small>
               

                <small>
                    Si necesitás conocer el estado de tu pedido hacé click <a href="{{ urlTracking}}" target="_blank" > aquí </a><br/><br/>
                </small>
            
                
                <span style="font-size: 16px;">Si tenes cualquier duda te pedimos por favor nos contactes a:</span>
                <h2 style="color: #003982; font-size: 25px;"><mat-icon>call</mat-icon> {{ contactPhone }}</h2>
                <h2 style="color: #003982; font-size: 25px;" >
                    <a href="https://api.whatsapp.com/send/?phone={{contactWhatsapp.replace('+','').replaceAll('-','')}}&app_absent=0"  target="_blank" style="font-size: 25px;">
                    <img style="margin-bottom: -10px" src="../../assets/images/whatsapp_brand.svg" width="32">
                    {{contactWhatsapp}} 
                    </a>
                </h2>
            </div>
            <div>
                <button mat-button color="primary" (click)="navigateToCountry()" style="background-color: #003982; color: white;">Hacer otra compra en Puppis</button>
            </div>
        </mat-card-content>
    </mat-card>
</div>
<div class="content" role="main" *ngIf="editable && !loading">

    <div class="card-container" fxLayout="row wrap" fxLayoutGap="16px grid">
        <mat-card fxFlex="100%">
            <div fxLayout="column" fxLayoutAlign="start">
                <h1 style="color: #003982; text-align: left;font-size: 25px; margin-bottom: 0px;">Confirma tus datos</h1>
                <h4 style="color: #757575; text-align: left;"><small>Revisa y edita los datos necesarios para que tu envío llegue sin inconvenientes</small></h4>
                <div fxFlex fxFlexAlign="center"
                    class="autocomplete-container"
                    style="width: 100%;">
                    <mat-form-field class="autocomplete">
                        <mat-label>Ubicación</mat-label>
                        <input matInput placeholder="Introduzca aquí su ubicación o calle y número para encontrar en el mapa"
                            matGoogleMapsAutocomplete
                            (onAutocompleteSelected)="onAutocompleteSelected($event)"
                            (onLocationSelected)="onLocationSelected($event)">
                    </mat-form-field>
                </div>
            </div>
        </mat-card>
    </div>

    <div class="card-container" fxLayout="row wrap" fxLayoutGap="16px grid">
        <mat-card fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
            <div fxLayout="row wrap" fxLayoutGap="16px grid">
                <mat-form-field fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <mat-label>{{'country'|localize}}</mat-label>
                    <input matInput readonly [value]="direction.country">
                </mat-form-field>
                <mat-form-field fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <mat-label>{{'province'|localize}}</mat-label>
                    <input matInput readonly [value]="direction.province">
                </mat-form-field>
            </div>
            <div fxLayout="row wrap" fxLayoutGap="16px grid">
                <mat-form-field fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <mat-label>{{'postalCode'|localize}}</mat-label>
                    <input matInput readonly [value]="direction.postalCode">
                </mat-form-field>
                <mat-form-field fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <mat-label>{{'city'|localize}}</mat-label>
                    <input matInput readonly [value]="direction.city">
                </mat-form-field>
            </div>
            <div fxLayout="row wrap" fxLayoutGap="16px grid">
                <mat-form-field fxFlex="33.33%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <mat-label>{{'street'|localize}}</mat-label>
                    <input matInput readonly [value]="direction.street">
                </mat-form-field>
                <mat-form-field fxFlex="33.33%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <mat-label>{{'number'|localize}}</mat-label>
                    <input matInput readonly [value]="direction.streetNumber">
                </mat-form-field>
                <mat-form-field fxFlex="33.33%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <mat-label>{{'Depto'|localize}}</mat-label>
                    <input matInput name="depto" [(ngModel)]="direction.depto">
                </mat-form-field>
            </div>
            <div fxLayout="row wrap" fxLayoutGap="16px grid">
                <mat-form-field fxFlex="33.33%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <mat-label>Teléfono</mat-label>
                    <input matInput [value]="direction.tel" name="phoneNumber" [(ngModel)]="direction.tel">
                </mat-form-field>
            </div>
            <div>
                <button (click)="updateDeliveryData()" mat-button color="primary" style="background-color: #003982; color: white;">Confirmar datos</button>
            </div>
        </mat-card>
        <mat-card fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
            <div fxFlex>
                <agm-map style="width: 100%; height: 270px;" [latitude]="direction.latitude" [longitude]="direction.longitude" [scrollwheel]="false" [zoom]="zoom">
                  <agm-marker [latitude]="direction.latitude" [longitude]="direction.longitude"></agm-marker>
                </agm-map>
            </div>
        </mat-card>
    </div>
</div>
    
</div>