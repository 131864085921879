import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HelperService } from '../oms/services/helper.service';
import { StoreDto } from '../stores/services/service-proxy/service-proxies';
import { CommonService } from './common.service';
import { LocalizationService } from './localization.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private BASE_URL = environment.storesUrl;
  private _stores: StoreDto[] = [];
  private userService: UserService;
  private STORE_KEY = 'CURRENT_STORE';
  private COUNTRY_KEY = 'country';

  constructor(
    private httpClient: HttpClient,
    private commonService: CommonService,
    private localizationService: LocalizationService,
    injector: Injector
  ) {
    this.userService = injector.get(UserService);
  }

  setCurrentStore(storeCode: string) {
    HelperService.storeOnLocalStorage(this.STORE_KEY, storeCode);
  }

  getStore(storeCode: string = null): StoreDto {
    if (storeCode == null) {
      storeCode = this.getCurrentStore();
    }

    return this._stores.find((x) => x.storeCode === storeCode);
  }

  currentStoreIsWarehouse(): boolean {
    let current = this.getStore(this.getCurrentStore());

    if (!current) return false;

    return current.isWarehouse;
  }

  getStores(countryCode: string = null): any[] {
    let stores = [];
    const currentCountry = countryCode || this.commonService.getCookie(this.COUNTRY_KEY);

    if (currentCountry) {
      stores = this._stores.filter((x) => x.countryCode === currentCountry);
    } else {
      stores = this._stores;
    }

    stores = [
      {
        name: this.localizationService.localize('allStores'),
        id: '',
        isHub: false,
      },
      ...stores.map((x) => ({
        id: x.storeCode,
        name: x.name,
        isHub: x.isHub,
        addressState: x.addressState,
      })),
    ];

    return stores;
  }

  getStoresAvailablesForSales(countryCode: string = null): any[] {
    let stores = [];
    const currentCountry = countryCode || this.commonService.getCookie(this.COUNTRY_KEY);

    if (currentCountry) {
      stores = this._stores.filter((x) => x.countryCode === currentCountry && x.availableForSales);
    } else {
      stores = this._stores.filter((x) => x.availableForSales);
    }

    stores = [
      ...stores.map((x) => ({
        id: x.storeCode,
        name: x.name,
        isHub: x.isHub,
        addressState: x.addressState,
      })),
    ];

    return stores;
  }

  getStoresByCity(city: string): string[] {
    return this._stores.filter((x) => x.addressState === city).map((x) => x.storeCode);
  }

  getCurrentStore(): string {
    let storeCode = HelperService.getValueFromLocalStorage(this.STORE_KEY);

    if (this.userService) {
      const user = this.userService.getUserProfileFromLocal();
      storeCode = user.storeCode || storeCode || '';
    }

    return storeCode;
  }

  isStoreSelected(): boolean {
    return this.getCurrentStore() ? this.getCurrentStore() !== '' : false;
  }

  getStoreName(code: string): string {
    const store = this.getStores().find((x) => x.id === code);
    if (store) {
      return store.name;
    } else {
      return '';
    }
  }

  isStoreHub(code: string) {
    const store = this.getStores().find((x) => x.id === code);
    return store ? store.isHub : false;
  }

  getStoreNameByErpCode(code: string) {
    let stores = [];
    const currentCountry = this.commonService.getCookie(this.COUNTRY_KEY);

    if (currentCountry) {
      stores = this._stores.filter((x) => x.countryCode === currentCountry);
    } else {
      stores = this._stores;
    }
    return (stores.find((x) => x.erpCode == code) || {}).name || code;
  }

  getStoreErpCode(code: string) {
    const store = this._stores.find((x) => x.storeCode === code);
    return store ? store.erpCode : code;
  }

  getStoreEmail(code: string) {
    return this._stores.find((x) => x.storeCode === code)?.email;
  }

  load(): Observable<StoreDto[]> {

    console.log('load stores ...');
    return this.httpClient
      .get<any>(`${this.BASE_URL}/api/services/app/Store/GetAllCountries?MaxResultCount=1000`)
      .pipe(catchError((e) => of([])))
      .pipe(map((r) => (r.result ? r.result.items : [])))
      .pipe(
        tap((stores: StoreDto[]) => {
          this._stores = stores.sort((n1, n2) => {
            if (n1.name > n2.name) {
              return 1;
            }
            if (n1.name < n2.name) {
              return -1;
            }
            return 0;
          });
        })
      );
  }
}
