import { __awaiter } from "tslib";
import { Validators } from '@angular/forms';
import { GoogleLoginProvider } from 'angularx-social-login';
import { combineLatest } from 'rxjs';
export class LoginComponent {
    constructor(authenticationService, userService, snackBar, router, formBuilder, authService, activatedRoute) {
        this.authenticationService = authenticationService;
        this.userService = userService;
        this.snackBar = snackBar;
        this.router = router;
        this.formBuilder = formBuilder;
        this.authService = authService;
        this.activatedRoute = activatedRoute;
        this.loading = false;
        this.isSubmitted = false;
        this.isSubmittedForgot = false;
        this.showPassword = false;
        this.isLogged = false;
        this.invalidData = false;
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(8)]],
        });
    }
    get formControls() {
        return this.loginForm.controls;
    }
    login() {
        this.isSubmitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;
        combineLatest([
            this.activatedRoute.queryParams,
            this.authenticationService.login(this.loginForm.value),
        ]).subscribe((results) => {
            const data = results[1];
            const returnUrl = results[0].returnUrl || '/';
            if (data && data.id) {
                this.setSessionData(data);
                this.router.navigateByUrl(returnUrl);
            }
            this.loading = false;
        }, (error) => {
            this.invalidData = true;
            this.loading = false;
            console.error(error);
        });
    }
    setShowPassword() {
        if (this.loginForm.controls.password.value) {
            this.showPassword = !this.showPassword;
        }
        else {
            this.showPassword = false;
        }
    }
    setSessionData(data) {
        this.authenticationService.setSessionToken(data.auth_Token);
        this.authenticationService.setRefreshToken(data.refresh_Token);
        const userData = this.authenticationService.decodePayloadJWT();
        this.authenticationService.setUserProfile(userData);
        localStorage.setItem('is_internal_user', 'true');
        this.authenticationService.setExpirationDate(data.expires_In);
        localStorage.setItem('user', JSON.stringify({
            id: userData.id,
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            storeCode: userData.storeCode,
            countryCode: userData.countryCode,
            isAdmin: userData.isAdmin,
        }));
        this.authenticationService.isLoggedIn$.next(true);
    }
    loginWithGoogle() {
        return __awaiter(this, void 0, void 0, function* () {
            this.loginForm.reset();
            this.isSubmitted = false;
            this.loading = true;
            try {
                const result = yield this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
                console.log(result);
                const externalLogin = { providerId: result.provider, idToken: result.idToken };
                this.authenticationService.loginWithGoogleSingin(externalLogin).subscribe((response) => {
                    if (response && response.id) {
                        this.authenticationService.setSessionToken(response.auth_Token);
                        this.authenticationService.setRefreshToken(response.refresh_Token);
                        const userData = this.authenticationService.decodePayloadJWT();
                        this.authenticationService.setUserProfile(userData);
                        localStorage.setItem('is_internal_user', 'true');
                        this.authenticationService.setExpirationDate(response.expires_In);
                        localStorage.setItem('user', JSON.stringify({
                            id: userData.id,
                            firstName: userData.firstName,
                            lastName: userData.lastName,
                            email: userData.email,
                            storeCode: userData.storeCode,
                            countryCode: userData.countryCode,
                            isAdmin: userData.isAdmin,
                        }));
                        this.authenticationService.isLoggedIn$.next(true);
                        this.router.navigateByUrl('/oms/info');
                    }
                }, (error) => {
                    this.invalidData = true;
                    console.log(error);
                }, () => (this.loading = false));
            }
            catch (e) {
                this.loading = false;
            }
        });
    }
    setResetPassword() {
        this.isSubmittedForgot = true;
        const emailControl = this.loginForm.controls['email'];
        if (emailControl.valid) {
            const email = emailControl.value;
            this.userService.setResetPassword(undefined, email).subscribe((response) => {
                this.snackBar.open('Contraseña restablecida, verifique su casilla de mail', 'Cerrar', {
                    duration: 5000,
                });
            }, (error) => {
                this.snackBar.open('Surgió un error, no se ha restrablecido la contraseña', 'Cerrar', {
                    duration: 5000,
                });
            });
        }
    }
}
