import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { isArray } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuadmindsResultComponent } from 'src/app/oms/orders/quadminds-preview/quadminds-result.component';
import { LocalizationService } from 'src/app/services/localization.service';
import { ProcessDto, ProcessService } from 'src/app/services/process.service';
import { UiService } from 'src/app/services/ui.service';
import { ConfirmationDialogComponent } from '../../../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-processes',
  templateUrl: './processes.component.html',
  styleUrls: ['./processes.component.scss']
})
export class ProcessesComponent implements OnInit {

  public finishedProcesses$: Observable<ProcessDto[]>;
  public finishedProcessesCount$: Observable<number>;
  public isProcessing$: Observable<boolean>;

  constructor(private dialog: MatDialog, 
              private processService: ProcessService,
              public uiService: UiService, 
              public localizationService: LocalizationService) {}

  ngOnInit(): void {

    this.isProcessing$           = this.processService.isProcessing$;
    this.finishedProcessesCount$ = this.processService.finishedProcess$.pipe(map(x => x.length));
    this.finishedProcesses$      = this.processService.finishedProcess$;
  }

  dismiss(id: number) {
    this.processService.removeFinishedProcess(id);
  }

  dismissAll() {
    this.processService.clearFinishedProcesses();
  }

  getDetailOutput(output: string): any[] {
    let result: any[] = [];
    try {
      const jObject = JSON.parse(output);
      if(!isArray(jObject)) {
        if(jObject.hasOwnProperty('Message')) {
          result = [jObject.Message];
        }else        if(jObject.hasOwnProperty('Result')) {
          result = [jObject.Result];
        } else {
          result = [jObject];
        }
      } else {
        result = jObject;
      }
      
    } catch (error) {
      result.push(output);
    }

    //console.log(result);

    return result;
  }

  isLink(value: any): boolean {
    if (typeof value.startsWith === 'function') {
      return value.startsWith('http') || value.startsWith('https');
    }

    return false;
  }

  isQuadmindResult(value: any): boolean {
    if (typeof value === 'object') {
      return 'Errors' in value && 'Orders' in value;
    }

    return false;
  }

  openQuadmindDialog(result: any): void {
    this.dialog.open(QuadmindsResultComponent, {
      data: {
        title: 'Envío de ordenes a Quadminds.',
        succeed: result.Orders.map(x => x.code),
        errors: result.Errors.map(x => x.OrderId)
      }
    });
  }


  confirmDismissAll() {

    const title = `Se eliminaran las notificaciones de los procesos finalizados`;
    const disableStoreDialogConfig = new MatDialogConfig();
    disableStoreDialogConfig.disableClose = false;
    disableStoreDialogConfig.autoFocus = true;
    disableStoreDialogConfig.data = { title,
                                      text: '',
                                      btnPrimaryText: 'Eliminar'
                                    };

    const disableDialogRef = this.dialog.open(ConfirmationDialogComponent, disableStoreDialogConfig);

    disableDialogRef.afterClosed()
                    .subscribe(
                      responseDialog =>  {
                          if (responseDialog === 'Confirm') 
                            this.dismissAll();
                      });

  };

}
