import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Country } from '../shared/models/address/country';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { State } from '../shared/models/address/state';
import { Gender } from '../shared/models/gender';
import { City } from '../shared/models/address/city';
import * as moment from 'moment';
import { HelperService } from '../oms/services/helper.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  clientUrl: string;
  private COUNTRY_KEY = 'country';

  selectedCountry$: Subject<Country> = new Subject<Country>();

  countryList$ = new BehaviorSubject(null);  
  countryListCities$ = new BehaviorSubject(null);  
  // documentTypeList$ = new BehaviorSubject(null); 

  constructor(private http: HttpClient) {
    this.clientUrl = environment.clientUrl;
  }

  getCountryCode(){
    return this.getCookie(this.COUNTRY_KEY);
  }
  

  setSelectedCountry(country: Country) {
    HelperService.storeOnLocalStorage('CURRENT_STORE', '');
    this.selectedCountry$.next(country);
  }

  getCountryList(): Observable<Country[]> {
    return this.http.get<Country[]>(`${this.clientUrl}/country`).pipe(
      tap((countries: Country[]) => this.countryList$.next(countries)));
  }

  getCountryById(id: string): Observable<Country> {
    return this.http.get<Country>(`${this.clientUrl}/country/${id}`);
  }

  getStatesByCountryId(id: string): Observable<State[]> {
    return this.http.get<State[]>(
      `${this.clientUrl}/State/statesByCountry/${id}`
    );
  }

  getStatesById(id: string): Observable<State[]> {
    return this.http.get<State[]>(`${this.clientUrl}/State/${id}`);
  }

  getCityByStateId(id: string): Observable<City[]> {
    return this.http.get<City[]>(`${this.clientUrl}/City/citiesByState/${id}`);
  }

  getCityById(id: string): Observable<State[]> {
    return this.http.get<State[]>(`${this.clientUrl}/City/${id}`);
  }

  getGenderList(): Observable<Gender[]> {
    return this.http.get<Gender[]>(`${this.clientUrl}/Client/genders`);
  }

  //"{Codes}/Cities"
  getCountriesWithStatesAndCities(countryCodes: string[]) {
    return this.http.post(
      `${this.clientUrl}/Country/Cities`,countryCodes
    ).pipe(
      tap((countries: any) => this.countryListCities$.next(countries)));
  }

  getCountriesFromLocalStorage() {
    const countryStatesAndCity = JSON.parse(
      localStorage.getItem('CountriesStatesCities')
    );
    return countryStatesAndCity;
  }

  getFilteredCountryByCityIdFromLocal(cityId: string) {
    const countryStatesAndCity = JSON.parse(
      localStorage.getItem('CountriesStatesCities')
    );
    return countryStatesAndCity.filter(country =>
      country.states.some(state => state.cities.some(
        city => city.id === cityId
      ))
    );
  }

  getDifferenceInDays(biggerDate: Date, smallerDate: Date) {
    return Math.round(moment.duration(moment(biggerDate).diff(moment(smallerDate))).asDays());
  }

  getAllCountryListFromLocal() {
    return JSON.parse(
      localStorage.getItem('allCountryList')
    );
  }

  getDocumentTypeListFromLocal() {
    return JSON.parse(
      localStorage.getItem('documentTypeList')
    );
  }

  getGenderListFromLocal() {
    return JSON.parse(
      localStorage.getItem('genderList')
    );
  }

  getAddressTypeListFromLocal() {
    return JSON.parse(
      localStorage.getItem('addressTypeList')
    );
  }

  setCookie(cname: string, cvalue: any, exdays: number): void {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    var cookie = cname + '=' + cvalue + ';' + expires +  ';SameSite=Lax;path=/';
    document.cookie = cookie;
  }

  getCookie(cname: string): string {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }

    return '';
  }

  clearCookie(cname?: string | undefined) {
    const cookies = document.cookie.split(';');

    for (var i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = cname || name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }

  deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
}
