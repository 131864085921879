import { User } from 'src/app/shared/models/user';

export class HelperService {
  public static isStoreUser(user: User): boolean {
    return user.storeCode != '' && user.storeCode != null;
  }

  public static isCentralStoreUser(user: User): boolean {
    return user.storeCode == '99';
  }

  public static storeOnLocalStorage(key: string, value: any): void {
    localStorage.setItem(key, value);
  }

  public static getValueFromLocalStorage(key: string): any {
    return localStorage.getItem(key);
  }

  public static removeKeyFromLocalStorage(key: string): void {
    localStorage.removeItem(key);
  }
}
