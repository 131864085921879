<!-- <div class="toolbar" role="banner">
        <img width="200" alt="Puppis Logo" src="https://puppis.vteximg.com.br/arquivos/logo_puppis.png?v=637490932890630000"/>
</div> -->

<div class="headerTracking">
	<div class="imgContainer"> <img src="../../assets/images/dog-puppis.png"> </div>
	<label> Estado de tu Pedido </label>
</div>



<div class="content" role="main" *ngIf="!isLoading">

    <div *ngIf="errorMsj" class="alert">
        <div> {{ errorMsj }} </div>
    </div>

    <div *ngIf="order" class="card-container" fxLayout="row wrap" fxLayoutGap="16px grid">

        <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">

            <mat-card >

              <mat-card-content style="padding-top:8px">

                <label class="text-blue-puppis" style="font-size:25px; font-weight: bold;">  Hola {{ order?.nombre }}, </label>
                <br><br>
                <span  style="font-size:15px"> Tu pedido de Puppis <b style="font-weight: 2000; font-size: 16px !important;"> {{ order?.id }} </b> </span> <br>
                <span  style="font-size:15px"> se encuentra </span>
                <br><br>

                <div *ngIf="order.lastStepIsDelivered  && order.lastStepDesc === 'Entregado' " >
                    <span style="font-size:22px; color:green; font-weight: bold;"> {{ order?.lastStepDesc }}  </span>
                    <br>
                    <span class="gray-subtitle" style="font-size:12px"> Felicitaciones, ya podes disfrutar de tu orden! </span>
                </div>

                <div *ngIf="order.lastStepIsDelivered  && order.lastStepDesc !== 'Entregado' " >
                    <span  style="font-size:22px; color:orange; font-weight: bold;"> {{ order?.lastStepDesc }}  </span>
                    <br>
                    <span class="gray-subtitle" style="font-size:12px"> {{ order?.lastStepDetail }} </span>
                </div>

                <div *ngIf="!order.lastStepIsDelivered">
                    <span  style="font-size:22px; color:#0058a4; font-weight: bold;"> {{ order?.lastStepDesc }}  </span>
                    <br>
                    <span class="gray-subtitle" style="font-size:12px"> {{ order?.lastStepDetail }} </span>
                </div>
                

              </mat-card-content>

            </mat-card>

            <ng-container *ngIf="order.lastStepIsDelivered && order.isShipping && order.lastStepDesc === 'Entregado' " >

                <mat-divider></mat-divider>
                <mat-card  >
                    <mat-card-content style="padding-top:8px">
    
                        <label class="text-blue-puppis" style="font-size:15px"> Detalle de tu entrega </label>
                        <br> <br> 
                        <span style="font-size:13px; padding-bottom: 2px;"> Tu pedido fue entregado el </span> 
                        <br>
                        <span style="font-size:13px; margin-bottom: 2px;"> <b> {{ order?.estimatedDate  }} </b>  </span> <br>
                        <span *ngIf="order?.estimatedTime && order?.estimatedTimeEnd" style="font-size:13px; margin-bottom: 2px;"> de {{ order?.estimatedTime }} a {{ order?.estimatedTimeEnd }} hs</span>
                        <span *ngIf="order?.estimatedTime && !order?.estimatedTimeEnd" style="font-size:13px; margin-bottom: 2px;"> desde las {{ order?.estimatedTime }} hs</span>
    
                    </mat-card-content>
                </mat-card>
            </ng-container>
            
           <ng-container>

            <mat-card  *ngIf="order.lastStepIsDelivered && !order.isShipping">

                <mat-divider></mat-divider>
                <mat-card-content style="padding-top:8px">

                    <label class="text-blue-puppis" style="font-size:15px"> Detalle de tu entrega </label>
                    <br> <br> 
                    <span style="font-size:13px; padding-bottom: 2px;"> Tu pedido fue entregado en la tienda el </span> 
                    <br>
                    <span style="font-size:13px; margin-bottom: 2px;"> <b> {{ order?.estimatedDate  }} </b>  </span> <br>
                    <span *ngIf="order?.estimatedTime && order?.estimatedTimeEnd" style="font-size:13px; margin-bottom: 2px;"> de {{ order?.estimatedTime }} a {{ order?.estimatedTimeEnd }} hs</span>
                    <span *ngIf="order?.estimatedTime && !order?.estimatedTimeEnd" style="font-size:13px; margin-bottom: 2px;"> desde las {{ order?.estimatedTime }} hs</span>
                </mat-card-content>
            </mat-card>

           </ng-container>

            <ng-container *ngIf="!order.lastStepIsDelivered">

                <mat-divider></mat-divider>
                <mat-card>
                    <mat-card-content style="padding-top:8px">
    
                        <label class="text-blue-puppis" style="font-size:15px"> Detalle de tu entrega </label>
                        <br> <br> 
                        <span style="font-size:13px; padding-bottom: 2px;"> Fecha estimada de entrega </span> 
                        <br>
                        <span style="font-size:13px; margin-bottom: 2px;"> <b> {{ order?.estimatedDate  }} </b>  </span> <br>
                        <span *ngIf="order?.estimatedTime && order?.estimatedTimeEnd" style="font-size:13px; margin-bottom: 2px;"> de {{ order?.estimatedTime }} a {{ order?.estimatedTimeEnd }} hs</span>
                        <span *ngIf="order?.estimatedTime && !order?.estimatedTimeEnd" style="font-size:13px; margin-bottom: 2px;"> desde las {{ order?.estimatedTime }} hs</span>
                    </mat-card-content>
                </mat-card>

            </ng-container>


            <mat-card>

                <mat-card-content style="padding-top:8px">

                    <label class="text-blue-puppis" style="font-size:15px; font-weight: bold;"> Seguimiento de tu envío </label>
                    <br>  <br>

                    <mat-grid-list cols="6" rowHeight="75px">

                        <div *ngFor="let item of order?.steps; let indice=index" >

                            <mat-grid-tile [colspan]="1" >

                                <img height="75" *ngIf="indice == 0 && item.isChecked"  src="/assets/icons/tracking/first-step.jpg" >
                                <img height="75" *ngIf="indice == 0 && !item.isChecked" src="/assets/icons/tracking/start-step-gray.jpg" > 
                                <img height="75" *ngIf="indice > 0 && indice < (order.steps.length - 1) &&  item.isChecked" src="/assets/icons/tracking/step.jpg" > 
                                <img height="75" *ngIf="indice > 0 && indice < (order.steps.length - 1) && !item.isChecked" src="/assets/icons/tracking/step-gray.jpg" > 
                                <img height="75" *ngIf="indice == (order.steps.length - 1) &&  item.isChecked" src="/assets/icons/tracking/last-step.jpg" >
                                <img height="75" *ngIf="indice == (order.steps.length - 1) && !item.isChecked" src="/assets/icons/tracking/last-step-gray.jpg" >

                            </mat-grid-tile>
        
                            <mat-grid-tile [colspan]="5" >
        
                                <div *ngIf="item.isChecked" class="grid-item" fxLayout="column" fxLayoutAlign="space-around stretch" > 
                                    <span  class="step-date"> {{ item.dateDesc }} </span>
                                    <span  class="step-title"> {{ item.stepDesc }}  </span>

                                    <span  class="step-subtitle" style="font-size:11px" > {{ item.stepDescSub }} <a *ngIf="order.lastStepIsDelivered && indice == 0 " href="javascript:void(0)" (click)="openUrlZen() "> Aquí </a>   </span>
                                </div>

                                <div *ngIf="!item.isChecked" class="grid-item" fxLayout="column" fxLayoutAlign="space-around stretch" > 
                                    <span class="step-title-disabled"> {{ item.stepDesc }}  </span>
                                    <span class="step-subtitle-disabled" style="font-size:11px"> {{ item.stepDescSub }}  </span>
                                </div>
        
                            </mat-grid-tile>

                        </div>

                    </mat-grid-list>

                </mat-card-content>

            </mat-card>


        </div>

        <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">


            <mat-card>

                <mat-card-actions align="end">
                        <button mat-button class="btn__primary" (click)="openUrlZen()" > ¿Necesitas Ayuda? </button>
                </mat-card-actions>

                <mat-card-content style="padding-top:8px">
                    <agm-map style="width: 100%; height: 300px;" 
                                    [latitude]="order?.adressLat || 0" [longitude]="order?.adressLng || 0" [scrollwheel]="false" [zoom]="14">
                        <agm-marker [latitude]="order?.adressLat || 0" [longitude]="order?.adressLng || 0"></agm-marker>
                    </agm-map>
                </mat-card-content>
            
            </mat-card>

            <mat-divider></mat-divider>

            <mat-card>

                <mat-card-content style="padding-top:8px" *ngIf="order.isShipping">

                    <label class="text-blue-puppis" style="font-size:15px; font-weight: bold;"> Dirección de entrega </label>
                    <label *ngIf="order?.editable" class="text-light-blue-puppis" style="font-size:10px; font-weight: bold; float: right;"> <a *ngIf="!isCol()" class="text-light-blue-puppis" href="public/validate/{{numDeli}}" style="font-size:10px;">¿Querés corregir tus Datos de Entrega? Hacelo aquí</a> </label>
                    <br><br>

                    <div> 
                        <span class="text-blue-puppis" style="font-size:13px"> Calle : </span>          <span  style="font-size:13px"> {{ order.calle }}  </span>  <br>
                        <span class="text-blue-puppis" style="font-size:13px"> Número : </span>         <span  style="font-size:13px"> {{ order.numero }}  </span>  <br>
                        <span class="text-blue-puppis" style="font-size:13px"> Piso/Depto : </span>     <span  style="font-size:13px"> {{ order.pisoDepto }} </span> <br>
                        <span class="text-blue-puppis" style="font-size:13px"> Barrio/Ciudad : </span>  <span  style="font-size:13px"> {{ order.barrioCiudad }} </span> <br>
                        <span class="text-blue-puppis" style="font-size:13px"> Codigo Postal : </span>  <span  style="font-size:13px"> {{ order.codigoPostal }} </span> <br>
                        <span class="text-blue-puppis" style="font-size:13px"> Provincia : </span>      <span  style="font-size:13px"> {{ order.provincia }} </span> <br>
                        <span class="text-blue-puppis" style="font-size:13px"> Teléfono : </span>       <span  style="font-size:13px"> {{ order.telefono }} </span> 
                    </div>

                    <br>
                </mat-card-content>

                <mat-card-content style="padding-top:8px" *ngIf="!order.isShipping">

                    <label class="text-blue-puppis" style="font-size:15px; font-weight: bold;"> Dirección de la Tienda </label>
                    <label *ngIf="order?.editable" class="text-light-blue-puppis" style="font-size:10px; font-weight: bold; float: right;"> <a class="text-light-blue-puppis" href="public/validate/{{numDeli}}" style="font-size:10px;">¿Querés corregir tus Datos de Entrega? Hacelo aquí</a> </label>
                    <br><br>

                    <div> 
                        <span class="text-blue-puppis" style="font-size:13px"> Sucursal : </span>   <span  style="font-size:13px"> {{ order.storeName }}  </span>  <br>
                        <span class="text-blue-puppis" style="font-size:13px"> Dirección : </span>  <span  style="font-size:13px"> {{ order.storeAdress }}  </span>  <br>
                        <span class="text-blue-puppis" style="font-size:13px"> Teléfono : </span>   <span  style="font-size:13px"> {{ order.storeTelefono }} </span> <br>
                        <span class="text-blue-puppis" style="font-size:13px"> Mail : </span>       <span  style="font-size:13px"> {{ order.storeMail }} </span>  <br>
                    </div>

                    <div>
                        <span class="text-blue-puppis" style="font-size:12px"> Horarios: </span> <br>
                        <span  style="font-size:12px"> Lunes a viernes: 8 a 20.30 hs </span>  <br>
                        <span  style="font-size:12px"> Sabados: 9 a 20.30 hs </span>  <br> 
                        <span  style="font-size:12px"> Domingos: 10 a 20 hs </span>  <br>
                        <span  style="font-size:12px"> Feriados: 10 a 19 hs </span>  
                    </div>

                    <br>
                </mat-card-content>

            </mat-card>

            <mat-divider></mat-divider>

            <mat-card>

                <mat-card-content style="padding-top:8px">

                    <label class="text-blue-puppis" style="font-size:15px; font-weight: bold;"> Detalle de tu pedido </label>
                    <br>  <br>
    
                    <mat-grid-list cols="7" rowHeight="70px">

                        <div>
    
                            <mat-grid-tile [colspan]="5" >
                                <span class="item-subtitle"> Producto  </span>
                            </mat-grid-tile>
        
                            <mat-grid-tile [colspan]="1" >
                                <span class="item-subtitle"> Unid.  </span>
                            </mat-grid-tile>

                            <mat-grid-tile [colspan]="1" >
                                <span class="item-subtitle"> Precio Unit.  </span>
                            </mat-grid-tile>
    
                        </div>
    
                        <div *ngFor="let item of order?.orderItems; let indice=index" >
    
                            <mat-grid-tile [colspan]="1" >
                                <img  style="max-width: 60px" src="{{item.imageUrl}}" >
                            </mat-grid-tile>
        
                            <mat-grid-tile [colspan]="4" >
                                <div  class="grid-item" fxLayout="column" fxLayoutAlign="space-around stretch" > 
                                    <span class="item-title"> {{ item.description }}  </span>
                                    <span class="step-subtitle"> SKU: {{ item.sku }} </span>
                                </div> 
                            </mat-grid-tile>

                            <mat-grid-tile [colspan]="1" >
                                <span class="item-title"> {{ item.quantity }}  </span>
                            </mat-grid-tile>

                            <mat-grid-tile [colspan]="1" >
                                <span class="item-title"> {{ '$' | localize }} {{ (item.netAmount) | currency: ' ' }} </span>
                            </mat-grid-tile>
    
                        </div>
    
                    </mat-grid-list>
                </mat-card-content>

            </mat-card>
        </div>

    </div>

    
</div>

<loading-indicator *ngIf="isLoading"> </loading-indicator>
  

