import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HomeRoutingModule } from './home-routing.module';
import { ProfileComponent } from '../profile/profile.component';
import { HomeComponent } from './home.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        HomeComponent,
        ProfileComponent
    ],
    imports: [
        SharedModule,
        HomeRoutingModule
    ],
    entryComponents: [],
    providers: [],
    schemas: []
})
export class HomeModule { }
