import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';

@Component({
  selector: 'app-success-validation-message',
  templateUrl: './success-validation-message.component.html',
  styleUrls: ['./success-validation-message.component.scss']
})
export class SuccessValidationMessageComponent extends BaseFormComponent<any> {
  create(): Observable<any> {
    throw new Error('Method not implemented.');
  }
  edit(): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getModel(): Observable<any> {
    throw new Error('Method not implemented.');
  }

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(dialogRef, data);
  }
}
