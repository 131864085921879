<button mat-icon-button [matMenuTriggerFor]="processes">
  <mat-icon
    [matBadge]="!(isProcessing$ | async) && finishedProcessesCount$ | async"
    class="profile__icon"
    [ngClass]="
      (isProcessing$ | async) ? 'animated-rotation' : 'no-animated-rotation'
    "
    >{{
      (isProcessing$ | async) ? "rotate_right" : "lightbulb"
    }}</mat-icon
  >
</button>
<mat-menu #processes="matMenu">

  <mat-nav-list style="width: 275px;">

    <ng-container *ngIf="(finishedProcesses$|async)?.length > 0; else emptyNotifications">

      <ng-container *ngFor="let process of finishedProcesses$ | async">

        <mat-list-item *ngIf="process.status === 'Finished' || process.status === 'Error'">

          <mat-icon mat-list-icon [class.green-icon]="process.status === 'Finished'" [class.red-icon]="process.status === 'Error'">{{ process.icon }}</mat-icon>
          <div mat-line>{{ process.name }}</div>
          <div mat-line>{{ process.creationTime | date: "dd/MM/yyyy HH:mm" }} </div>
          <p mat-line *ngFor="let result of getDetailOutput(process.output)" matTooltip="{{result}}">
            <ng-container *ngIf="isLink(result) || isQuadmindResult(result); else elseTemplate">
              <a *ngIf="isLink(result)" [href]="result" target="_blank">{{'Download'|localize}}</a>
              <a *ngIf="isQuadmindResult(result)" [routerLink] (click)="openQuadmindDialog(result)">{{result.Orders.length}} ordenes enviadas.</a>
            </ng-container>
            <ng-template #elseTemplate>
              {{ result }}
            </ng-template>
          </p>
          <button mat-icon-button (click)="dismiss(process.id)">
            <mat-icon>delete</mat-icon>
          </button>

        </mat-list-item>

        <mat-list-item *ngIf="!(process.status === 'Finished' || process.status === 'Error')">
          <mat-icon mat-list-icon [class.green-icon]="process.status === 'Finished'" [class.red-icon]="process.status === 'Error'">{{ process.icon }}</mat-icon>
          <div mat-line>{{ process.name }}</div>
          <div mat-line>{{ process.creationTime | date: "dd/MM/yyyy HH:mm" }} </div>
          <button mat-icon-button (click)="dismissAll()">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-list-item>



      </ng-container>

      <ng-container>
        <mat-list-item (click)="confirmDismissAll()">
          <div mat-line class="center">{{ 'deleteAllNotifications' | localize }} </div>
        </mat-list-item>
      </ng-container>
      
      


    </ng-container>

  </mat-nav-list>

</mat-menu>

<ng-template #emptyNotifications>
  <mat-list-item>
    <div mat-line>{{ 'NoDataToDisplay' | localize }}</div>
  </mat-list-item>
</ng-template>
