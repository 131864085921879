import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TransferOmsService {
    constructor(http) {
        this.http = http;
        this.baseUrl = environment.omsUrl;
    }
    getTransferOrderInfo(id) {
        const queryUrl = `${this.baseUrl}api/services/app/Transfer/GetTransferOrderInfo?id=${id}`;
        return this.http
            .get(queryUrl)
            .pipe(map(x => x.result ? x.result : null));
    }
}
TransferOmsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TransferOmsService_Factory() { return new TransferOmsService(i0.ɵɵinject(i1.HttpClient)); }, token: TransferOmsService, providedIn: "root" });
export class TransferOrderInfoDto {
}
