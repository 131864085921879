<ng-container *ngIf="pickingGroup$ | async as picking; else loading">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <h2>Separación {{ picking.id }}</h2>
    </mat-toolbar-row>
    <mat-toolbar-row class="products-header">
      <button mat-icon-button (click)="prevProduct()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <span class="products-header-spacer"></span>
      <h2>Producto {{ currentProductNumber }}  / {{ picking.pickingGroupItems.length }}</h2>
      <span class="products-header-spacer"></span>
      <button mat-icon-button (click)="nextProduct(picking)">
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <div *ngIf="picking.pickingGroupItems[currentProductNumber - 1] as product">
    <mat-list class="product-location">

      <mat-list-item *ngIf="product.aisle"> {{ product.aisle }}</mat-list-item>
      <mat-divider></mat-divider>

      <mat-list-item *ngIf="product.location"> Posición: {{ product.location }} <button mat-flat-button class="btn__primary" style="margin-left: 16px" (click)="selectLocation(product)"> Edit </button></mat-list-item> 
      <mat-divider></mat-divider>

      <mat-list-item *ngIf="!product.location"> NO location  <button mat-flat-button class="btn__primary"  style="margin-left: 16px" (click)="selectLocation(product)"> Add </button> </mat-list-item>
      <mat-divider></mat-divider>

      <mat-list-item>EAN: {{ product.barCode }}</mat-list-item>
    </mat-list>
    <div class="product-image-detail">
      <img [src]="product.imageUrl || 'assets/images/image-product-no-available.png'" />
      <h2>{{ product.description }}</h2>
    </div>
    <div class="barcode-input">
      <img src="assets/images/barcode.png" (click)="openScan(product)" />
      <mat-form-field appearance="outline">
        <input
          class="barcode-input"
          #barcodeInput
          matInput
          [(ngModel)]="barcode"
          (keydown.enter)="onBarcodeScanned(product); barcodeInput.select()"
        />
      </mat-form-field>
    </div>
    <div class="quantity-input">
      <h2>Cantidad Total:</h2>
      <mat-form-field appearance="outline">
        <input matInput disabled [value]="product.quantity" />
      </mat-form-field>
    </div>
    <div class="quantity-input">
      <h2>Cantidad Pickeada:</h2>
      <mat-form-field appearance="outline">
        <input
          matInput
          type="number"
          [max]="product.quantity"
          [(ngModel)]="product.quantityPicked"
        />
      </mat-form-field>
    </div>
    <div class="summary" *ngIf="finished">
      
        <mat-icon>info</mat-icon>
     
      <span>
            Pickeo finalizado. <br>
            Continue el proceso en el computador.
      </span>
    </div>
    <div class="buttons-container">

      <button *ngIf="currentProductNumber < picking.pickingGroupItems.length && !finished"
              mat-flat-button
              class="btn__primary next-button"
              (click)="nextProduct(picking)">
        Siguiente
      </button>

      <button *ngIf="currentProductNumber === picking.pickingGroupItems.length && ! finished"
              mat-flat-button
              class="btn__success next-button"
              (click)="finishPicking(picking)">
        Terminar
      </button>

      <button *ngIf="finished"
              mat-flat-button
              class="btn__secondary next-button" disabled>
        
      </button>


    </div>
  </div>
  <loading-indicator *ngIf="isLoading"></loading-indicator>
</ng-container>
<ng-template #loading>
  <loading-indicator></loading-indicator>
</ng-template>
