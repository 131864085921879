import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currency'
})
export class CurrencyProxyPipe implements PipeTransform {

    constructor(private currencyPipe: CurrencyPipe) {
    }

    transform(value, code = '$', display = 'symbol-narrow', digits = '1.0-0', local) {
        return this.currencyPipe.transform(value, code, display, digits, local);
    }
}
