
 
<mat-dialog-header>
    <h1> Asignar Coordenada </h1>
</mat-dialog-header>

<mat-dialog-content>

    <div fxFill fxLayout="row">

        <mat-form-field >
            <mat-label> {{ 'product' | localize }} </mat-label>
            <input matInput type="text" [(ngModel)]="selectedProduct && selectedProduct.name" [ngModelOptions]="{standalone: true}" readonly >
        </mat-form-field>

    </div>


    <div fxFill fxLayout="row">

        <mat-form-field [disabled]="isReadonly()">

            <mat-label>{{'location'|localize}}</mat-label>
            <input type="text" matInput [formControl]="locationControl" [matAutocomplete]="auto" />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayLocationCode" (optionSelected)="locationChange($event)">
                <mat-option *ngFor="let location of filteredLocations | async" [value]="location">
                    {{ location.code }}
                </mat-option>
            </mat-autocomplete>

        </mat-form-field>

    </div>

    <loading-indicator *ngIf="isLoading"></loading-indicator>

</mat-dialog-content>

<mat-dialog-actions>

    <button mat-button class="btn__secondary btn-width" [mat-dialog-close]="false" #cancelButton> Cancelar
    </button>

    <button *ngIf="action != 0" mat-button type="submit" class="btn__primary btn-width" (click)="send()" [disabled]="!enableSendBtn()" >
        <span> {{ getActionLabel() }} </span>
    </button>

</mat-dialog-actions>
