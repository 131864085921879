import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService } from './services/authentication.service';
import { CommonService } from './services/common.service';
import { ClientService } from './clients/services/client.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'puppisUI';
  isLoggedIn: boolean;
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private clientService: ClientService
  ) {
    this.loadIcons();
    //this.loadCountriesStatesAndCities();
    //this.loadAllDocumentTypes();
    //this.loadGenders();
    //this.loadAddressType();
  }

  ngOnInit(): void {
    moment.tz.setDefault('UTC');

    this.authenticationService.isLoggedIn$.subscribe(value => {
      this.isLoggedIn = value;
    });
  }

  loadIcons() {
    this.matIconRegistry.addSvgIcon(
      'icon-puppis',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-puppis.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-puppis-blue',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-puppis-blue.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-change',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-change.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-change',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-change.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-history',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-history.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-reorder',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-reorder.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-invoice',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-invoice.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-profile',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-profile.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-clock',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-clock.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-phone',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-phone.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-dispatch',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/despachar.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-quitar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/quitar.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-email',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-email.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-address',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-address.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-nationality',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-nationality.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-warning',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-warning.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-hide-password',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-hide-password.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-plus',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-plus.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-sap',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-sap.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-sap-1',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-sap-1.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-sap-2',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-sap-2.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-merge',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-merge.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-tag-merge',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-tag-merge.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-vtex',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-vtex.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-icg',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-icg.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-meli',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-meli.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-pvet',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-pvet.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-simp',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-simp.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-com',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-com.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-drag',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-drag.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-google',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-google.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-flag-arg',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-flag-arg.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-flag-col',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-flag-col.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-flag-arg-col',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-flag-arg-col.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-service-dr-active',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-service-dr-active.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-service-dr',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-service-dr.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-service-garage-active',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-service-garage-active.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-service-garage',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-service-garage.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-service-hair-active',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-service-hair-active.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-service-hair',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-service-hair.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-text-attribute',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-text-attribute.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-file-attribute',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-file-attribute.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-image-attribute',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-image-attribute.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-edit.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-flag-ppco',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-flag-ppco.svg'
      )
    );
  }

  loadCountriesStatesAndCities() {
    const countryCodes = ['AR', 'CO']
    this.commonService.getCountriesWithStatesAndCities(countryCodes).subscribe(
      (response: any) => {
        localStorage.setItem('CountriesStatesCities', JSON.stringify(response));
      },
      error => {
        //log issue
      }
    );
    this.commonService.getCountryList().subscribe(
      (response: any) => {
        localStorage.setItem('allCountryList', JSON.stringify(response));
      },
      error => {
        //log issue
      }
    );
  }

  loadAllDocumentTypes() {
    this.clientService.getIdentificationDocumentCompleteList().subscribe(
      response => {
        localStorage.setItem('documentTypeList', JSON.stringify(response));
      },
      error => {
        //log issue
      }
    );
  }

  loadGenders() {
    this.commonService.getGenderList().subscribe(
      response => {
        localStorage.setItem('genderList', JSON.stringify(response));
      },
      error => {
        //log issue
      }
    );
  }

  loadAddressType() {
    this.clientService.getAddressTypeList().subscribe(
      response => {
        localStorage.setItem('addressTypeList', JSON.stringify(response));
      },
      error => {
        //log issue
      }
    );
  }
}
